import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Box, Button, Card, CircularProgress, Grid, Typography } from "@mui/material";

import dayjs from "dayjs";

import { Dialog, IconText } from "components";
import RewardDetail from "components/RewardDetail";

import { useQuestBoxApi } from "api/questBox";
import useCatchError from "api/useCatchError";

import { Config } from "services";

import queryBuilder from "utils/queryBuilder";
import shortenNumber from "utils/shortenNumber";

import { LeaderBoard, TierReward as TierRewardIcon } from "images";

import { handleDateRange, isCampaignContinuing } from "../carousel/QuestBoxCard";
import { List, SelfRank } from "../leaderboard/List";
import ClaimButton from "./ClaimButton";
import Disclaimer from "./Disclaimer";
import ProgressBar from "./ProgressBar";
import RegisterChecklist from "./RegisterChecklist";
import StepperHorizontal from "./StepperHorizontal";
import StepperVertical from "./StepperVertical";
import TierReward from "./TierReward";

const btnStyle = {
	padding: "0.8rem 6rem",
	marginBlock: "1rem",
};

const mockLayout = {
	web: {
		leftSide: {
			registerSteps: true,
		},
		midSide: {
			actionsButton: true,
			termsConditionsText: true,
		},
		rightSide: {
			claimSteps: false,
			leaderboard: false,
			rewardTiers: false,
			briefInfo: false,
			yourRewards: true,
			bannerImage: true,
			leaderboardRedirect: true,
			showVideo: false,
		},
	},

	mobile: {
		leaderboard: true,
		claim_steps: false,
		register_steps: true,
		see_reward_tiers: true,
		your_rewards: false,
		action_buttons: true,
	},
};

const ClaimRequest = ({ campaign, merchant }) => {
	const [checkCampaignStatus, setCheckCampaignStatus] = useState(null);
	const [eligibityStatus, setEligibityStatus] = useState({ register: { status: false } });
	const [countdown, setCountdown] = useState(0);
	const [open, setOpen] = useState(false);
	const [btnDisabled, setBtnDisabled] = useState(false);

	const [selfRank, setSelfRank] = useState();
	const [top5, setTop5] = useState();

	const navigate = useNavigate();

	const catchError = useCatchError();
	const { i18n, t } = useTranslation("questBox");
	const {
		id,
		title,
		prize,
		description,
		slogan,
		startDate,
		endDate,
		bannerUrl,
		checklistTypes,
		checklist,
		forMerchant,
		maxAttendeeCount,
		leaderboardInfo,
		termsConditionsUrl,
	} = campaign ?? {};

	const layout = campaign?.layout?.web ? campaign.layout.web : mockLayout.web;
	const {
		checkCampaignUserStatus,
		getEligibilityUserStatus,
		createCampaignAttendance,
		claimRewards,
		getLeaderboard,
		getSelfLeaderboard,
		getCampaignRewards,
	} = useQuestBoxApi();

	const handleCheckEligibity = () => {
		getEligibilityUserStatus(id).then((status) => {
			setCountdown(60);
			setBtnDisabled(true);
			setEligibityStatus(status.data?.checklist);
		});
	};

	const handleCreateCampaignAttendance = () => {
		if (eligibityStatus?.register.status) {
			createCampaignAttendance(id)
				.catch(catchError)
				.finally(() => window.location.reload());
		}
	};

	const handleCheckUserCampaignStatus = () => {
		checkCampaignUserStatus(id)
			.then((camp) => setCheckCampaignStatus(camp?.data?.campaignAttendance))
			.catch(catchError);
	};

	const handleClaimReward = () => {
		claimRewards(id)
			.catch(catchError)
			.finally((reward) => setOpen(reward?.data?.campaignAttendance?.checklist?.claim?.stepsStatus));
	};

	const onClose = () => {
		setOpen(false);
	};

	const comingSoon = dayjs(startDate).isAfter(dayjs());

	useEffect(() => {
		checkCampaignUserStatus(id)
			.then((camp) => setCheckCampaignStatus(camp?.data?.campaignAttendance))
			.catch((e) => {
				if (e.message === "611.user campaign attendance not found") {
					return;
				}

				catchError(e);
			});
	}, []);

	useEffect(() => {
		if (layout?.rightSide?.leaderboard && leaderboardInfo && !leaderboardInfo?.placeholderUrl) {
			Promise.all([
				getLeaderboard({ campaignId: id, pageNumber: 0, pageSize: 5 }).then((data) =>
					setTop5(data?.data?.leaderboard)
				),
				getSelfLeaderboard(id).then((data) => {
					setSelfRank(data?.data?.userRank);
				}),
			]).catch(catchError);
		}
	}, [leaderboardInfo]);

	useEffect(() => {
		let timer;
		if (countdown > 0) {
			timer = setInterval(() => setCountdown(countdown - 1), 1000);
		} else {
			setBtnDisabled(false);
		}
		return () => clearInterval(timer);
	}, [countdown]);

	const [processing, setProcessing] = useState(false);
	const [openReward, setOpenReward] = useState(false);
	const [campaignRewards, setCampaignRewards] = useState([]);

	const handleSeeRewards = () => {
		setProcessing(true);
		setOpenReward(true);
		getCampaignRewards(id)
			.then((data) => {
				setCampaignRewards(data?.data?.campaignUserReward?.details);
			})
			.finally(() => {
				setProcessing(false);
			})
			.catch(catchError);
	};

	return (
		<Card sx={{ mt: 3 }}>
			{open && (
				<Dialog
					dialogProps={{
						open: open,
						onClose: onClose,

						sx: {
							"& .MuiDialog-paper": {
								maxHeight: "75vh",
								minHeight: "75vh",
								minWidth: "75vw",
							},
						},
					}}
					content={
						<Grid container>
							<Grid item xs={6} paddingY={12} paddingX={6}>
								<Typography fontSize={24} fontWeight={700} mb={4}>
									You did it! Quest completed the reward are yours.
								</Typography>
								<Typography fontSize={18}>
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus praesentium omnis
									id ipsa sequi, corrupti beatae vitae, modi incidunt alias quae reprehenderit
									delectus explicabo provident at repudiandae minima voluptatem amet.
								</Typography>

								<Typography marginTop={4} fontWeight={600}>
									Campain End Date
								</Typography>
								<Typography fontWeight={600} color="primary">
									{dayjs(endDate).format("MMMM D, YYYY")}
								</Typography>
							</Grid>
							<Grid item xs={6} display="flex" justifyContent="center" alignItems="center" height="34rem">
								<img
									src={bannerUrl}
									alt="imageUrl"
									style={{
										width: "15rem",
										marginInlineEnd: "2rem",
									}}
								/>
							</Grid>
							<Box width="100%" display="flex" justifyContent="center">
								<Button
									variant="outlined"
									sx={{
										width: "18rem",
										height: "3rem",
									}}
									onClick={() => onClose()}
								>
									Close
								</Button>
							</Box>
						</Grid>
					}
				/>
			)}
			<Grid container p={3} spacing={2} paddingTop={6}>
				<Grid item xs={6}>
					<Typography fontSize={24} fontWeight={700} color="primary" marginBottom={2}>
						{title?.[i18n.resolvedLanguage]}
					</Typography>
					<Button variant="contained">{slogan?.[i18n.resolvedLanguage]}</Button>
					<Box marginTop={2} marginBottom={2}>
						<Typography fontWeight={700}>{t("quest_box_campaign_prize_title")}</Typography>
						<Typography>{prize?.[i18n.resolvedLanguage]}</Typography>
					</Box>

					<Box marginTop={2} marginBottom={2}>
						<Typography fontWeight={700}>{t("quest_box_campaign_claim_date_title")}</Typography>
						<Box display="flex">
							<Typography>{t("quest_box_sign_up_title")} : </Typography>
							<Typography marginX={1} fontWeight={700}>
								{dayjs(startDate).format("MMMM D, YYYY")}
							</Typography>
						</Box>

						<Box display="flex">
							<Typography>{t("quest_box_quest_period_title")} :</Typography>
							<Typography marginX={1} fontWeight={700}>
								{handleDateRange(startDate, endDate, t)}
							</Typography>
						</Box>
					</Box>
					<Typography fontWeight={700} gutterBottom>
						{t("quest_box_campaign_details_title")}
					</Typography>
					<Typography fontSize={14} maxWidth={450}>
						{description[i18n.resolvedLanguage]}
					</Typography>
					{layout?.leftSide?.registerSteps && (
						<RegisterChecklist
							checklist={checklist?.register?.steps}
							completedList={checkCampaignStatus?.checklist?.register?.steps}
							eligibityStatus={eligibityStatus?.register?.steps}
						/>
					)}
				</Grid>
				{checklistTypes.claim === 5 && (
					<Grid item xs={6}>
						{leaderboardInfo && !leaderboardInfo?.placeholderUrl && layout?.rightSide?.rewardTiers && (
							<TierReward campaign={campaign} />
						)}

						{layout?.rightSide?.leaderboard &&
							(leaderboardInfo?.placeholderUrl ? (
								<img src={leaderboardInfo?.placeholderUrl} />
							) : (
								<Grid container spacing={3}>
									<SelfRank
										score={selfRank?.score}
										rank={selfRank?.rank}
										previousRank={selfRank?.previousRank}
									/>
									<List top5={top5} tierList={leaderboardInfo?.tierList} id={id} />
								</Grid>
							))}
					</Grid>
				)}
				<Grid item xs={6}>
					{layout?.rightSide?.showVideo && (
						<Grid
							item
							xs={6}
							display="flex"
							flexDirection="column"
							justifyContent="center"
							sx={{ width: "21rem", ml: 10, mt: 10 }}
						>
							<video src={`${Config.cdnRoot()}/campaign_videos/${id}.mp4`} autoPlay muted playsInline />
						</Grid>
					)}
					{checklistTypes.claim !== 5 && (
						<>
							{layout?.rightSide?.showVideo && <Grid item xs={6} />}
							<Grid
								item
								xs={6}
								display="flex"
								flexDirection="column"
								justifyContent="center"
								gap={4}
								alignItems="center"
								sx={{ ml: 10 }}
							>
								{checklistTypes.claim !== 5 && layout?.rightSide?.bannerImage && (
									<img
										src={bannerUrl}
										alt="imageUrl"
										style={{
											filter: !isCampaignContinuing(endDate) ? "grayscale(100%)" : "none",
											width: "15rem",
											marginInlineEnd: "2rem",
										}}
									/>
								)}

								{checklistTypes.claim !== 5 &&
									layout?.rightSide?.leaderboardRedirect &&
									leaderboardInfo?.leaderboardId && (
										<Button
											onClick={() => {
												navigate(
													`/quest-box/${
														leaderboardInfo?.leaderboardId
													}/full-list?${queryBuilder({
														campaignId: id,
													})}`
												);
											}}
										>
											<IconText
												text={t("quest_box_view_leaderboard")}
												textProps={{ sx: { fontSize: "14px", fontWeight: "normal" } }}
												icon={<LeaderBoard active={true} />}
											/>
										</Button>
									)}

								{checklistTypes.claim !== 5 && layout.rightSide.yourRewards && (
									<>
										<Button onClick={handleSeeRewards}>
											<IconText
												text={t("quest_box_see_rewards")}
												textProps={{ sx: { fontSize: "14px", fontWeight: "normal" } }}
												icon={<TierRewardIcon active={true} />}
											/>
										</Button>
										<Dialog
											dialogProps={{
												fullWidth: true,
												open: openReward,
												maxWidth: "md",
												onClose: () => {
													setOpenReward(false);
												},
											}}
											title={<></>}
											content={
												<>
													{processing ? (
														<CircularProgress />
													) : (
														<RewardDetail rewardDetails={campaignRewards} />
													)}
												</>
											}
											action={
												<Grid container justifyContent={"center"}>
													<Grid xs="3">
														<Button
															onClick={() => {
																setOpenReward(false);
															}}
															disabled={processing}
															fullWidth
															variant="outlined"
														>
															{t("common:Close")}
														</Button>
													</Grid>
												</Grid>
											}
										></Dialog>
									</>
								)}

								{forMerchant && layout?.rightSide?.briefInfo && (
									<Box
										width="300px"
										height="80px"
										backgroundColor="#F4F5FC"
										display="flex"
										justifyContent="center"
										alignItems="center"
										flexDirection="column"
										paddingX={3}
										paddingY={1}
										borderRadius={2}
									>
										<Grid container justifyContent="center">
											<Grid item xs={6}>
												<Typography fontWeight={700} fontSize="10px" textAlign="center">
													{t("quest_box_leaderboard_total_volume")}
												</Typography>
												<Typography
													fontWeight={700}
													color="primary"
													fontSize="16px"
													textAlign="center"
												>
													$
													{shortenNumber(
														parseFloat(checkCampaignStatus?.details?.volumeGenerated ?? 0)
													)}
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography fontWeight={700} fontSize="10px" textAlign="center">
													{t("quest_box_leaderboard_average_profit")}
												</Typography>
												<Typography
													fontWeight={700}
													color="primary"
													fontSize="16px"
													textAlign="center"
												>
													{campaign?.checklistTypes?.merchant_stats &&
													merchant?.merchantCampaignProfit
														? `%${merchant?.merchantCampaignProfit?.averageProfit?.toFixed(
																2
														  )}`
														: "-"}
												</Typography>
											</Grid>
										</Grid>
									</Box>
								)}

								{checklistTypes.claim === 1 && layout?.rightSide?.claimSteps && (
									<StepperVertical checkCampaignStatus={checkCampaignStatus} claim={checklist} />
								)}

								{checklistTypes.claim === 2 && layout?.rightSide?.claimSteps && (
									<StepperHorizontal claim={checklist.claim} />
								)}

								{checklistTypes.claim === 3 && layout?.rightSide?.claimSteps && (
									<ProgressBar claim={checklist.claim} />
								)}
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
			<Box p={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={1}>
				{layout?.midSide?.actionsButton && (
					<ClaimButton
						campaign={campaign}
						campaignAttendance={checkCampaignStatus}
						eligibityStatus={eligibityStatus}
						countdown={countdown}
						disabled={btnDisabled}
						onCheckEligibity={handleCheckEligibity}
						onCheckUserCampaignStatus={handleCheckUserCampaignStatus}
						onClaimReward={handleClaimReward}
						onCreateCampaignAttendance={handleCreateCampaignAttendance}
					/>
				)}
				{layout?.midSide?.termsConditionsText && <Disclaimer width={390} campaign={campaign} />}
			</Box>
		</Card>
	);
};

export default ClaimRequest;
