import { Button, Grid, Paper, Typography, useTheme } from "@mui/material";
import { ArrowRight } from "images";
import blueBackgroundBig from "images/blue_bg_3.svg";

export default function MerchantStatus({ icon, title, buttonText, buttonAction, status }) {
	const theme = useTheme();
	return (
		<Paper
			sx={{
				padding: "0",
				mb: "16px",
				backgroundImage: `url(${blueBackgroundBig})`,
				backgroundRepeat: "no-repeat",
				backgroundPosition: "right",
				backgroundSize: "contain"
			}}
		>
			<Grid container alignItems={"center"}>
				<Grid item xs={3} padding={"24px"}>
					<Typography fontWeight={"bold"} mb={0.5} color={status === "REJECTED" ? theme.palette.danger.main : theme.palette.text.dark}>
						{title}
					</Typography>
					<Button onClick={buttonAction} disabled={status === "PENDING"} variant="text" sx={{ padding: 0, color: theme.palette.primary.main }}>
						<Typography sx={{ fontWeight: "bold", marginRight: "4px" }}>{buttonText}</Typography>
						{status !== "PENDING" && <ArrowRight />}
					</Button>
				</Grid>
				<Grid item xs={9} sx={{display: "flex", justifyContent: "flex-end", padding: "12px"}}>
					<img src={icon} />
				</Grid>
			</Grid>
		</Paper>
	);
}
