import { useTranslation } from "react-i18next";

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

export default function RewardDetail({rewardDetails}) {
	const { t } = useTranslation("questBox");

	return (
		<Grid container>
			<Grid item xs={12} mt={2}>
				<TableContainer>
					<Table
						sx={{
							minWidth: 750,
							borderCollapse: "separate",
							borderSpacing: "0 0",
							border: "none",
						}}
					>
						<TableHead>
							<TableRow>
								{["", "total_volume", "your_volume", "reward"].map((item, ix) => (
									<TableCell sx={{ border: "none" }} key={ix}>
										<Typography textAlign="center" color="primary" fontWeight="bold">
											{item && t(`quest_box_leaderboard_tier_reward_dialog_header_${item}`)}
										</Typography>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody
							sx={{
								background: "#FFFFFF",

							}}
						>
							{rewardDetails?.map((detail, index) => {
								return (
									<TableRow>
										<TableCell sx={{ border: "none", padding: "8px" }}>
											<Typography sx={{ textAlign: "center", fontSize: "14px", color: (theme) => theme.palette.primary.main }} fontWeight="bold">
												{detail?.nickname}
											</Typography>
										</TableCell>
										<TableCell sx={{ border: "none", padding: "8px", background: (theme) => theme.palette.background.default }}>
											<Typography sx={{ textAlign: "center" }} fontWeight="bold">
												{detail?.totalVolume?.toFixed(2)}
											</Typography>
										</TableCell>
										<TableCell sx={{ border: "none", padding: "8px", background: (theme) => theme.palette.background.default }}>
											<Typography sx={{ textAlign: "center" }} fontWeight="bold">
												{detail?.userVolume?.toFixed(2)}
											</Typography>
										</TableCell>
										<TableCell sx={{ border: "none", padding: "8px", background: (theme) => theme.palette.background.default }}>
											<Typography
												sx={{
													textAlign: "center",
													color: (theme) => theme.palette.success.main,
												}}
												fontWeight="bold"
												fontSize="16px"
											>
												{detail?.reward?.toFixed(2)}
											</Typography>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	);
}
