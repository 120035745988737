import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Paper, Tab, Tabs } from "@mui/material";

import Safety from "../Safety";
import { LiveTest } from "../liveTest";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export default function RealTimePerformance() {
	const [tab, setTab] = useState(0);
	const { t } = useTranslation("marketplace");

	return (
		<Paper
			sx={{
				padding: "24px",
				borderRadius: "8px",
				minHeight: "70vh",
				// marginBottom: "16px",
				backgroundColor: "#FFF",
			}}
		>
			<Tabs
				variant="fullWidth"
				centered
				value={tab}
				onChange={(_, newValue) => {
					setTab(newValue);
				}}
			>
				<Tab
					fullWidth
					sx={{ textTransform: "none", color: "#000" }}
					label={t("marketplace_strategy_page_live_performance_title")}
					disabled={true}
					{...a11yProps(0)}
				></Tab>
			</Tabs>
			<LiveTest />
			<Safety />
		</Paper>
	);
}
