import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Box, Grid, Typography, Button } from "@mui/material";

const NoCampaign = ({ handleDialog }) => {
	const { t } = useTranslation("questBox");
	const navigate = useNavigate();

	return (
		<Box
			sx={{
				minHeight: "350px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
			}}
		>
			<Grid container justifyContent={"center"} pb={2}>
				<Grid xs={10}>
					<Box>
						<Typography
							color={(theme) => theme.palette.primary.main}
							fontWeight={"Bold"}
							sx={{ textAlign: "center", fontSize: "24px" }}
						>
							{t("quest_box_no_campaign_text_1")}
						</Typography>

						<Typography sx={{ textAlign: "center", fontSize: "16px" }}>
							{t("quest_box_no_campaign_text_2")}
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<Grid container justifyContent={"center"} pb={2}>
				<Grid xs={4}>
					<Button
						fullWidth
						variant="contained"
						onClick={() => {
							navigate("/quest-box");
							handleDialog(false);
						}}
					>
						{t("quest_box_no_campaign_btn_text")}
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};

export default NoCampaign;
