import { SideFutures, SideLong, SideShort, SideSpot } from "images";

const STRATEGIES_HEAD_CELLS = (t, onOrderChange) => [
	{
		id: "status",
		label: t("control_panel_robots_table_pair"),
		orderActive: Boolean(onOrderChange),
	},
	{
		id: "head-cell-strategy",
		label: t("control_panel_robot_table_strategy"),
		orderActive: false,
	},
	{
		id: "head-cell-expert",
		label: t("control_panel_robot_table_exp_str"),
		orderActive: false,
	},
	{
		id: "side",
		label: t("control_panel_robot_table_side"),
		orderActive: Boolean(onOrderChange),
	},
	{
		id: "exchange",
		label: t("control_panel_robot_table_exchange"),
		orderActive: Boolean(onOrderChange),
	},
	{
		id: "averageProfit",
		label: t("control_panel_robots_table_average_profit"),
		orderActive: Boolean(onOrderChange),
	},
	{
		id: "openPositions",
		label: t("control_panel_robots_table_pos_count"),
		orderActive: Boolean(onOrderChange),
	},
	{
		id: "successRatio",
		label: t("control_panel_robots_table_success_rate"),
		orderActive: Boolean(onOrderChange),
	},
	{
		id: "totalProfit",
		label: t("control_panel_robots_table_total_profit"),
		orderActive: Boolean(onOrderChange),
	},
	{
		id: "settings",
		label: t("control_panel_robots_table_settings"),
		orderActive: false,
	},
];

const SIDE_LOOKUP = {
	SHORT: {
		backgroundColor: "#7879F1",
		icon: SideShort,
	},
	LONG: {
		backgroundColor: "#0F20E8",
		icon: SideLong,
	},
	SPOT: {
		backgroundColor: "#A5A6F6",
		icon: SideSpot,
	},
	FUTURES: {
		backgroundColor: "#7879F1",
		icon: SideFutures,
	},
};
export { SIDE_LOOKUP, STRATEGIES_HEAD_CELLS };
