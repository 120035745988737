import { useTranslation } from "react-i18next";

import { Grid, Paper, Typography, useTheme } from "@mui/material";

import { InfoBlue } from "images";

export default function Safety() {
	const theme = useTheme();
	const { t } = useTranslation("marketplace");
	return (
		<>
			<Paper sx={{ mb: "4px", mt: "4px", borderRadius: "8px" }}>
				<Grid container sx={{ p: 2 }}>
					<Grid item xs={0.7}>
						<InfoBlue style={{ marginTop: 5 }} />
					</Grid>
					<Grid item xs={11.3}>
						<Typography
							sx={{
								fontSize: "0.8rem",
								[theme.breakpoints.up("xs")]: {
									ml: 3,
								},
								[theme.breakpoints.up("md")]: {
									ml: 3,
								},
								[theme.breakpoints.up("lg")]: {
									ml: 2,
								},
								[theme.breakpoints.up("xl")]: {
									ml: 1,
								},
							}}
						>
							{t("marketplace_strategy_page_disclaimer")}
						</Typography>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
}
