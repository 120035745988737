import { useTheme } from "@mui/material/styles";

export default function ({ active, disabled }) {
	const theme = useTheme();

	const color = disabled
		? theme.palette.info.dark
		: active
		? theme.palette.primary.main
		: theme.palette.primary.light;

	return (
		<svg width="33" height="24" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.0699 2.28245C2.63501 2.28245 2.28245 2.63501 2.28245 3.0699V20.9301C2.28245 21.365 2.63501 21.7175 3.0699 21.7175H26.99C27.4249 21.7175 27.7775 21.365 27.7775 20.9301V14.7742H30.0599V20.9301C30.0599 22.6256 28.6855 24 26.99 24H3.0699C1.37445 24 0 22.6256 0 20.9301V3.0699C0 1.37444 1.37445 0 3.0699 0H26.99C28.6855 0 30.0599 1.37444 30.0599 3.0699V8.61829H27.7775V3.0699C27.7775 2.63501 27.4249 2.28245 26.99 2.28245H3.0699Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M20.6218 10.2983C19.7393 10.3627 19.0471 11.1 19.0471 11.9941C19.0471 12.9357 19.8113 13.6945 20.7475 13.6945H29.4551C29.8633 13.6945 30.1969 13.361 30.1969 12.9527V11.0469C30.1969 10.6387 29.8633 10.3051 29.4551 10.3051H20.7475C20.7051 10.3051 20.6631 10.3028 20.6218 10.2983ZM20.9096 8.02264C20.8566 8.01512 20.8025 8.01123 20.7475 8.01123C18.5394 8.01123 16.7646 9.80333 16.7646 11.9941C16.7646 14.2023 18.5568 15.977 20.7475 15.977H29.4551C31.1239 15.977 32.4793 14.6216 32.4793 12.9527V11.0469C32.4793 9.37809 31.1239 8.02264 29.4551 8.02264H20.9096Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M22.4596 8.02295C23.0899 8.02295 23.6008 8.53389 23.6008 9.16418V14.8361C23.6008 15.4664 23.0899 15.9773 22.4596 15.9773C21.8293 15.9773 21.3184 15.4664 21.3184 14.8361V9.16418C21.3184 8.53389 21.8293 8.02295 22.4596 8.02295Z"
				fill={color}
			/>
		</svg>
	);
}
