import React from "react";

import { useTheme } from "@mui/material/styles";

export default function ({ active, fill, width, height }) {
	const theme = useTheme();

	const color = active ? theme.palette.primary.main : theme.palette.info.dark;

	return (
		<svg
			width={width ? width : "28"}
			height={height ? height : "28"}
			viewBox="0 0 25 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.7989 0.215855C12.1626 -0.0719524 12.6764 -0.0719515 13.0401 0.215857L24.4595 9.2533C24.8926 9.59604 24.9658 10.225 24.6231 10.658C24.2803 11.0911 23.6514 11.1643 23.2183 10.8216L12.4195 2.27528L1.62062 10.8216C1.18755 11.1643 0.558631 11.0911 0.215895 10.658C-0.12684 10.2249 -0.0536087 9.59603 0.379463 9.2533L11.7989 0.215855Z"
				fill={fill ? fill : color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.0361328 25C0.0361328 24.4477 0.483848 24 1.03613 24H23.875C24.4273 24 24.875 24.4477 24.875 25C24.875 25.5523 24.4273 26 23.875 26H1.03613C0.483848 26 0.0361328 25.5523 0.0361328 25Z"
				fill={fill ? fill : color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.4414 12.493C18.4998 13.0422 18.1019 13.5347 17.5527 13.5931L12.2138 14.1605L14.2783 17.7233C14.4499 18.0194 14.4581 18.3828 14.3 18.6864C14.142 18.9901 13.8396 19.1917 13.4985 19.221L6.66368 19.8075C6.11342 19.8547 5.62906 19.4469 5.58185 18.8967C5.53463 18.3464 5.94242 17.8621 6.49268 17.8148L11.758 17.363L9.71091 13.8303C9.54109 13.5373 9.53116 13.1782 9.68451 12.8762C9.83786 12.5742 10.1337 12.3703 10.4705 12.3345L17.3413 11.6043C17.8905 11.5459 18.383 11.9438 18.4414 12.493Z"
				fill={fill ? fill : color}
			/>
		</svg>
	);
}
