export const questTypes = (t) => [
	{
		name: t("quest_box_all_quests_title"),
		type: "ALL",
	},
	{
		name: t("quest_box_active_quests_title"),
		type: "ACTIVE",
	},
	{
		name: t("quest_box_merchant_quests_title"),
		type: "CREATOR",
	},
];
