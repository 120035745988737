import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Card, CardContent, CardHeader, DialogContent, Grid, Typography } from "@mui/material";

import { StrategyContext } from "./MyStrategiesDialog";
import {
	ADD_CONNECTION_SETTINGS,
	EDIT_CONNECTION_SETTINGS,
	SELECTION,
	SHOW_CONNECTIONS,
	SUCCESS,
} from "./utils/constant";

const Actions = ({ selected, type, title, subtitle, onClick, color, strategy }) => {
	return (
		<Grid item xs={12} sx={{ mt: 2 }} style={{ marginBottom: "5px" }}>
			<Card
				sx={{
					borderRadius: 2,
					border: "1px solid",
					borderColor: (theme) => (color ? color : theme.palette.primary.light),
					backgroundColor: `${selected === true ? (color ? color : "blue") : "white"}`,
					cursor: strategy.public === 1 ? "default" : "pointer",
				}}
				onClick={() => {
					if (!(type === "DELETE" && strategy.public === 1)) {
						onClick(type);
					}
				}}
			>
				<CardHeader
					title={
						<Typography
							sx={{
								color: (theme) =>
									`${selected === true ? "white" : color ? color : theme.palette.primary.main}`,
								fontSize: "1rem",
								fontWeight: 700,
							}}
						>
							{title}
						</Typography>
					}
					sx={{
						paddingBottom: "0",
					}}
				/>
				<CardContent>
					<Grid container spacing={1}>
						<Grid
							item
							xs={12}
							style={{
								fontSize: "12px",
								color: `${
									selected === true
										? "white"
										: !(type === "DELETE" && strategy.public === 1)
										? "black"
										: "#e0e0e0"
								}`,
							}}
						>
							{subtitle}
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};

const Selection = ({ children, contentProps, strategy }) => {
	const [selectedAction, setSelectedAction] = useState();

	const { setSelection, SelectionSettings } = useContext(StrategyContext);
	const toExclude = [SELECTION, SUCCESS, ADD_CONNECTION_SETTINGS, SHOW_CONNECTIONS, EDIT_CONNECTION_SETTINGS];
	const { t } = useTranslation("workshop");

	const handleActionClick = (type) => {
		setSelectedAction(type);
	};

	return (
		<>
			<DialogContent sx={{ paddingTop: "10vh !important" }} {...contentProps}>
				<Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
					<Grid item xs={12}>
						<Typography variant="h5">{t("workshop_actions_main_title")}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="p">{t("workshop_actions_main_text")}</Typography>
					</Grid>
				</Grid>

				{children}

				<Grid>
					{Object.keys(SelectionSettings)
						.filter((x) => !toExclude.includes(x))

						.map((key) => (
							<Actions
								type={key}
								title={SelectionSettings[key].title}
								subtitle={SelectionSettings[key].subtitle}
								selected={selectedAction === key}
								color={SelectionSettings[key].color}
								onClick={handleActionClick}
								strategy={strategy}
							/>
						))}
				</Grid>
				<Grid container spacing={2} sx={{ paddingTop: "1vh" }}>
					<Grid item xs={12}>
						<Button
							fullWidth
							variant="contained"
							onClick={() => {
								setSelection(selectedAction);
							}}
							disabled={!selectedAction}
						>
							{t("workshop_actions_button_next")}
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</>
	);
};

export default Selection;
