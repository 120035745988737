import React from "react";

import { Backdrop, Box } from "@mui/material";

import { TraderlandsLoaderIcon } from "./TraderlandsLoader";

export default function PageCenteredProgress({ open }) {
	return (
		<Backdrop sx={{ color: "#fff", bgcolor: "#F4F5FB", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
			<Box
				sx={{
					borderRadius: "50%",
					width: "150px",
					height: "103px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<TraderlandsLoaderIcon fill="#FF0000" />
			</Box>
		</Backdrop>
	);
}
