import { Box, SvgIcon, Typography } from "@mui/material";

import { FutureChip, LongChip, ShortChip, SpotChip } from "images";

const TradingType = ({ value, sx, ...rest }) => {
	return (
		<Box
			border={value === "SPOT" && "1px solid #0F20E8"}
			height={"1.8rem"}
			p={"0.2rem 0.45rem"}
			paddingTop="0.35rem"
			borderRadius={1}
			sx={{
				backgroundColor:
					value === "FUTURE" ? "#3A3A3A" : value === "LONG" ? "#0F20E8" : value === "SHORT" ? "#CFD2FA" : "",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				...sx,
			}}
			{...rest}
		>
			<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 0.5 }}>
				<SvgIcon>
					{value === "FUTURE" ? (
						<FutureChip />
					) : value === "SHORT" ? (
						<ShortChip />
					) : value === "SPOT" ? (
						<SpotChip />
					) : (
						<LongChip />
					)}
				</SvgIcon>
			</Box>

			<Typography
				sx={{
					color: value === "SPOT" ? "#0F20E8" : value === "SHORT" ? "#0F20E8" : "#fff",
				}}
				fontWeight={400}
				fontSize={12.5}
				ml={-0.4}
			>
				{value}
			</Typography>
		</Box>
	);
};

export default TradingType;
