import { Typography, useTheme } from "@mui/material";
import { Chip } from "components";

const CampaignItem = ({ title, titleProps, chips, chipProps }) => {
	const theme = useTheme();
	return (
		<>
			<Typography
				color={(theme) => theme.palette.primary.main}
				fontWeight={"Bold"}
				sx={{ fontSize: "24px", paddingBottom: "12px" }}
			>
				{title}
			</Typography>
            {chips?.map((chip) => 
                <Chip
                    backgroundColor={chip?.bgColor ? chip?.bgColor : theme.palette.info.light}
                    label={chip?.label}
                    sx={{
                        fontSize: "12px",
                        color: chip?.color ? chip?.color : theme.palette.primary.main,
                        borderRadius: "4px",
                        py: 2,
                        px: 1,
                        mr: 1,
                    }}
                />
            )}
		</>
	);
};

export default CampaignItem;
