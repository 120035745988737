import { useIdleTimer } from "react-idle-timer";

export default function ActivityDetection({ children }) {
	const onAction = () => {
		if (["agreements", "marketplace"].some((str) => window.location.pathname.replace("/", "").includes(str))) {
			return;
		}

		const parsedParaticaKey = JSON.parse(localStorage.getItem("persist:paratica:paratica-web") ?? "{}");

		const { jwt } = JSON.parse(parsedParaticaKey.jwt ?? "{}");

		if (
			[
				"register",
				"login",
				"login-verification",
				"register-verification",
				"register-success",
				"forgot-password",
			].includes(window.location.pathname.replace("/", ""))
		) {
			if (jwt) {
				window.location.replace("/dashboard");
			}

			return;
		}

		if (!jwt) {
			localStorage.clear();

			window.location.replace("/login");
		}
	};

	const { getElapsedTime } = useIdleTimer({
		onAction,
		timeout: 60 * 1000, // in milliseconds
		throttle: 1, // take only the first action into consideration
	});

	return children;
}
