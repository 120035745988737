import React from "react";

import { useTheme } from "@mui/material/styles";

export default function ({ active }) {
	const theme = useTheme();

	const color = active ? theme.palette.primary.main : theme.palette.info.dark;

	return (
		<svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M26 15.0339V7.3855L16.9181 10.3241L14 4.51855L11.0819 10.3241L2 7.3855V15.0339C4.45734 17.4913 23.5324 17.4913 25.9898 15.0339H26Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.0005 3.49512C14.3875 3.49512 14.7415 3.71337 14.9153 4.05918L17.439 9.07998L25.6853 6.41179C25.9969 6.31096 26.3379 6.3653 26.6028 6.55798C26.8677 6.75066 27.0243 7.05842 27.0243 7.38595V15.0344C27.0243 15.3733 26.8597 15.6737 26.606 15.8601C26.1086 16.3005 25.3844 16.5989 24.6511 16.8207C23.821 17.0718 22.8086 17.2728 21.694 17.4304C19.4613 17.7462 16.7196 17.9013 13.9953 17.9013C11.271 17.9013 8.52936 17.7462 6.29662 17.4304C5.18208 17.2728 4.16963 17.0718 3.33953 16.8207C2.55113 16.5822 1.77334 16.2553 1.27645 15.7584C1.08444 15.5664 0.976562 15.3059 0.976562 15.0344V7.38595C0.976562 7.05842 1.13326 6.75066 1.39812 6.55798C1.66298 6.3653 2.00403 6.31096 2.31566 6.41179L10.562 9.07998L13.0857 4.05917C13.2595 3.71336 13.6134 3.49512 14.0005 3.49512ZM14.0005 6.79887L11.9972 10.7843C11.7703 11.2358 11.2479 11.4542 10.7672 11.2987L3.02434 8.7934V14.5097C3.22291 14.6131 3.51716 14.735 3.93246 14.8607C4.62549 15.0703 5.52563 15.2533 6.58334 15.4028C8.69509 15.7014 11.3365 15.8535 13.9953 15.8535C16.6541 15.8535 19.2956 15.7014 21.4073 15.4028C22.465 15.2533 23.3652 15.0703 24.0582 14.8607C24.4807 14.7329 24.7779 14.6089 24.9766 14.5043V8.7934L17.2338 11.2987C16.753 11.4542 16.2306 11.2358 16.0037 10.7843L14.0005 6.79887Z"
				fill={color}
			/>
			<path
				d="M13.9997 7.05802C15.3965 7.05802 16.5287 5.92571 16.5287 4.52898C16.5287 3.13225 15.3965 2 13.9997 2C12.603 2 11.4707 3.13225 11.4707 4.52898C11.4707 5.92571 12.603 7.05802 13.9997 7.05802Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.0002 3.02434C13.1689 3.02434 12.495 3.69821 12.495 4.52943C12.495 5.36069 13.1689 6.03458 14.0002 6.03458C14.8314 6.03458 15.5053 5.36072 15.5053 4.52943C15.5053 3.69818 14.8315 3.02434 14.0002 3.02434ZM10.4473 4.52943C10.4473 2.56719 12.038 0.976562 14.0002 0.976562C15.9624 0.976562 17.5531 2.56722 17.5531 4.52943C17.5531 6.49161 15.9624 8.08237 14.0002 8.08237C12.038 8.08237 10.4473 6.49165 10.4473 4.52943Z"
				fill={color}
			/>
		</svg>
	);
}
