import { useTheme } from "@mui/material/styles";

export default function ({ active }) {
	const theme = useTheme();

	const color = active ? theme.palette.primary.main : theme.palette.primary.light;

	return (
		<svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.7989 0.809117C12.1626 0.521309 12.6764 0.52131 13.0401 0.809119L24.4595 9.84656C24.8926 10.1893 24.9658 10.8182 24.6231 11.2513C24.2803 11.6844 23.6514 11.7576 23.2183 11.4148L12.4195 2.86854L1.62062 11.4148C1.18755 11.7576 0.558631 11.6844 0.215895 11.2513C-0.12684 10.8182 -0.0536087 10.1893 0.379463 9.84656L11.7989 0.809117Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M0.0361328 25.5933C0.0361328 25.041 0.483848 24.5933 1.03613 24.5933H23.875C24.4273 24.5933 24.875 25.041 24.875 25.5933C24.875 26.1455 24.4273 26.5933 23.875 26.5933H1.03613C0.483848 26.5933 0.0361328 26.1455 0.0361328 25.5933Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M18.4414 13.0863C18.4998 13.6355 18.1019 14.128 17.5527 14.1864L12.2138 14.7538L14.2783 18.3165C14.4499 18.6127 14.4581 18.9761 14.3 19.2797C14.142 19.5833 13.8396 19.785 13.4985 19.8143L6.66368 20.4008C6.11342 20.448 5.62906 20.0402 5.58185 19.4899C5.53463 18.9397 5.94242 18.4553 6.49268 18.4081L11.758 17.9563L9.71091 14.4236C9.54109 14.1305 9.53116 13.7714 9.68451 13.4694C9.83786 13.1674 10.1337 12.9636 10.4705 12.9278L17.3413 12.1976C17.8905 12.1392 18.383 12.5371 18.4414 13.0863Z"
				fill={color}
			/>
		</svg>
	);
}
