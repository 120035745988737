import { useTranslation } from "react-i18next";

import { Area, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export default function VolumeAreaChart({ data }) {
	const { t } = useTranslation("marketplace");
	function getWeekDates(year, weekNumber) {
		const januaryFirst = new Date(year, 0, 1);
		const daysToFirstDay = (weekNumber - 1) * 7;

		januaryFirst.setDate(januaryFirst.getDate() + daysToFirstDay);

		const dayOfWeek = januaryFirst.getDay();

		const startDate = new Date(januaryFirst);
		startDate.setDate(januaryFirst.getDate() - dayOfWeek);

		const endDate = new Date(startDate);
		endDate.setDate(startDate.getDate() + 7);

		const startMonth = startDate.toLocaleString("en-US", { month: "long" });
		const endMonth = endDate.toLocaleString("en-US", { month: "long" });

		const result = `${startMonth} ${startDate.getDate()} - ${endMonth} ${endDate.getDate()}`;

		return result;
	}

	function CustomTooltip({ payload, label, active }) {
		if (active && label) {
			const [year, weekNo] = label.split("-").map(Number);
			return (
				<div
					className="custom-tooltip"
					style={{ background: "#FFFFFF", padding: "3px", border: "0.2px solid grey" }}
				>
					<p className="label" style={{ marginBottom: "8px" }}>{`${getWeekDates(year, weekNo)}`}</p>
					<p style={{ margin: "2px", color: "#2521E8" }}>
						{t("marketplace_user_stats_area_line_chart_area_value")} : {`${payload[0].value}`}
					</p>
					<p style={{ color: "#808080" }}>
						{t("marketplace_user_stats_area_line_chart_line_value")} : {`${payload[1].value}`}
					</p>
				</div>
			);
		}

		return null;
	}
	return (
		<div style={{ width: "100%", height: 600 }}>
			<ResponsiveContainer>
				<ComposedChart data={data} margin={{ top: 20, right: 0, bottom: 20, left: 0 }}>
					<XAxis dataKey="label" tickLine={false} axisLine={false} dy={5} />
					<YAxis yAxisId="left" tickLine={true} axisLine={true} />
					<YAxis
						yAxisId="right"
						orientation="right"
						tickLine={true}
						tickFormatter={(tick) => {
							if (tick >= 1000000) {
								return (tick / 1000000).toString() + "M";
							} else {
								return (tick / 1000).toString() + "K";
							}
						}}
						axisLine={true}
					/>
					<Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
					<Legend />
					<CartesianGrid stroke="#f5f5f5" vertical={false} />
					<Area
						type="basis"
						yAxisId="right"
						name={t("marketplace_user_stats_area_line_chart_area_value")}
						dataKey="volume"
						fill="#2521E8"
						stroke="#2521E8"
					/>{" "}
					<Line
						yAxisId="left"
						type="linear"
						name={t("marketplace_user_stats_area_line_chart_line_value")}
						dataKey="averageProfit"
						stroke="#41527D"
						strokeWidth={2}
					/>
				</ComposedChart>
			</ResponsiveContainer>
		</div>
	);
}
