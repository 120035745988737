import { useTranslation } from "react-i18next";

import { Box, Button, Card, Typography } from "@mui/material";

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { algorithmicTradeMadeSimple } from "images";

dayjs.extend(isSameOrAfter);

const ArrivalCards = ({ campaign }) => {
	const { t, i18n } = useTranslation("questBox");

	const lang = i18n.resolvedLanguage;

	const campaignPassed = dayjs().isSameOrAfter(dayjs(campaign?.startDate));

	return (
		<Card
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "flex-start",
				borderRadius: "1.1rem",
				height: "10rem",
				minWidth: "488px",
				maxWidth: "100%",
				paddingBlockStart: "1rem",
				backgroundColor: (theme) => (campaignPassed ? theme.palette.info.main : "none"),
			}}
		>
			<Box padding="0.8rem">
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<Typography
						variant="h3"
						sx={{
							fontSize: "1rem",
							fontWeight: 700,
							color: campaignPassed ? "#3A3A3A" : "#0F20E8",
							maxWidth: "100%",
							marginInlineEnd: "0.5rem",
							width: "14rem",
						}}
					>
						{campaign.title[lang]}
					</Typography>

					{!campaignPassed && (
						<Button
							sx={{
								backgroundColor: "#F4F5FC",
								height: "2rem",
								lineHeight: "1rem",
								width: "8rem",
							}}
						>
							{t("quest_box_coming_soon_chip_text")}
						</Button>
					)}
				</Box>

				<Typography color="#3A3A3A" fontSize="12px">
					{campaign.description[lang]}
				</Typography>
			</Box>

			<div>
				<img
					src={campaign?.imageUrl ? campaign?.imageUrl : algorithmicTradeMadeSimple}
					alt="imageUrl"
					style={{
						width: "6rem",
						marginInlineEnd: "2rem",
					}}
				/>
			</div>
		</Card>
	);
};

export default ArrivalCards;
