import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, FormControlLabel, Grid, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material";

import { useSnackbar } from "notistack";

import { Breadcrumbs, Protection, RouteLayout } from "components";

import { useUserApi } from "api/user";

import { IslemSirasi } from "images";

export default function DeleteAccountReason() {
	const { t } = useTranslation("accountCenter");
	const { enqueueSnackbar } = useSnackbar();
	const { sendOtp, annulConfirm } = useUserApi();

	const navigate = useNavigate();

	const [selectedValue, setSelectedValue] = useState("");
	const [textFieldValue, setTextFieldValue] = useState("");
	const [open, setOpen] = useState(false);
	const [reason, setReason] = useState("");
	const [isReasonSelected, setIsReasonSelected] = useState(false);

	const handleDeleteAccountLogout = () => {
		localStorage.clear();
		window.location.replace("/login");
	};

	const handleRadioChange = (event) => {
		setSelectedValue(event.target.value);
		setIsReasonSelected(true);
	};
	const handleTextFieldChange = (event) => {
		setTextFieldValue(event.target.value);
	};

	const handleNext = () => {
		const updatedReason =
			selectedValue === "account_center_settings_delete_flow_reason_6" ? textFieldValue : t(selectedValue);
		setReason(updatedReason);
		setOpen(true);
	};

	const otpAction = (operation, body, transactionId) => annulConfirm(operation.method, body, transactionId);

	return (
		<RouteLayout
			headerComp={
				<Breadcrumbs
					paths={[
						{
							text: t("account_center_menu_main_title"),
							onClick: () => navigate("/account-center"),
						},
						{
							text: t("account_center_settings_main_title"),
							onClick: () => navigate("/settings"),
						},
						{
							text: t("account_center_settings_delete_account_title"),
						},
					]}
				/>
			}
		>
			<Grid container sx={{ mb: 2 }}>
				<Grid item xs={12}>
					<Paper sx={{ backgroundColor: "#FFFFFF", padding: 4 }}>
						<Grid container>
							<Grid item xs={12}>
								<Typography sx={{ mt: 2, fontSize: "24px", fontWeight: 700 }}>
									{t("account_center_settings_delete_flow_main_title")}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography sx={{ mt: 2, fontSize: "14px" }}>
									{t("account_center_settings_delete_flow_reason_main_title")}
								</Typography>
							</Grid>
							<Paper
								sx={{
									mt: 2,
									backgroundColor: "#F4F5FC",
									width: "100%",
									padding: 5,
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<Typography sx={{ mt: 2, mb: 2, textAlign: "center", fontWeight: "bold" }}>
									{t("account_center_settings_delete_flow_reason_title")}
								</Typography>
								<Grid item xs={12}>
									<RadioGroup value={selectedValue} onChange={handleRadioChange}>
										{Array.from({ length: 6 }, (_, i) => i + 1).map((number) => {
											const translationKey = `account_center_settings_delete_flow_reason_${number}`;
											const label = t(translationKey);
											return (
												<FormControlLabel
													key={translationKey}
													value={translationKey}
													control={<Radio />}
													label={<Typography>{label}</Typography>}
												/>
											);
										})}
									</RadioGroup>
								</Grid>
								<Grid item xs={12}>
									<TextField
										margin="normal"
										name="text"
										fullWidth
										multiline
										sx={{
											mr: 45,
											ml: 15,
											width: {
												md: "70%",
												lg: "80%",
												xl: "100%",
											},
											"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
												borderColor: (theme) => theme.palette.text.dark,
											},
											"& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
												borderColor: (theme) => theme.palette.info.dark,
											},
										}}
										minRows="6"
										value={textFieldValue}
										onChange={handleTextFieldChange}
										disabled={selectedValue !== "account_center_settings_delete_flow_reason_6"}
										placeholder={t("account_center_settings_delete_flow_reason_other_placeholder")}
										inputProps={{ maxLength: 240 }}
									/>
								</Grid>
							</Paper>
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs={8} sx={{ mt: 2 }}></Grid>
				<Grid item xs={4} sx={{ mt: 2 }}>
					<Button
						variant="contained"
						disabled={isReasonSelected === false}
						fullWidth
						onClick={() => handleNext(true)}
					>
						{t("common:Next")}
					</Button>
				</Grid>
			</Grid>

			{open && (
				<Protection
					open={open}
					title={t("account_center_settings_update_protection_otp_title")}
					explanation={t("account_center_settings_update_protection_otp_text")}
					success={{
						icon: <IslemSirasi />,
						content: (
							<Typography sx={{ mt: 2, textAlign: "center", fontSize: "14px" }}>
								{t("account_center_settings_delete_flow_modal_text")}
							</Typography>
						),
						title: t("account_center_settings_delete_flow_modal_title"),
						actions: (
							<Button
								variant="outlined"
								sx={{ width: 300, mt: 2 }}
								color="primary"
								onClick={() => handleDeleteAccountLogout()}
							>
								{t("account_center_settings_delete_flow_modal_close_button_text")}
							</Button>
						),
					}}
					operation={{ path: "/annul/confirm", body: { reason }, method: "PATCH" }}
					onClose={() => setOpen()}
					sendOtp={(method, transactionId) =>
						sendOtp(method, transactionId).then((_) => {
							enqueueSnackbar(t("common:Code sent successfully"), { variant: "success" });
						})
					}
					otpAction={otpAction}
					onComplete={() => handleDeleteAccountLogout()}
				/>
			)}
		</RouteLayout>
	);
}
