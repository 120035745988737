import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, DialogContent, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";

import { useSnackbar } from "notistack";

import { useFetchAuthorized } from "services";
import Config from "services/config";

import AnimatedTimedButton from "./AnimatedTimedButton";
import { StrategyContext } from "./MyStrategiesDialog";
import { SUCCESS } from "./utils/constant";

export default function AddConnectionSettings({ contentProps }) {
	const { t } = useTranslation("workshop");
	const { selectedNetwork, strategy, setSuccessMessage, setSelection } = useContext(StrategyContext);
	const [channelName, setChannelName] = useState("");
	const [language, setLanguage] = useState("tr");
	const [id, setId] = useState("");
	const [token, setToken] = useState("");
	const { enqueueSnackbar } = useSnackbar();
	const fetchAuthorized = useFetchAuthorized();
	const [refCodeToUse, setRefCodeToUse] = useState("");

	useEffect(() => {
		fetchAuthorized(`${Config.apiRoot()}/user/reference-codes`, {
			headers: {
				"Content-type": "application/json",
			},
			method: "GET",
		})
			.then((response) => {
				if (response.status !== 200 || response.error !== undefined) {
					throw new Error(
						JSON.stringify({
							status: response.status,
							code: response.error?.code,
							detail: response.error?.detail,
						})
					);
				}
				response.json().then((data) => {
					setRefCodeToUse(
						data.data.referenceCodes.length > 0
							? data.data.referenceCodes.reduce((prev, current) =>
									prev.createdAt > current.createdAt ? prev : current
							  ).code
							: null
					);
				});
			})
			.catch((err) => {
				JSON.parse(err.message);
			});
	}, []);

	const handleAddConnection = () => {
		fetchAuthorized(`${Config.apiRoot()}/strategy/strategy/${strategy?.id}/commercialSettings`, {
			headers: {
				"Content-type": "application/json",
			},
			method: "PUT",
			body: JSON.stringify({
				commercialSettings: {
					platform: selectedNetwork,
					channelName,
					language,
					id,
					token,
					enabled: true,
					refCode: refCodeToUse,
				},
			}),
		})
			.then((response) => {
				if (response.status !== 200 || response.error !== undefined) {
					throw new Error(
						JSON.stringify({
							status: response.status,
							code: response.error?.code,
							detail: response.error?.detail,
						})
					);
				}
				setSuccessMessage({
					title: t("workshop_commercial_settings_add_success"),
				});
				setSelection(SUCCESS);
			})
			.catch((err) => {
				const error = JSON.parse(err.message);
				enqueueSnackbar(
					`${t("workshop_commercial_settings_error")} ${
						error.status ? error.status : error.detail ? error.detail : ""
					} ${error.detail ? error.detail : ""}`,
					{
						variant: "error",
					}
				);
			});
	};

	const handleTestConnection = () => {
		fetchAuthorized(`${Config.apiRoot()}/strategy/strategy/${strategy?.id}/testCommercialNotification`, {
			headers: {
				"Content-type": "application/json",
			},
			method: "POST",
			body: JSON.stringify({
				platform: selectedNetwork,
				id,
				token,
				language,
			}),
		})
			.then((response) => {
				if (response.status !== 200 || response.error !== undefined) {
					throw new Error(
						JSON.stringify({
							status: response.status,
							code: response.error?.code,
							detail: response.error?.detail,
						})
					);
				}
			})
			.catch((err) => {
				const error = JSON.parse(err.message);
				enqueueSnackbar(
					`${t("workshop_commercial_settings_error")} ${
						error.status ? error.status : error.detail ? error.detail : ""
					} ${error.detail ? error.detail : ""}`,
					{
						variant: "error",
					}
				);
			});
	};

	return (
		<>
			<DialogContent sx={{ paddingTop: "10vh !important" }} {...contentProps}>
				<Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
					<Grid item xs={12}>
						<Typography variant="h5">
							{t(`workshop_commercial_settings_add_${selectedNetwork}_header`)}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="p">
							{t(`workshop_commercial_settings_add_${selectedNetwork}_subtext`)}
						</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{ paddingTop: "3vh" }}>
					<Grid item xs={12}>
						<Typography sx={{ color: (theme) => theme.palette.primary.main }}>
							{t(`workshop_commercial_settings_channel_name`)}
						</Typography>
						<TextField
							fullWidth
							sx={{ marginBottom: 4 }}
							onChange={(e) => {
								setChannelName(e.target.value);
							}}
						/>
						<Typography sx={{ color: (theme) => theme.palette.primary.main }}>
							{t(`workshop_commercial_settings_channel_language`)}
						</Typography>
						<Select
							fullWidth
							sx={{ marginBottom: 4 }}
							labelId="language-select-menu"
							id="language-select-menu"
							defaultValue="Turkish"
							value={language === "en" ? "English" : "Turkish"}
							onChange={(e) => {
								setLanguage(e.target.value === "English" ? "en" : "tr");
							}}
						>
							<MenuItem value={"English"}>
								{t(`workshop_commercial_settings_channel_language_english`)}
							</MenuItem>
							<MenuItem value={"Turkish"}>
								{t(`workshop_commercial_settings_channel_language_turkish`)}
							</MenuItem>
						</Select>
						<Typography sx={{ color: (theme) => theme.palette.primary.main }}>
							{t(`workshop_commercial_settings_id_${selectedNetwork}`)}
						</Typography>
						<TextField fullWidth sx={{ marginBottom: 4 }} onChange={(e) => setId(e.target.value)} />
						<Typography sx={{ color: (theme) => theme.palette.primary.main }}>
							{t(`workshop_commercial_settings_token_${selectedNetwork}`)}
						</Typography>
						<TextField fullWidth onChange={(e) => setToken(e.target.value)} />
					</Grid>
					<Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginBottom: -2 }}>
						<AnimatedTimedButton
							fullWidth
							handleTestConnection={handleTestConnection}
							buttonText={t("workshop_commercial_settings_channel_test_button")}
						/>
					</Grid>
					<Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
						<Button fullWidth sx={{ minWidth: "520px" }} variant="contained" onClick={handleAddConnection}>
							{t("workshop_commercial_settings_channel_add_button")}
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</>
	);
}
