import React from "react";

import { useTheme } from "@mui/material/styles";

export default function Questbox({ active }) {
	const theme = useTheme();

	const color = active ? theme.palette.primary.main : theme.palette.primary.light;

	return (
		<svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M10.0413 0.145757C10.3796 -0.0485857 10.7965 -0.0485856 11.1349 0.145757L20.6291 5.59882C20.9676 5.79323 21.1761 6.15259 21.1761 6.54149V17.4476C21.1761 17.8362 20.9679 18.1953 20.6299 18.3898L11.1357 23.8538C10.7969 24.0487 10.3792 24.0487 10.0405 23.8538L0.546199 18.3898C0.208171 18.1953 0 17.8362 0 17.4476V6.54149C0 6.15259 0.208528 5.79323 0.547018 5.59882L10.0413 0.145757ZM2.18761 7.16999V16.8197L10.5881 21.6542L18.9885 16.8197V7.16999L10.5881 2.34516L2.18761 7.16999Z"
				fill={color ?? "#CFD2FA"}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M0.146659 5.99764C0.448643 5.47701 1.11758 5.29856 1.64078 5.59906L10.5882 10.7381L19.5357 5.59906C20.0589 5.29856 20.7278 5.47701 21.0298 5.99764C21.3318 6.51826 21.1525 7.18392 20.6293 7.48442L11.135 12.9375C10.7967 13.1318 10.3798 13.1318 10.0415 12.9375L0.5472 7.48442C0.0240043 7.18392 -0.155324 6.51826 0.146659 5.99764Z"
				fill={color ?? "#CFD2FA"}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M10.8731 10.9062C11.4772 10.9062 11.9669 11.3936 11.9669 11.9947V22.9117C11.9669 23.5128 11.4772 24.0001 10.8731 24.0001C10.269 24.0001 9.7793 23.5128 9.7793 22.9117V11.9947C9.7793 11.3936 10.269 10.9062 10.8731 10.9062Z"
				fill={color ?? "#CFD2FA"}
			/>
		</svg>
	);
}
