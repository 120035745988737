import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import dayjs from "dayjs";
import jwtDecode from "jwt-decode";

import { AuthContext, FetchError } from "./AuthContext";

export const abortController = new AbortController();

export default function AuthProvider({ children }) {
	const [user, setUser] = useState();

	const { jwt: selectedJWT } = useSelector((state) => state.jwt);

	const fetchAuthorized = async (url, opt = {}) => {
		if (!selectedJWT) {
			throw new FetchError("Not authenticated");
		}

		opt.headers = {
			"Content-type": "application/json",
			Authorization: `Bearer ${selectedJWT.accessToken}`,
			...opt.headers,
			signal: abortController.signal,
		};

		return fetch(url, opt);
	};

	useEffect(() => {
		if (selectedJWT?.accessToken) {
			setUser(jwtDecode(selectedJWT?.accessToken));
		}

		const checkTokenExpiration = setInterval(() => {
			const parsedParaticaKey = JSON.parse(localStorage.getItem("persist:paratica:paratica-web") ?? "{}");

			const { jwt } = JSON.parse(parsedParaticaKey.jwt ?? "{}");

			if (jwt) {
				const decodedJwt = jwtDecode(jwt.accessToken);

				if (!dayjs().isBefore(dayjs.unix(decodedJwt.exp))) {
					localStorage.clear();

					window.location.replace("/login");

					return;
				}
			}
		}, 500);

		return () => {
			clearInterval(checkTokenExpiration);
		};
	}, [selectedJWT]);

	return (
		<AuthContext.Provider
			value={{
				fetchAuthorized,
				user,
				setUser,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}
