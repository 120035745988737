import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { Dialog } from "components";

import ParityPositionHistory from "./Positions";

const Results = ({ symbol }) => {
	const { t } = useTranslation("workshop");

	return (
		<Grid container sx={{ backgroundColor: "#F4F5FC", p: 2, borderRadius: "8px", mx: 4, mt: 2 }}>
			<Grid item xs={12} sx={{ mt: 2 }}>
				<ParityPositionHistory symbol={symbol} />
			</Grid>
		</Grid>
	);
};

export default function DetailDialog({ symbol, open, onClose }) {
	return (
		<Dialog
			title={<></>}
			titleProps={{ sx: { alignSelf: "flex-start", fontSize: "16px" } }}
			content={<Results symbol={symbol} />}
			dialogProps={{ open, onClose, maxWidth: "lg", fullWidth: true }}
		/>
	);
}
