import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
	CircularProgress,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from "@mui/material";

import CustomTableHead from "components/CustomTableHead";

import { useMarketplaceApi } from "api/marketplace";

import { Details } from "images";

import Pair from "../Positions/columns/Pair";
import DetailDialog from "./Dialog";
import { PercentChange } from "./columns";

const getBaseAndQuote = (symbol) => {
	const cleanedSymbol = symbol.replace(/-|SWAP/g, "");
	const quotes = ["USDT", "USD", "TRY", "BTC"];
	let base = "";
	let quote = "";
	symbol = cleanedSymbol;

	for (const quoteSymbol of quotes) {
		if (symbol.endsWith(quoteSymbol)) {
			quote = quoteSymbol;
			base = symbol.replace(quoteSymbol, "");
			break;
		}
	}

	return { base, quote };
};

export default function ParityAnalytics({ marketStrategyId }) {
	const { t } = useTranslation("marketplace");
	const { getMarketStrategyAnalytics } = useMarketplaceApi();

	const POSITIONS_HEAD_CELLS = [
		{
			id: "pair",
			label: t("marketplace_parity_based_analytics_pair"),
		},
		{
			id: "successRate",
			label: t("marketplace_merchant_profile_success_rate_title"),
		},
		{
			id: "totalProfit",
			label: t("marketplace_merchant_profile_total_profit_title"),
		},
		{
			id: "averageProfit",
			label: t("marketplace_merchant_profile_details_tab_avg_profit"),
		},
		{
			id: "positionCount",
			label: t("marketplace_strategy_page_metrics_pos_count"),
		},
	];

	const [openDetail, setOpenDetail] = useState(false);
	const [selectedSymbol, setSelectedSymbol] = useState();
	const [processing, setProcessing] = useState(true);

	const [analytics, setAnalytics] = useState([]);
	const [filter, setFilter] = useState({
		where: {
			marketStrategyId: marketStrategyId,
			funnelId: "4",
		},
		orderBy: {
			totalProfit: "desc",
		},
	});
	useEffect(() => {
		setProcessing(true);
		getMarketStrategyAnalytics(filter)
			.then((data) => {
				console.log(data);
				setAnalytics(data);
			})
			.then(() => {
				setProcessing(false);
			})
			.catch((e) => {
				console.log(e);
			});
	}, [filter]);

	const handleDetail = (symbol) => {
		setSelectedSymbol(symbol);
		setOpenDetail(true);
	};
	return (
		<>
			{processing ? (
				<CircularProgress />
			) : (
				<TableContainer>
					<Table sx={{ minWidth: 750, borderCollapse: "separate", borderSpacing: "0 0.75rem" }}>
						<CustomTableHead expand headCells={POSITIONS_HEAD_CELLS} />
						<TableBody
							sx={{
								backgroundColor: "#F0F0F5",
								mt: 4,
							}}
						>
							{analytics?.length <= 0 ? (
								<TableCell colSpan={POSITIONS_HEAD_CELLS.length + 1} sx={{ bgcolor: "white" }}>
									<Typography sx={{ textAlign: "center" }}>
										{t("marketplace_parity_position_not_found")}
									</Typography>
								</TableCell>
							) : (
								analytics?.map((item, index) => {
									const { symbol, successRatio, totalProfit, averageProfit, positionCount } = item;
									const parity = getBaseAndQuote(symbol);
									return (
										<React.Fragment key={index}>
											<TableRow key={index}>
												<TableCell>
													<Pair parity={parity} />
												</TableCell>
												<TableCell>
													<PercentChange value={successRatio} />
												</TableCell>
												<TableCell>
													<PercentChange value={totalProfit} />
												</TableCell>
												<TableCell>
													<PercentChange value={averageProfit} />
												</TableCell>

												<TableCell>
													<Typography sx={{ fontSize: "1.15rem" }}>
														{positionCount}
													</Typography>
												</TableCell>
												<TableCell>
													<IconButton
														sx={{
															"&:hover": {
																backgroundColor: "inherit",
															},
														}}
														onClick={() => {
															handleDetail(symbol);
														}}
													>
														{" "}
														<Details />{" "}
													</IconButton>
												</TableCell>
											</TableRow>
										</React.Fragment>
									);
								})
							)}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{openDetail && (
				<DetailDialog symbol={selectedSymbol} open={openDetail} onClose={() => setOpenDetail(false)} />
			)}
		</>
	);
}
