import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Button, DialogContent, Grid, Typography } from "@mui/material";

import { Success2 } from "images";

import { StrategyContext } from "./MyStrategiesDialog";

const Success = () => {
	const { t } = useTranslation("workshop");

	const { onClose, successMessage } = useContext(StrategyContext);

	return (
		<>
			<DialogContent sx={{ paddingTop: "10vh !important", width: "35.5rem" }}>
				<Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
					<Grid item xs={12}>
						<Success2 />
					</Grid>
					<Grid item xs={12}>
						<Typography variant="p" style={{ fontWeight: "bold", color: "#3A3A3A" }}>
							{successMessage.title}
						</Typography>
					</Grid>
				</Grid>
				<Grid item xs={12} style={{ marginTop: "2.5rem" }}>
					<Button
						fullWidth
						variant="outlined"
						onClick={() => {
							onClose();
							window.location.reload();
						}}
					>
						{t("common:Close")}
					</Button>
				</Grid>
			</DialogContent>
		</>
	);
};

export default Success;
