import React from "react";

import { Box } from "@mui/material";

import { BinanceChip, BinanceTrChip, BitmexChip, DyDxChip, FtxChip, FtxTRChip, HuobiChip, OkxChip } from "images";

const StrategyExchangeType = ({ value, chipWidth = 80, chipHeight = 24, ...props }) => {
	const renderChip = () => {
		switch (value) {
			case "bitmex":
				return <BitmexChip width={chipWidth} height={chipHeight} />;
			case "binanceTr":
				return <BinanceTrChip width={chipWidth} height={chipHeight} />;
			case "huobi":
				return <HuobiChip width={chipWidth} height={chipHeight} />;
			case "dYdX":
				return <DyDxChip width={chipWidth} height={chipHeight} />;
			case "binance":
				return <BinanceChip width={chipWidth} height={chipHeight} />;
			case "ftx":
				return <FtxChip width={chipWidth} height={chipHeight} />;
			case "ftxTr":
				return <FtxTRChip width={chipWidth} height={chipHeight} />;
			case "okx":
				return <OkxChip width={chipWidth} height={chipHeight} />;
			default:
				return <></>;
		}
	};

	return <Box {...props}>{renderChip()}</Box>;
};

export default StrategyExchangeType;
