import { Box, Grid, Radio, Typography } from "@mui/material";

export default function SelectionCard({ icon, title, text, value, status, handleSetSelectedNetwork }) {
	return (
		<Grid container sx={{ borderRadius: 1, border: "1px solid #CFD2FA", marginTop: 2, minWidth: "520px" }}>
			<Grid item xs={2} sx={{ alignSelf: "center" }}>
				<Box component="img" src={icon} sx={{ ml: 3, width: 25 }} />
			</Grid>
			<Grid item xs={9}>
				<Typography variant="h6" sx={{ mt: 2 }}>
					{title}
				</Typography>
				<Typography sx={{ mt: 1, mb: 1, fontSize: "14px" }}>{text} </Typography>
			</Grid>
			<Grid item xs={1} sx={{ alignSelf: "center" }}>
				<Radio
					checked={value.toLowerCase() == status.toLowerCase()}
					value={value}
					onChange={() => {
						handleSetSelectedNetwork(value);
					}}
				/>
			</Grid>
		</Grid>
	);
}
