export default function shortenNumber(num) {
	let str,
		suffix = "";

	if (num < 10000) {
		str = num.toFixed(2);
	} else if (num < 1000000) {
		str = (num / 1000).toFixed(2);
		suffix = "K";
	} else if (num < 1000000000) {
		str = (num / 1000000).toFixed(2);
		suffix = "M";
	} else if (num < 1000000000000) {
		str = (num / 1000000000).toFixed(2);
		suffix = "B";
	} else if (num < 1000000000000000) {
		str = (num / 1000000000000).toFixed(2);
		suffix = "T";
	}
	return str + suffix;
}
