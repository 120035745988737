import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	IconButton,
	InputAdornment,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";

import { Dialog, TextField } from "components";

import { Config } from "services";

import useCopyToClipboard from "utils/useCopyToClipboard";

import { CopyClipboard } from "images";

export default function WebhookSettings({ open, onClose, showContinue, onAccept, disableBackdropClick }) {
	const { strategy } = useSelector((state) => state.strategyBuilder);
	const copyToClipboard = useCopyToClipboard();
	const { t } = useTranslation("workshop");

	const [selectedValue, setSelectedValue] = useState("strategy");

	const handleValueChange = (event) => {
		setSelectedValue(event.target.value);
	};

	const json = `{
	    "strategyId": ${strategy?.id},
	    "token": "${strategy?.webhookToken}",
	    "ticker": "{{ticker}}",
	    "exchange": "{{exchange}}",
	    "timestamp": "{{timenow}}",
	    "side": "{{strategy.order.action}}",
	    "price": {{strategy.order.price}},
	    "strategy": {
	        "order": {
	        	"id": "{{strategy.order.id}}",
	        	"comment": "{{strategy.order.comment}}",
	        	"alertMessage": "{{strategy.order.alert_message}}"
	        },
	        "marketPosition": "{{strategy.market_position}}",
	        "prevMarketPosition": "{{strategy.prev_market_position}}"
	    }
	}`;

	const json1 = `{
	    "strategyId": ${strategy?.id},
	    "token": "${strategy?.webhookToken}",
	    "ticker": "{{ticker}}",
	    "exchange": "{{exchange}}",
	    "timestamp": "{{time}}",
	    "side": "buy",
	    "price": {{close}},
	    "strategy": {
	        "order": {
	        	"id": "",
	        	"comment": "",
	        	"alertMessage": ""
	        },
	        "marketPosition": "long",
	        "prevMarketPosition": "flat"
	    }
	}`;

	const json2 = `{
	    "strategyId": ${strategy?.id},
	    "token": "${strategy?.webhookToken}",
	    "ticker": "{{ticker}}",
	    "exchange": "{{exchange}}",
	    "timestamp": "{{time}}",
	    "side": "sell",
	    "price": {{close}},
	    "strategy": {
	        "order": {
	        	"id": "",
	        	"comment": "",
	        	"alertMessage": ""
	        },
	        "marketPosition": "short",
	        "prevMarketPosition": "flat"
	    }
	}`;

	const webhookUrl = `${Config.apiRoot()}/signal/external`;

	return (
		<Dialog
			dialogProps={{ open, maxWidth: "lg", onClose, disableBackdropClick }}
			title={t("workshop_tv_strategy_page_settings_modal_main_title")}
			content={
				<Grid container spacing={2} sx={{ width: "70%" }}>
					<Grid item xs={12} sx={{ textAlign: "center" }}>
						<Typography>{t("workshop_tv_strategy_page_settings_modal_main_text")}</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label={
								<>
									{t("workshop_tv_strategy_page_settings_modal_address_title")}
									<IconButton
										onClick={() => {
											copyToClipboard(webhookUrl);
										}}
									>
										<CopyClipboard />
									</IconButton>
								</>
							}
							value={webhookUrl}
							fullWidth
							disabled
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										onClick={() => {
											copyToClipboard(webhookUrl);
										}}
									>
										<CopyClipboard />
									</IconButton>
								</InputAdornment>
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControl>
							<FormLabel
								sx={{
									color: "#0F20E8",
									fontSize: "1rem",
									fontWeight: 700,
									lineHeight: "20px",
									marginBlockStart: "1rem",
									display: "inline",
								}}
							>
								<Typography>
									{t("workshop_tv_strategy_page_settings_modal_connection_type_title")}
								</Typography>
							</FormLabel>
							<RadioGroup row value={selectedValue} onChange={handleValueChange}>
								<FormControlLabel
									value="strategy"
									control={<Radio />}
									label={t("workshop_tv_strategy_page_settings_modal_connection_type_strategy")}
								/>
								<FormControlLabel
									value="indicator"
									control={<Radio />}
									label={t("workshop_tv_strategy_page_settings_modal_connection_type_indicator")}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>

					{selectedValue === "strategy" ? (
						<Grid item xs={12}>
							<TextField
								label={
									<>
										{t("workshop_tv_strategy_page_settings_modal_message_title")}
										<IconButton
											onClick={() => {
												copyToClipboard(json);
											}}
										>
											<CopyClipboard />
										</IconButton>
									</>
								}
								disabled
								fullWidth
								multiline
								maxRows={Infinity}
								value={json}
							/>
						</Grid>
					) : (
						<Grid item xs={12}>
							<TextField
								label={
									<>
										{t("workshop_tv_strategy_page_settings_modal_short_exit_message")}
										<IconButton
											onClick={() => {
												copyToClipboard(json1);
											}}
										>
											<CopyClipboard />
										</IconButton>
									</>
								}
								disabled
								fullWidth
								multiline
								maxRows={Infinity}
								value={json1}
								sx={{ mb: 3 }}
							/>
							<TextField
								label={
									<>
										{t("workshop_tv_strategy_page_settings_modal_short_entry_message")}
										<IconButton
											onClick={() => {
												copyToClipboard(json2);
											}}
										>
											<CopyClipboard />
										</IconButton>
									</>
								}
								disabled
								fullWidth
								multiline
								maxRows={Infinity}
								value={json2}
							/>
						</Grid>
					)}
				</Grid>
			}
			action={
				<>
					{showContinue && (
						<Grid container sx={{ width: "70%", textAlign: "center" }}>
							<Grid item xs={12}>
								<Button
									variant="contained"
									sx={{ backgroundColor: (theme) => theme.palette.primary.main }}
									onClick={() => onAccept()}
								>
									{t("workshop_tv_strategy_page_settings_modal_button_title")}
								</Button>
							</Grid>
						</Grid>
					)}
				</>
			}
		/>
	);
}
