import { useTranslation } from "react-i18next";

import { Grid, Paper, Typography } from "@mui/material";

import { CheckLongBlue, StopOrange } from "images";

export default function MarketTypesValidation({ marketTypes, restrictions }) {
	const { t } = useTranslation("accountCenter");

	return (
		<Paper
			sx={{
				mt: 2,
				backgroundColor: "#F4F5FC",
				padding: 1,
				display: "flex",
				flexDirection: "column",
				alignItems: "left",
				width: "32.5rem",
			}}
		>
			<Grid container>
				<Grid item xs={12}>
					<Typography sx={{ fontSize: "14px", mb: 2, textAlign: "center" }}>
						{t("add_connection_success_modal_permissions_title")}
					</Typography>
				</Grid>

				<Grid container sx={{ justifyContent: "left" }}>
					<Grid item xs={12}>
						{marketTypes?.includes("SPOT") && (
							<Grid item xs={12}>
								<Typography
									sx={{
										fontSize: "14px",
										textAlign: "left",
										display: "flex",
										color: (theme) =>
											restrictions?.canSpotTrade
												? theme.palette.primary.main
												: theme.palette.warning.main,
									}}
								>
									<Grid
										item
										xs={1}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										{restrictions?.canSpotTrade ? <CheckLongBlue /> : <StopOrange />}
									</Grid>
									<Grid item xs={11}>
										{restrictions?.canSpotTrade
											? t("add_connection_success_modal_permissions_success_text", {
													tradeType: "SPOT",
											  })
											: t("add_connection_success_modal_permissions_warning_text", {
													tradeType: "SPOT",
											  })}
									</Grid>
								</Typography>
							</Grid>
						)}
						{marketTypes?.includes("FUTURES") && (
							<Grid item xs={12}>
								<Typography
									sx={{
										mt: 1,
										fontSize: "14px",
										textAlign: "left",
										display: "flex",
										color: (theme) =>
											restrictions?.canFuturesTrade
												? theme.palette.primary.main
												: theme.palette.warning.main,
									}}
								>
									<Grid
										item
										xs={1}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										{restrictions?.canFuturesTrade ? <CheckLongBlue /> : <StopOrange />}
									</Grid>
									<Grid item xs={11}>
										{restrictions?.canFuturesTrade
											? t("add_connection_success_modal_permissions_success_text", {
													tradeType: "FUTURES",
											  })
											: t("add_connection_success_modal_permissions_warning_text", {
													tradeType: "FUTURES",
											  })}
									</Grid>
								</Typography>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
}
