export const searchDropdown = (t) => [
	{
		key: t("marketplace_search_for_strategy"),
		value: "Strategy",
	},
	{
		key: t("marketplace_search_for_expert"),
		value: "Expert",
	},
];

export const activeTabType = (t) => [
	{
		name: t("marketplace_strategy_tab"),
		type: "STRATEGY",
	},
];

export const filterOptionsRight = (t) => [
	{
		name: t("marketplace_main_page_order_roi"),
		type: "profitPercent",
	},
	{
		name: t("marketplace_main_page_order_success_rate"),
		type: "successRate",
	},
	{
		name: t("marketplace_main_page_order_avg_profit"),
		type: "averageProfit",
	},
	{
		name: t("marketplace_main_page_order_pos_count"),
		type: "positionCount",
	},
	{
		name: "Score",
		type: "score",
	},
];

export const filterOptionsLeft = (t) => [
	{
		name: t("marketplace_main_page_filter_time_range"),
		type: "WEEK",
	},
	{
		name: t("marketplace_main_page_filter_exchange"),
		type: "EXCHANGE",
	},
];

export const timeFilter = (t) => [
	{
		name: t("marketplace_main_page_filter_time_range_1M"),
		value: "1M",
	},
	{
		name: t("marketplace_main_page_filter_time_range_3M"),
		value: "3M",
	},

	{
		name: t("marketplace_main_page_filter_time_range_6M"),
		value: "6M",
	},

	{
		name: t("marketplace_main_page_filter_time_range_12M"),
		value: "1Y",
	},
];

export const defaultFiltering = {
	FUNNEL_ID: 2,
	INITIAL_PAGE_NUMBER: 0,
	INITIAL_PAGE_SIZE: 12,
};

export const stratigiesObjectCount = {
	where: {
		timeRange: "1Y",
		pageSize: 250,
	},
};

export const TIME_RANGE_DATA_MAP = {
	undefined: "strategyStatistics",
	"1M": "strategyStatistics1m",
	"1Y": "strategyStatistics1y",
	"3M": "strategyStatistics3m",
	"6M": "strategyStatistics6m",
};
