import { useEffect, useState } from "react";

import Button from "@mui/material/Button";

export default function AnimatedTimedButton({ handleTestConnection, buttonText }) {
	const [isAnimating, setIsAnimating] = useState(false);
	const [timeLeft, setTimeLeft] = useState(5);

	useEffect(() => {
		let timer;
		if (isAnimating) {
			timer = setInterval(() => {
				setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
			}, 1000);
		}
		return () => clearInterval(timer);
	}, [isAnimating]);

	useEffect(() => {
		if (timeLeft === 0) {
			setIsAnimating(false);
		}
	}, [timeLeft]);

	const handleButtonClick = async () => {
		handleTestConnection();
		setIsAnimating(true);
		setTimeLeft(5);
	};

	return (
		<>
			<Button
				fullWidth
				variant="outlined"
				sx={{
					background: "#FFFFFF",
					color: (theme) => theme.palette.primary.main,
					minWidth: "520px",
					marginBottom: 2,
					"&:disabled": {
						backgroundColor: "#FFFFFF",
						border: "1px solid",
						borderColor: (theme) => theme.palette.primary.secondary,
					},
				}}
				disabled={isAnimating}
				onClick={handleButtonClick}
			>
				{buttonText}
				{isAnimating && timeLeft > 0 && (
					<svg
						viewBox="0 0 36 36"
						style={{
							position: "absolute",
							right: "6px",
							width: "34px",
							height: "34px",
						}}
					>
						<circle
							cx="18"
							cy="18"
							r="16"
							fill="none"
							strokeWidth="2"
							stroke="#0F20E8"
							strokeDasharray={`${(timeLeft / 5) * 100}, 100`}
							transform="rotate(-90 18 18)"
						/>
						<text
							x="18"
							y="18"
							textAnchor="middle"
							dominantBaseline="central"
							fill="#0F20E8"
							fontSize="12px"
						>
							{timeLeft}
						</text>
					</svg>
				)}
			</Button>
		</>
	);
}
