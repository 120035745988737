import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Circle as CircleIcon } from "@mui/icons-material";
import { Button, CircularProgress, Grid } from "@mui/material";

import { omit } from "lodash";

import { MarketStrategyContext } from "scenes/marketplace/strategy/Strategy";

import { Positions } from "components";
import { prepareRateMessage } from "components/Positions/utils";

import { useMarketplaceApi } from "api/marketplace";
import { usePositionApi } from "api/position";
import useCatchError from "api/useCatchError";

import ClientFeeder from "services/ClientFeeder";

const PAGE_COUNT = 5;

export default function ParityPositionHistory({ symbol }) {
	const { t } = useTranslation("marketplace");

	const { marketStrategy } = useContext(MarketStrategyContext);

	const [processing, setProcessing] = useState(true);
	const [openTab, setOpenTab] = useState("closed");
	const [filter, setFilter] = useState({
		type: "strategy",
		strategyId: marketStrategy.strategy.id,
		where: {
			status: "CLOSED",
			pageNumber: 0,
			pageSize: PAGE_COUNT,
			strategyPositions: true,
			symbols: `${symbol}`,
			strategyIds: `${marketStrategy.strategy.id}`,
		},
	});
	const [positions, setPositions] = useState();
	const [count, setCount] = useState();

	const { getMarketStrategyAnalytic } = useMarketplaceApi();
	const [analytics, setAnalytics] = useState();
	const { getPositions, getPositionsCount } = usePositionApi();
	const catchError = useCatchError();

	const refresh = () => {
		setProcessing(true);

		Promise.all([
			getPositions(filter).then((data) => {
				setPositions(data);
			}),
			getPositionsCount({ ...filter, where: omit(filter.where, ["pageSize", "pageNumber"]) }).then((data) => {
				setCount(data);
			}),
		])
			.then(() => {
				setProcessing(false);
			})
			.catch((e) => {
				catchError(e);
			});
	};

	const refreshAnalytics = () => {
		setProcessing(true);

		Promise.all([
			getMarketStrategyAnalytic(marketStrategy.id).then((data) => {
				setAnalytics(data);
			}),
		])
			.then(() => {
				setProcessing(false);
			})
			.catch((e) => {
				catchError(e);
			});
	};

	const handlePageChange = (_, newPage) => {
		setFilter({
			...filter,
			where: {
				...filter?.where,
				pageNumber: newPage,
			},
		});
	};
	const handleTabChange = (tab) => {
		setOpenTab(tab);
		if (tab != "parityBased") {
			setFilter({
				...filter,
				where: {
					...filter?.where,
					pageNumber: 0,
					status: tab.toUpperCase(),
				},
			});
		} else {
			refreshAnalytics();
		}
	};

	const handlePerPageChange = (event) => {
		setFilter({
			...filter,
			where: {
				...filter?.where,
				pageNumber: 0,
				pageSize: event.target.value,
			},
		});
	};

	useEffect(() => {
		if (filter) {
			refresh();
		}
	}, [filter]);

	return (
		<>
			{processing ? (
				<CircularProgress />
			) : (
				<Grid container>
					<Grid item xs={12} container direction={"row"}>
						{["closed"].map((tab) => (
							<Grid key={tab} item>
								<Button
									size="large"
									sx={{
										fontSize: "1.25rem",
										...(tab !== openTab ? { color: "#AEAEAE" } : {}),
									}}
									startIcon={tab === openTab ? <CircleIcon sx={{ width: 8, height: 8 }} /> : <></>}
									onClick={() => handleTabChange(tab)}
								>
									{t("marketplace_parity_based_position_history")}
								</Button>
							</Grid>
						))}
					</Grid>
					<Grid item xs={12} container direction={"row"}>
						<>
							<Positions
								positions={positions}
								hideLookup={{ side: true, exchange: true, expertStrategy: true }}
								filter={filter}
								count={parseInt(count)}
								onPageChange={handlePageChange}
								onRowsPerPageChange={handlePerPageChange}
							/>
							<ClientFeeder data={JSON.stringify(prepareRateMessage(positions))} />
						</>
					</Grid>
				</Grid>
			)}
		</>
	);
}
