import { Typography } from "@mui/material";

import { EXCHANGE_TEXT_MAP } from "constants";

export const getAvailableBudget = ({
	portfolio,
	platform,
	quote,
	budgetWord,
	showLineBreak = false,
	noBudget = "",
}) => {
	const { exchange, info, id: platformId } = platform;

	const platformPortfolio = portfolio?.find((x) => x.platformId === platformId);

	const bitmexCondition = exchange === "bitmex" && quote === "USD"; // BITMEX has weird behaviour for XBT

	const okxCondition = info.platformKey === "okx-futures-perpetual";

	const portfolioItem = platformPortfolio?.portfolio?.assets?.find(
		(x) => x.coinName === (bitmexCondition ? "XBT" : quote)
	);

	let okxBudget = 0;

	if (okxCondition) {
		okxBudget = portfolio
			?.filter((x) => x.platform?.exchange === "okx")
			.reduce((acc, curr) => {
				const amount = curr.stableAmounts?.totalUsdtAmount;

				acc = acc + amount;
				return acc;
			}, 0);
	}

	const availableBudget = bitmexCondition
		? portfolioItem?.usdtAmount
		: okxCondition
		? okxBudget
		: portfolioItem?.coinAmount;

	return availableBudget ? (
		<Typography fontSize={12}>
			{`${EXCHANGE_TEXT_MAP[exchange]} ${budgetWord}`}
			&nbsp;
			{showLineBreak && <br />}
			<Typography component="span" color="primary" fontSize={12}>
				{availableBudget}
			</Typography>
			&nbsp;
			{quote}
		</Typography>
	) : (
		<>{noBudget}</>
	);
};
