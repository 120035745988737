/* eslint-disable no-unused-vars */
import GaugeChart from "react-gauge-chart";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Chip, TableBody, TableCell, TableRow, Typography } from "@mui/material";

import { EXCHANGE_TEXT_MAP } from "constants";

import useQuery from "utils/useQuery";

import { Settings as SettingsSvg } from "images";

import { AverageProfit, Info, OpenClosePos, Side, Status } from "./columns";
import TotalProfit from "./columns/TotalProfit";

export default function StrategyTable({ strategies, setOpen, setSelectedStrategyFollower }) {
	const navigate = useNavigate();
	const query = useQuery();
	const { t } = useTranslation("controlPanel");

	const isArchived = query.get("archived");
	const isOwn = query.get("own");
	const isMarket = !isArchived && !isOwn;

	return strategies?.length ? (
		<TableBody
			sx={{
				backgroundColor: "#F0F0F5",
				borderRadius: "8px",
			}}
		>
			{strategies.map(
				(
					{
						blacklist,
						budgetSettings,
						positionSettings,
						strategy,
						strategyFollowerStatistics,
						virtual,
						status,
					},
					ix
				) => {
					return (
						<TableRow key={strategy.id} sx={{ backgroundColor: "#FFFFFF" }}>
							<TableCell>
								<Status status={status} strategyFollowerStatistics={strategyFollowerStatistics} />
							</TableCell>
							<TableCell width="15%">
								<Info
									{...(strategy?.marketStrategy?.id
										? {
												href: `/marketplace/strategy/${strategy?.marketStrategy?.id}`,
										  }
										: {})}
									virtual={virtual}
									name={strategy?.marketStrategy?.name ?? strategy?.name}
									quote={strategy?.parities?.quotes?.[0]}
									strategyType={strategy?.strategyTypeId}
									isPublic={strategy?.public}
								/>
							</TableCell>

							{isMarket && (
								<TableCell>
									{strategy?.marketStrategy?.merchant?.id ? (
										<Typography
											fontWeight={500}
											sx={{
												cursor: "pointer",
											}}
											onClick={() =>
												navigate(`/marketplace/merchant/${strategy.marketStrategy.merchant.id}`)
											}
										>
											{strategy?.marketStrategy?.merchant?.nickname}
										</Typography>
									) : (
										<Typography>{t("control_panel_robot_table_own")}</Typography>
									)}
								</TableCell>
							)}

							<TableCell>
								<Side label={strategy?.executionType} />
							</TableCell>

							<TableCell>
								<Chip
									label={EXCHANGE_TEXT_MAP[strategy?.platform?.exchange]}
									size="small"
									sx={{
										paddingLeft: 0.5,
										borderRadius: 2,
										backgroundColor: (theme) => theme.palette.info.light,
										color: (theme) => theme.palette.primary.main,
									}}
								/>
							</TableCell>

							<TableCell>
								<AverageProfit profit={strategyFollowerStatistics?.averageProfit} />
							</TableCell>
							<TableCell>
								<OpenClosePos successInfo={strategyFollowerStatistics} />
							</TableCell>
							<TableCell>
								{strategyFollowerStatistics == null ||
								strategyFollowerStatistics?.closedPositionsCount === 0 ? (
									<Typography sx={{ textAlign: "center" }}>
										{t("control_panel_robots_table_no_closed_positions")}
									</Typography>
								) : (
									<GaugeChart
										id="gauge-chart3"
										style={{
											width: "100px",
											marginLeft: "0px",
											fontWeight: "500",
											lineHeight: "18px",
										}}
										colors={["#0F20E8", "#F4F5FC"]}
										nrOfLevels={2}
										arcsLength={[
											strategyFollowerStatistics?.successRatio / 100,
											(100 - strategyFollowerStatistics?.successRatio) / 100,
										]}
										arcWidth={0.1}
										arcPadding={0}
										percent={strategyFollowerStatistics?.successRatio / 100}
										textColor="#0F20E8"
										fontSize={14}
										needleColor={"#FFF"}
										needleBaseColor={"#FFF"}
										marginInPercent={0.01}
									/>
								)}
							</TableCell>
							<TableCell>
								<TotalProfit profit={strategyFollowerStatistics?.totalProfit} />
							</TableCell>

							{!isArchived && (
								<TableCell>
									<SettingsSvg
										style={{ cursor: "pointer" }}
										onClick={() => {
											setOpen(true);
											setSelectedStrategyFollower({
												strategy,
												blacklist,
												status,
												virtual,
												budgetSettings,
												positionSettings,
											});
										}}
									></SettingsSvg>
								</TableCell>
							)}
						</TableRow>
					);
				}
			)}
		</TableBody>
	) : (
		<></>
	);
}
