import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, DialogContent, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";

import { useSnackbar } from "notistack";

import { useFetchAuthorized } from "services";
import Config from "services/config";

import { StrategyContext } from "./MyStrategiesDialog";
import { SUCCESS } from "./utils/constant";

export default function EditConnectionSettings({ contentProps }) {
	const { t } = useTranslation("workshop");
	const { selectedConnectionSetting, strategy, setSuccessMessage, setSelection } = useContext(StrategyContext);
	const fetchAuthorized = useFetchAuthorized();
	const { enqueueSnackbar } = useSnackbar();
	const [id, setId] = useState("");
	const [language, setLanguage] = useState("tr");
	const [token, setToken] = useState("");

	useEffect(() => {
		setId(selectedConnectionSetting.id);
		setLanguage(selectedConnectionSetting.language);
		setToken(selectedConnectionSetting.token);
	}, [selectedConnectionSetting]);

	const handleToggleConnection = () => {
		fetchAuthorized(`${Config.apiRoot()}/strategy/strategy/${strategy?.id}/commercialSettings`, {
			headers: {
				"Content-type": "application/json",
			},
			method: "PUT",
			body: JSON.stringify({
				commercialSettings: {
					...selectedConnectionSetting,
					enabled: !selectedConnectionSetting.enabled,
				},
			}),
		})
			.then((response) => {
				if (response.status !== 200 || response.error !== undefined) {
					throw new Error(
						JSON.stringify({
							status: response.status,
							code: response.error?.code,
							detail: response.error?.detail,
						})
					);
				}
				setSuccessMessage({
					title: t("workshop_commercial_settings_updated_success"),
				});
				setSelection(SUCCESS);
			})
			.catch((err) => {
				const error = JSON.parse(err.message);
				enqueueSnackbar(
					`${t("workshop_commercial_settings_error")} ${
						error.status ? error.status : error.detail ? error.detail : ""
					} ${error.detail ? error.detail : ""}`,
					{
						variant: "error",
					}
				);
			});
	};

	const handleUpdateConnection = () => {
		fetchAuthorized(`${Config.apiRoot()}/strategy/strategy/${strategy?.id}/commercialSettings`, {
			headers: {
				"Content-type": "application/json",
			},
			method: "PUT",
			body: JSON.stringify({
				commercialSettings: {
					...selectedConnectionSetting,
					id,
					language,
					token,
				},
			}),
		})
			.then((response) => {
				if (response.status !== 200 || response.error !== undefined) {
					throw new Error(
						JSON.stringify({
							status: response.status,
							code: response.error?.code,
							detail: response.error?.detail,
						})
					);
				}
				setSuccessMessage({
					title: t("workshop_commercial_settings_updated_success"),
				});
				setSelection(SUCCESS);
			})
			.catch((err) => {
				const error = JSON.parse(err.message);
				enqueueSnackbar(
					`${t("workshop_commercial_settings_error")} ${
						error.status ? error.status : error.detail ? error.detail : ""
					} ${error.detail ? error.detail : ""}`,
					{
						variant: "error",
					}
				);
			});
	};

	const handleDeleteConnection = () => {
		fetchAuthorized(`${Config.apiRoot()}/strategy/strategy/${strategy?.id}/deleteCommercialSettings`, {
			headers: {
				"Content-type": "application/json",
			},
			method: "PUT",
			body: JSON.stringify({
				commercialSettings: {
					...selectedConnectionSetting,
					id,
					language,
					token,
				},
			}),
		})
			.then((response) => {
				if (response.status !== 200 || response.error !== undefined) {
					throw new Error(
						JSON.stringify({
							status: response.status,
							code: response.error?.code,
							detail: response.error?.detail,
						})
					);
				}
				setSuccessMessage({
					title: t("workshop_commercial_settings_deleted_success"),
				});
				setSelection(SUCCESS);
			})
			.catch((err) => {
				const error = JSON.parse(err.message);
				enqueueSnackbar(
					`${t("workshop_commercial_settings_error")} ${
						error.status ? error.status : error.detail ? error.detail : ""
					} ${error.detail ? error.detail : ""}`,
					{
						variant: "error",
					}
				);
			});
	};

	return (
		<>
			<DialogContent sx={{ paddingTop: "10vh !important" }} {...contentProps}>
				<Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
					<Grid item xs={12}>
						<Typography variant="h5">
							{t(`workshop_commercial_settings_header_single_${selectedConnectionSetting.platform}`)}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="p">
							{t(`workshop_commercial_settings_explanation_single_${selectedConnectionSetting.platform}`)}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="p">{selectedConnectionSetting.text}</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{ paddingTop: "3vh" }}>
					<Grid item xs={12}>
						<Typography sx={{ color: (theme) => theme.palette.primary.main }}>
							{t("workshop_commercial_settings_channel_name")}
						</Typography>
						<TextField
							fullWidth
							sx={{ marginBottom: 4 }}
							value={selectedConnectionSetting.channelName}
							disabled={true}
						/>
						<Typography sx={{ color: (theme) => theme.palette.primary.main }}>
							{t("workshop_commercial_settings_channel_language")}
						</Typography>
						<Select
							fullWidth
							sx={{ marginBottom: 4 }}
							labelId="language-select-menu"
							id="language-select-menu"
							value={language === "en" ? "English" : "Turkish"}
							onChange={(e) => setLanguage(e.target.value === "English" ? "en" : "tr")}
						>
							<MenuItem value={"English"}>
								{t(`workshop_commercial_settings_channel_language_english`)}
							</MenuItem>
							<MenuItem value={"Turkish"}>
								{t(`workshop_commercial_settings_channel_language_turkish`)}
							</MenuItem>
						</Select>
						<Typography sx={{ color: (theme) => theme.palette.primary.main }}>
							{t(`workshop_commercial_settings_id_${selectedConnectionSetting.platform}`)}
						</Typography>
						<TextField
							fullWidth
							sx={{ marginBottom: 4 }}
							value={id}
							onChange={(e) => {
								setId(e.target.value);
							}}
						/>
						<Typography sx={{ color: (theme) => theme.palette.primary.main }}>
							{t(`workshop_commercial_settings_token_${selectedConnectionSetting.platform}`)}
						</Typography>
						<TextField
							fullWidth
							sx={{ marginBottom: 2 }}
							value={token}
							onChange={(e) => setToken(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
						<Button
							fullWidth
							sx={{ minWidth: "520px" }}
							variant="contained"
							onClick={handleUpdateConnection}
						>
							{t(`workshop_commercial_settings_channel_edit_update_button`)}
						</Button>
					</Grid>
					<Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
						<Button
							fullWidth
							sx={{ minWidth: "520px" }}
							variant="contained"
							onClick={handleToggleConnection}
						>
							{selectedConnectionSetting.enabled
								? t(`workshop_commercial_settings_channel_edit_disable`)
								: t(`workshop_commercial_settings_channel_edit_enable`)}
						</Button>
					</Grid>
					<Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
						<Button
							fullWidth
							sx={{ minWidth: "520px" }}
							variant="contained"
							onClick={handleDeleteConnection}
						>
							{t(`workshop_commercial_settings_channel_edit_delete_button`)}
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</>
	);
}
