import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, DialogContent, Grid, Typography } from "@mui/material";

import { run, stop } from "images";

import { StrategyContext } from "./MyStrategiesDialog";
import SelectionCard from "./SelectionCard";
import { EDIT_CONNECTION_SETTINGS } from "./utils/constant";

export default function ShowConnections({ contentProps }) {
	const { t } = useTranslation("workshop");
	const { strategy } = useContext(StrategyContext);
	const { setSelection, setSelectedConnectionSetting, selectedNetwork } = useContext(StrategyContext);
	const [selectedSetting, setSelectedSetting] = useState("");
	const commercialSettings = Object.values(strategy?.commercialSettings);
	const handleSelectedSetting = (value) => {
		setSelectedSetting(value);
	};

	useEffect(() => {
		if (selectedSetting) {
			setSelectedConnectionSetting(
				commercialSettings.find((x) => x.platform === selectedNetwork && x.channelName === selectedSetting)
			);
		}
	}, [selectedSetting]);

	return (
		<DialogContent sx={{ paddingTop: "10vh !important" }} {...contentProps}>
			<Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
				<Grid item xs={12}>
					<Typography variant="h5">
						{t(`workshop_commercial_settings_edit_${selectedNetwork}_header`)}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="p">
						{t(`workshop_commercial_settings_edit_${selectedNetwork}_subtext`)}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					{commercialSettings
						.filter((x) => x.platform === selectedNetwork)
						.map((connection, i) => (
							<SelectionCard
								key={i}
								icon={connection.enabled ? run : stop}
								title={connection.channelName}
								text={connection.channelName}
								value={connection.channelName}
								status={selectedSetting}
								handleSetSelectedNetwork={handleSelectedSetting}
							></SelectionCard>
						))}
				</Grid>
			</Grid>
			<Grid container spacing={2} sx={{ paddingTop: "3vh" }}>
				<Grid item xs={12}>
					{commercialSettings.filter((x) => x.platform === selectedNetwork).length > 0 && (
						<Button
							fullWidth
							variant="contained"
							onClick={() => {
								setSelection(EDIT_CONNECTION_SETTINGS);
							}}
						>
							{t("workshop_commercial_settings_edit_single_button")}
						</Button>
					)}
				</Grid>
			</Grid>
		</DialogContent>
	);
}
