import { useTheme } from "@mui/material/styles";

export default function MenuPositionsCenter({ active }) {
	const theme = useTheme();

	const color = active ? theme.palette.primary.main : theme.palette.primary.light;

	return (
		<svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M22.1315 0.644931C22.5428 0.658283 22.8654 1.00253 22.852 1.41382C22.5704 10.0887 15.3112 16.8859 6.63696 16.6043C6.22566 16.591 5.90306 16.2467 5.91642 15.8354C5.92977 15.4241 6.27401 15.1015 6.68531 15.1149C14.5376 15.3698 21.1077 9.21711 21.3626 1.36547C21.3759 0.954178 21.7202 0.63158 22.1315 0.644931Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M21.6403 0.414637C21.9609 0.156649 22.43 0.207406 22.6879 0.528007L25.2362 3.69472C25.4942 4.01532 25.4434 4.48436 25.1228 4.74235C24.8022 5.00034 24.3332 4.94958 24.0752 4.62898L21.5269 1.46226C21.269 1.14166 21.3197 0.672625 21.6403 0.414637Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M22.6865 0.526115C22.9455 0.845873 22.8963 1.31507 22.5765 1.57411L19.4768 4.08512C19.1571 4.34415 18.6879 4.29493 18.4289 3.97517C18.1698 3.65541 18.2191 3.18621 18.5388 2.92718L21.6385 0.416161C21.9582 0.157129 22.4275 0.206357 22.6865 0.526115Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M3.39786 23.0858C3.67946 14.4109 10.9387 7.61358 19.6129 7.89516C20.0242 7.90851 20.3468 8.25276 20.3334 8.66405C20.3201 9.07535 19.9759 9.39795 19.5646 9.3846C11.7123 9.1297 5.14217 15.2825 4.8873 23.1341C4.87394 23.5454 4.5297 23.868 4.1184 23.8547C3.70711 23.8413 3.38451 23.4971 3.39786 23.0858Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M1.12762 19.7574C1.44822 19.4994 1.91726 19.5502 2.17524 19.8708L4.72351 23.0375C4.98149 23.3581 4.93074 23.8271 4.61013 24.0851C4.28953 24.3431 3.8205 24.2924 3.56251 23.9718L1.01425 20.805C0.756258 20.4844 0.807016 20.0154 1.12762 19.7574Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M7.82125 20.5251C8.08028 20.8449 8.03105 21.3141 7.71129 21.5731L4.61161 24.0841C4.29186 24.3432 3.82265 24.294 3.56362 23.9742C3.30459 23.6544 3.35382 23.1852 3.67358 22.9262L6.77326 20.4152C7.09301 20.1562 7.56222 20.2054 7.82125 20.5251Z"
				fill={color}
			/>
		</svg>
	);
}
