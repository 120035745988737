import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";

import dayjs from "dayjs";

const btnStyle = {
	padding: "0.8rem 6rem",
	marginBlock: "1rem",
};

export default function ClaimButton({
	campaign,
	campaignAttendance,
	eligibityStatus,
	countdown,
	disabled,
	onCheckEligibity,
	onCheckUserCampaignStatus,
	onClaimReward,
	onCreateCampaignAttendance,
}) {
	const { t } = useTranslation("questBox");

	if (!campaign) {
		return;
	}

	const { startDate, endDate } = campaign ?? {};

	const hasPassed = dayjs(endDate).isBefore(dayjs());
	const hasStarted = dayjs(startDate).isBefore(dayjs());

	if (hasPassed) {
		return (
			<Button sx={btnStyle} variant="contained" disabled={campaignAttendance?.status !== "COMPLETED" ? "disabled" : ""}>
				{(!campaignAttendance || campaignAttendance?.status === "SIGNED") &&
					t("quest_box_campaign_button_text_campaign_ended")}
				{campaignAttendance?.status === "COMPLETED" && t("quest_box_campaign_button_text_claim_cta")}
				{campaignAttendance?.status === "CLAIMED" && t("quest_box_campaign_button_text_claimed_cta")}
			</Button>
		);
	}

	if (!campaignAttendance) {
		if (eligibityStatus?.register.status) {
			return (
				<Button sx={btnStyle} variant="contained" onClick={() => onCreateCampaignAttendance()}>
					{t("quest_box_campaign_button_text_sign_up_cta")}
				</Button>
			);
		}

		return (
			<Button sx={btnStyle} variant="contained" disabled={disabled} onClick={() => onCheckEligibity()}>
				{disabled
					? t("quest_box_campaign_button_text_wait", { countdown: countdown })
					: t("quest_box_campaign_button_text_check_eligibility")}
			</Button>
		);
	}

	if (campaignAttendance?.status === "CLAIMED") {
		return (
			<Button sx={btnStyle} variant="contained" disabled>
				{t("quest_box_campaign_button_text_claimed_cta")}
			</Button>
		);
	}

	if (campaignAttendance?.status === "SIGNED") {
		if (!hasStarted) {
			return (
				<Button sx={btnStyle} variant="contained" disabled>
					{t("quest_box_campaign_button_text_coming_soon")}
				</Button>
			);
		}

		return (
			<Button sx={btnStyle} variant="contained" onClick={() => onCheckUserCampaignStatus()}>
				{t("quest_box_campaign_button_text_check_status")}
			</Button>
		);
	}

	if (campaignAttendance?.status === "COMPLETED") {
		return (
			<Button
				sx={{
					padding: "0.8rem 6rem",
					marginBlock: "1rem",
					backgroundColor: campaignAttendance?.checklist?.claim?.stepsStatus ? "#12c15a" : "#CFD2FA",
				}}
				variant="contained"
				onClick={() => onClaimReward()}
			>
				{t("quest_box_campaign_button_text_claim_cta")}
			</Button>
		);
	}

	return <></>;
}
