import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Box, Grid, Typography, Button, Tab, Tabs, Paper, useTheme, CircularProgress } from "@mui/material";

import { Dialog, IconText } from "components";
import RewardDetail from "components/RewardDetail";

import { useQuestBoxApi } from "api/questBox";
import useCatchError from "api/useCatchError";

import { ArrowLeft, TierReward as TierRewardIcon, Crown as CrownIcon } from "images";

import CampaignItem from "./CampaignItem";
import NoActiveCampaign from "./NoActiveCampaign";
import NoCampaign from "./NoCampaign";

export default function MyRewards() {
	const { getCampaigns, getUserRewards } = useQuestBoxApi();
	const { t, i18n } = useTranslation("questBox");
	const theme = useTheme();
	const navigate = useNavigate();

	const [campaigns, setCampaigns] = useState([]);
	const [rewards, setRewards] = useState([]);

	const [open, setOpen] = useState(false);
	const [detail, setDetail] = useState({
		open: false,
		data: null,
	});
	const [dialogTab, setDialogTab] = useState(0);
	const [processing, setProcessing] = useState(false);

	const catchError = useCatchError();

	const handleClick = () => {
		setOpen(true);
		setProcessing(true);
		Promise.all([
			getCampaigns().then((data) => {
				setCampaigns(data?.data?.campaigns.filter((campaign) => campaign.endDate < new Date()));
			}),
			getUserRewards().then((data) => setRewards(data?.data?.campaignUserRewards)),
		])
			.catch(catchError)
			.finally(() => {
				setProcessing(false);
			});
	};

	const rewardStatusChip = {
		ONGOING: {
			label: t("quest_box_your_rewards_status_ongoing"),
			bgColor: theme.palette.info.light,
			color: "#3A3A3A",
		},
		DISTRIBUTED: {
			label: t("quest_box_your_rewards_status_distributed"),
			bgColor: theme.palette.success.main,
			color: "#FFFFFF",
		},
		CLAIMED: {
			label: t("quest_box_your_rewards_status_claimed"),
			bgColor: theme.palette.secondary.main,
			color: "#FFFFFF",
		},
	};

	return (
		<>
			<Button onClick={handleClick} sx={{ borderRadius: "0", border: "1px dashed #CFD2FA" }}>
				<IconText
					text={t("common:Rewards")}
					textProps={{ sx: { fontSize: "14px", fontWeight: "normal" } }}
					icon={<CrownIcon />}
				/>
				<Box sx={{ border: "1px solid #36B37E", ml: 1, padding: "0 8px", borderRadius: "4px" }}>
					<Typography fontSize="12px" color="#36B37E">
						{t("common:New")}
					</Typography>
				</Box>
			</Button>

			<Dialog
				dialogProps={{
					fullWidth: true,
					open: open,
					maxWidth: "md",
					onClose: () => {
						setOpen(false);
					},
				}}
				title={<></>}
				content={
					<>
						{processing ? (
							<CircularProgress />
						) : (
							<>
								{detail?.open ? (
									<>
										<Box
											sx={{
												position: "absolute",
												top: "0",
												left: "0",
												padding: "24px",
											}}
										>
											<Button
												onClick={() => {
													setDetail({
														open: false,
														data: null,
													});
												}}
											>
												<ArrowLeft />
												<Typography pl={1} color={(theme) => theme.palette.text.dark}>
													{t("quest_box_rewards_and_campaigns_dialog_title")}
												</Typography>
											</Button>
										</Box>
										<IconText icon={<TierRewardIcon />} text={t("quest_box_your_rewards_text")} />
										<RewardDetail rewardDetails={detail?.data} />
									</>
								) : (
									<>
										<IconText
											icon={<TierRewardIcon />}
											text={t("quest_box_rewards_and_campaigns_dialog_title")}
										/>
										<Box sx={{ width: "100%", padding: "24px" }}>
											<Typography
												sx={{ fontSize: "14px", textAlign: "center", paddingBottom: "16px" }}
											>
												{t("quest_box_leaderboard_tier_reward_dialog_info")}
											</Typography>
											<Tabs
												variant="fullWidth"
												centered
												sx={{
													paddingBottom: "24px",
												}}
												value={dialogTab}
												onChange={(_, newValue) => {
													setDialogTab(newValue);
												}}
											>
												<Tab
													fullWidth
													sx={{ textTransform: "none" }}
													label={
														<Typography>
															{t("quest_box_rewards_and_campaigns_dialog_tab_1_text")}
														</Typography>
													}
												></Tab>
												<Tab
													fullWidth
													sx={{ textTransform: "none" }}
													label={
														<Typography>
															{t("quest_box_rewards_and_campaigns_dialog_tab_2_text")}
														</Typography>
													}
												></Tab>
											</Tabs>
											{dialogTab === 0 && (
												<Box>
													{campaigns?.length > 0 ? (
														campaigns.map((campaign) => {
															return (
																<Paper
																	variant="outlined"
																	sx={{
																		mt: 2,
																		backgroundColor: "#FFFFFF",
																		borderColor: "#CFD2FA",
																		overflow: "hidden",
																	}}
																>
																	<Grid
																		container
																		padding={"8px"}
																		justifyContent={"space-between"}
																		alignItems={"center"}
																		xs={12}
																	>
																		<Grid xs={1}>
																			<Box
																				component={"img"}
																				sx={{
																					display: "block",
																					width: "100%",
																					height: "auto",
																				}}
																				src={campaign?.bannerUrl}
																			/>
																		</Grid>
																		<Grid xs={9}>
																			<Box sx={{ padding: "24px" }}>
																				<CampaignItem
																					title={
																						campaign?.title?.[
																							i18n.resolvedLanguage
																						]
																					}
																					chips={[
																						{
																							label: campaign?.scope,
																							bgColor:
																								theme.palette.info
																									.light,
																							color: theme.palette.primary
																								.main,
																						},
																						{
																							label: campaign?.prize?.[
																								i18n.resolvedLanguage
																							],
																							bgColor:
																								theme.palette.primary
																									.main,
																							color: "#FFFFFF",
																						},
																					]}
																				/>
																			</Box>
																		</Grid>
																		<Grid
																			xs={2}
																			sx={{
																				display: "flex",
																				flexDirection: "column",
																				textAlign: "right",
																			}}
																		>
																			<Button
																				disableRipple
																				onClick={() => {
																					setOpen(false);
																					navigate(
																						`/quest-box/${campaign?.id}/details`
																					);
																				}}
																				variant="text"
																			>
																				<Typography
																					component={"span"}
																					sx={{
																						fontSize: "16px",
																						lineHeight: "normal",
																						borderBottom:
																							"1px solid #0F20E8",
																					}}
																				>
																					{t("common:View")}
																				</Typography>
																			</Button>
																		</Grid>
																	</Grid>
																</Paper>
															);
														})
													) : (
														<NoActiveCampaign handleDialog={setOpen} />
													)}
												</Box>
											)}
											{dialogTab === 1 && (
												<Box>
													<Box>
														{rewards.map((reward) => (
															<Paper
																variant="outlined"
																sx={{
																	mt: 2,
																	backgroundColor: "#FFFFFF",
																	borderColor: "#CFD2FA",
																	overflow: "hidden",
																}}
															>
																<Grid
																	container
																	padding={"8px"}
																	justifyContent={"space-between"}
																	alignItems={"center"}
																	xs={12}
																>
																	<Grid xs={1}>
																		<Box
																			component={"img"}
																			sx={{
																				display: "block",
																				width: "100%",
																				height: "auto",
																			}}
																			src={reward?.campaign?.bannerUrl}
																		/>
																	</Grid>
																	<Grid xs={9}>
																		<Box sx={{ padding: "24px" }}>
																			<CampaignItem
																				title={
																					reward?.campaign?.title?.[
																						i18n.resolvedLanguage
																					]
																				}
																				chips={[
																					{
																						label: reward?.campaign?.scope,
																						bgColor:
																							theme.palette.info.light,
																						color: theme.palette.primary
																							.main,
																					},
																					{
																						label: reward?.campaign
																							?.prize?.[
																							i18n.resolvedLanguage
																						],
																						bgColor:
																							theme.palette.primary.main,
																						color: "#FFFFFF",
																					},
																					rewardStatusChip[reward?.status],
																				]}
																			/>
																		</Box>
																	</Grid>
																	<Grid
																		xs={2}
																		sx={{
																			display: "flex",
																			flexDirection: "column",
																			textAlign: "right",
																		}}
																	>
																		<Box>
																			<Typography
																				sx={{
																					padding: "0 8px",
																					fontSize: "14px",
																				}}
																			>
																				{t("quest_box_your_rewards_text")}
																			</Typography>
																			<Typography
																				sx={{
																					color: theme.status.success,
																					fontSize: "24px",
																					padding: "0 8px",
																				}}
																			>
																				{reward?.totalReward}(
																				{reward?.campaign?.prize?.type})
																			</Typography>
																			{reward?.details?.length > 0 && (
																				<Button
																					disableRipple
																					onClick={() => {
																						setDetail({
																							open: true,
																							data: reward?.details,
																						});
																					}}
																					variant="text"
																					sx={{
																						justifyContent: "flex-end",
																					}}
																				>
																					<Typography
																						component={"span"}
																						sx={{
																							fontSize: "14px",
																							lineHeight: "normal",
																							borderBottom:
																								"1px solid #0F20E8",
																						}}
																					>
																						{t("common:View Details")}
																					</Typography>
																				</Button>
																			)}
																		</Box>
																	</Grid>
																</Grid>
															</Paper>
														))}
														{rewards.length === 0 && <NoCampaign handleDialog={setOpen} />}
													</Box>
												</Box>
											)}
										</Box>
									</>
								)}
							</>
						)}
					</>
				}
				action={
					<Grid container justifyContent={"center"}>
						<Grid xs="3">
							<Button
								onClick={() => {
									setOpen(false);
								}}
								disabled={processing}
								fullWidth
								variant="outlined"
							>
								{t("common:Close")}
							</Button>
						</Grid>
					</Grid>
				}
			></Dialog>
		</>
	);
}
