import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CircularProgress, Grid } from "@mui/material";

import { omit } from "lodash";

import { Positions } from "components";
import { prepareRateMessage } from "components/Positions/utils";

import { usePositionApi } from "api/position";
import useCatchError from "api/useCatchError";

import ClientFeeder from "services/ClientFeeder";

import { MarketStrategyContext } from "./Strategy";

const PAGE_COUNT = 5;

export default function PositionHistory({ type }) {
	const { t } = useTranslation("marketplace");

	const { marketStrategy } = useContext(MarketStrategyContext);

	const [processing, setProcessing] = useState(true);

	const [filter, setFilter] = useState({
		type: "strategy",
		strategyId: marketStrategy.strategy.id,
		where: {
			status: "OPEN",
			pageNumber: 0,
			pageSize: PAGE_COUNT,
			strategyPositions: true,
			strategyIds: `${marketStrategy.strategy.id}`,
		},
	});
	const [positions, setPositions] = useState();
	const [count, setCount] = useState();

	const { getPositions, getPositionsCount } = usePositionApi();
	const catchError = useCatchError();

	const refresh = () => {
		setProcessing(true);

		Promise.all([
			getPositions(filter).then((data) => {
				setPositions(data);
			}),
			getPositionsCount({ ...filter, where: omit(filter.where, ["pageSize", "pageNumber"]) }).then((data) => {
				setCount(data);
			}),
		])
			.then(() => {
				setProcessing(false);
			})
			.catch((e) => {
				catchError(e);
			});
	};
	const handlePageChange = (_, newPage) => {
		setFilter({
			...filter,
			where: {
				...filter?.where,
				pageNumber: newPage,
			},
		});
	};
	useEffect(() => {
		setFilter({
			...filter,
			where: {
				...filter?.where,
				pageNumber: 0,
				status: type.toUpperCase(),
			},
		});
	}, [type]);

	const handlePerPageChange = (event) => {
		setFilter({
			...filter,
			where: {
				...filter?.where,
				pageNumber: 0,
				pageSize: event.target.value,
			},
		});
	};

	useEffect(() => {
		if (filter) {
			refresh();
		}
	}, [filter]);

	return (
		<>
			{processing ? (
				<CircularProgress />
			) : (
				<Grid item xs={12} container direction={"row"}>
					<>
						<Positions
							positions={positions}
							hideLookup={{ side: true, exchange: true, expertStrategy: true }}
							filter={filter}
							count={parseInt(count)}
							onPageChange={handlePageChange}
							onRowsPerPageChange={handlePerPageChange}
						/>
						<ClientFeeder data={JSON.stringify(prepareRateMessage(positions))} />
					</>
				</Grid>
			)}
		</>
	);
}
