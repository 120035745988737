import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Circle as CircleIcon } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";

import useQuery from "utils/useQuery";

const routes = {
	all: "/strategies",
	own: "/strategies?own=true",
	archived: "/strategies?archived=true",
};

const getTabs = (archivedShown, showOwnStrategies) => {
	if (archivedShown) {
		if (showOwnStrategies) {
			return ["all", "own", "archived"];
		} else {
			return ["all", "archived"];
		}
	} else {
		if (showOwnStrategies) {
			return ["all", "own"];
		} else {
			return ["all"];
		}
	}
};

const getTab = (query) => {
	if (query.get("archived")) {
		return "archived";
	} else if (query.get("own")) {
		return "own";
	} else {
		return "all";
	}
};

export default function Filter({ archivedShown, showOwnStrategies }) {
	const query = useQuery();

	const { t } = useTranslation("strategies");
	const navigate = useNavigate();

	const tabs = getTabs(archivedShown, showOwnStrategies);
	const currentTab = getTab(query);

	return (
		<Grid container>
			{tabs.map((tab) => (
				<Grid item>
					<Button
						size="large"
						sx={{
							fontSize: "1.25rem",
							...(currentTab !== tab ? { color: "#AEAEAE" } : {}),
						}}
						startIcon={currentTab === tab ? <CircleIcon sx={{ width: 8, height: 8 }} /> : <></>}
						onClick={() => navigate(routes[tab])}
					>
						{t(`filter.${tab}`)}
					</Button>
				</Grid>
			))}
		</Grid>
	);
}
