export const customFormatDuration = (duration, t) => {
	if (!duration) {
		return 0;
	}

	const { day, days, hour, hours, minute, minutes } = {
		day: t("common:day"),
		days: t("common:days"),
		hour: t("common:hour"),
		hours: t("common:hours"),
		minute: t("common:minute"),
		minutes: t("common:minutes"),
	};

	const [firstPart, secondPart] = duration.split(", ");
	const firstValue = parseInt(firstPart, 10);
	const secondValue = parseInt(secondPart, 10);

	if (duration.includes("day")) {
		const dayLabel = firstValue === 1 ? day : days;
		const hourLabel = secondValue === 1 ? hour : hours;
		return `${firstValue} ${dayLabel}, ${secondValue} ${hourLabel}`;
	} else {
		const hourLabel = firstValue === 1 ? hour : hours;
		const minuteLabel = secondValue === 1 ? minute : minutes;
		return `${firstValue} ${hourLabel}, ${secondValue} ${minuteLabel}`;
	}
};
