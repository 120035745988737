import React from "react";

export default function SpotDown({ width, height, color }) {
	return (
		<svg
			width={width == "12"}
			height={height ?? "10"}
			viewBox="0 0 12 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M9.54732 9.41091C9.77457 9.40353 9.95282 9.21333 9.94544 8.98608C9.78985 4.19298 5.77897 0.437321 0.98623 0.592901C0.758978 0.600279 0.580735 0.790482 0.588112 1.01773C0.595489 1.24499 0.785693 1.42323 1.01294 1.41585C5.35154 1.27501 8.98166 4.67455 9.12249 9.01279C9.12986 9.24005 9.32007 9.41829 9.54732 9.41091Z"
				fill={color ?? "#DE350B"}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M9.27544 9.53794C9.45204 9.68115 9.7113 9.65409 9.85452 9.47749L11.3201 7.67018C11.4634 7.49358 11.4363 7.23431 11.2597 7.0911C11.0831 6.94789 10.8238 6.97496 10.6806 7.15156L9.21499 8.95887C9.07178 9.13547 9.09884 9.39473 9.27544 9.53794Z"
				fill={color ?? "#DE350B"}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M9.85477 9.47686C9.99763 9.29998 9.97006 9.04077 9.79318 8.8979L8.08055 7.51462C7.90366 7.37176 7.64446 7.39933 7.50159 7.57621C7.35872 7.7531 7.3863 8.0123 7.56318 8.15517L9.27581 9.53845C9.45269 9.68131 9.7119 9.65374 9.85477 9.47686Z"
				fill={color ?? "#DE350B"}
			/>
		</svg>
	);
}
