import { Box, Typography } from "@mui/material";

const IconText = ({ text, icon, iconProps, textProps, containerProps }) => {
	return (
		<Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", ...containerProps?.sx }}>
			<Box sx={{ marginRight: "8px", display: "flex", alignItems: "center", ...iconProps?.sx }}>{icon}</Box>
			<Typography
				component="span"
				color={(theme) => theme.palette.primary.main}
				sx={{ textAlign: "center", fontSize: "24px", fontWeight: "bold", ...textProps?.sx }}
			>
				{text}
			</Typography>
		</Box>
	);
};

export default IconText;
