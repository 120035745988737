import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Button, Card, Typography, useTheme } from "@mui/material";

import { STRATEGY_RISK_LABELS } from "constants";

import { Chip, ExchangeType, Tooltip, TradingType } from "components";

import useQuery from "utils/useQuery";

import { HighRisk, Info as InfoIcon, LowRisk, MidRisk, NoRisk, OnChain } from "images";

import Chart from "../chart";

const Strategy = ({ data, lowerTimeFrameData, timeRange }) => {
	const { profile } = useSelector((state) => state.user);
	const navigate = useNavigate();
	const theme = useTheme();
	const query = useQuery();
	const { t, i18n } = useTranslation("marketplace");
	const LOWER_TIME_FRAMES = {
		"1M": "1W",
		"3M": "1M",
		"6M": "3M",
		"1Y": "6M",
	};
	const metricsData = data?.marketStrategyMetrics?.[0]?.livetestResults?.metrics;
	const score = metricsData?.score ? metricsData?.score?.toFixed(2) : "N/A";
	const ltfMetrics = lowerTimeFrameData?.marketStrategyMetrics?.[0]?.livetestResults?.metrics;
	const riskData = useMemo(() => {
		switch (metricsData?.riskLabel) {
			case STRATEGY_RISK_LABELS.high:
				return {
					color: theme.palette.danger.main,
					icon: HighRisk,
					label: t("marketplace_strategy_risk_text_High"),
				};
			case STRATEGY_RISK_LABELS.mid:
				return {
					color: theme.palette.warning.main,
					icon: MidRisk,
					label: t("marketplace_strategy_risk_text_Mid"),
				};
			case STRATEGY_RISK_LABELS.low:
				return {
					color: theme.palette.success.main,
					icon: LowRisk,
					label: t("marketplace_strategy_risk_text_Low"),
				};
			default:
				return {
					color: theme.palette.info.dark,
					icon: NoRisk,
					label: t("marketplace_strategy_risk_text_na"),
				};
		}
	}, [metricsData]);
	const getRoiLabel = (abbr) => {
		let result = " 30 ";
		switch (abbr) {
			case "1W":
				result = " 7 ";
				break;
			case "1M":
				result = " 30 ";
				break;
			case "3M":
				result = " 90 ";
				break;
			case "6M":
				result = " 180 ";
				break;
			default:
				result = " 365 ";
		}

		return result;
	};

	return (
		<Card
			sx={{
				minWidth: "281.6px",
				maxWidth: "312.6px",
				borderRadius: "0.5rem",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "space-between",
				position: "relative",
				height: "auto",
				flex: 1,
				padding: 2,
				boxSizing: "border-box",
				transition: "transform 0.3s ease-in-out",

				"&:hover": {
					cursor: "pointer",
					transform: "scale(1.05)",
					boxShadow: "0 0 5px #0F20E8",
				},
			}}
			onClick={() => navigate(`/marketplace/strategy/${data?.id}`)}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Typography
					color="primary"
					gutterBottom
					sx={{
						fontWeight: 700,
						maxWidth: "18rem",
						textAlign: "center",
						textOverflow: "hidden",
						fontSize: "1.4rem",
						lineHeight: "1.4rem",
						marginBlockStart: "1rem",
					}}
				>
					{data.name}
				</Typography>

				<Typography
					sx={{
						fontSize: "14px",
						fontWeight: 700,
						color: "#3A3A3A",
						marginBlockStart: "0.5rem",
						marginBlockEnd: "1rem",
					}}
				>
					{data?.merchant?.nickname}
				</Typography>
			</Box>

			<Box display="flex" width="100%" justifyContent="center">
				<TradingType value={data?.strategy?.executionType} />
				{data?.strategy?.labels?.includes("OnC") && (
					<Chip
						backgroundColor="#FFFFFF"
						component={(props) => (
							<Tooltip
								title={t("marketplace_strategy_page_description_onchain")}
								icon={<InfoIcon style={{ width: "8px", height: "8px" }} />}
								{...props}
							/>
						)}
						icon={OnChain}
						label={t("marketplace_strategy_page_label_onchain")}
						sx={{
							fontSize: "14px",
							color: "#0F20E8",
							border: "1px solid rgba(15, 32, 232, 0.5)",
							borderRadius: "4px",
							ml: 1,
							height: "1.8rem",
							padding: "0.2rem 0.3rem",
						}}
					/>
				)}
				<ExchangeType value={data?.strategy?.platform.exchange} />
			</Box>
			<Box display="flex" width="100%" justifyContent="center" sx={{ height: "5rem" }}>
				<Chart data={data?.marketStrategyMetrics?.[0]?.livetestResults?.results} id={data?.id} />
			</Box>
			<Box display="flex" width="100%" justifyContent="space-between" textAlign="center">
				<Box sx={{ border: "1px solid transparent", padding: "0.5rem" }}>
					<Typography
						fontSize="1rem"
						fontWeight={700}
						color={metricsData?.totalProfitPercent > 0 ? "#36B37E" : "red"}
					>
						{metricsData?.totalProfitPercent
							? i18n.resolvedLanguage !== "tr"
								? `${metricsData?.totalProfitPercent}%` +
								  (data?.strategy?.tradeSettings?.leverage
										? `(${data?.strategy?.tradeSettings?.leverage}x)`
										: "")
								: `%${metricsData?.totalProfitPercent}` +
								  (data?.strategy?.tradeSettings?.leverage
										? `(${data?.strategy?.tradeSettings?.leverage}x)`
										: "")
							: ""}
					</Typography>
					{data?.marketStrategyMetrics?.[0]?.livetestResults?.results?.length && (
						<Typography color={"#3A3A3A"} fontSize={12} fontWeight={500}>
							{t("marketplace_main_page_market_strategy_card_details_roi_title_1")}
							<Typography color="primary" display="inline" fontSize={12} fontWeight={500}>
								{getRoiLabel(timeRange)}
							</Typography>
							{t("marketplace_main_page_market_strategy_card_details_roi_title_2")}
						</Typography>
					)}
				</Box>
				<Box sx={{ border: "1px solid transparent", padding: "0.5rem", marginLeft: "1rem" }}>
					<Typography
						fontSize="1rem"
						fontWeight={700}
						color={ltfMetrics?.totalProfitPercent > 0 ? "#36B37E" : "red"}
					>
						{ltfMetrics?.totalProfitPercent
							? i18n.resolvedLanguage !== "tr"
								? `${ltfMetrics?.totalProfitPercent}%` +
								  (data?.strategy?.tradeSettings?.leverage
										? `(${data?.strategy?.tradeSettings?.leverage}x)`
										: "")
								: `%${ltfMetrics?.totalProfitPercent}` +
								  (data?.strategy?.tradeSettings?.leverage
										? `(${data?.strategy?.tradeSettings?.leverage}x)`
										: "")
							: ""}
					</Typography>
					{data?.marketStrategyMetrics?.[0]?.livetestResults?.results?.length && (
						<Typography color={"#3A3A3A"} fontSize={12} fontWeight={500}>
							{t("marketplace_main_page_market_strategy_card_details_roi_title_1")}
							<Typography color="primary" display="inline" fontSize={12} fontWeight={500}>
								{getRoiLabel(LOWER_TIME_FRAMES[timeRange])}
							</Typography>
							{t("marketplace_main_page_market_strategy_card_details_roi_title_2")}
						</Typography>
					)}
				</Box>
			</Box>
			<Box display="flex" justifyContent="space-between" width="100%">
				<Box>
					<Box marginTop="1rem" width={"120%"}>
						<Box display="flex">
							<Typography fontWeight={500} fontSize={12} color="#3A3A3A">
								{t("marketplace_main_page_market_strategy_card_details_average_profit_title")}
							</Typography>
							<Typography fontWeight={700} fontSize={12} color="primary" marginLeft={1}>
								{metricsData?.averageProfitPercent
									? i18n.resolvedLanguage === "en"
										? `${metricsData?.averageProfitPercent}%`
										: `%${metricsData?.averageProfitPercent}`
									: ""}
							</Typography>
						</Box>
						<Box display="flex">
							<Typography fontWeight={500} fontSize={12} color="#3A3A3A">
								{t("marketplace_main_page_market_strategy_card_details_success_rate_title")}
							</Typography>
							<Typography fontWeight={700} fontSize={12} color="primary" marginLeft={1}>
								{metricsData?.successRate
									? i18n.resolvedLanguage === "en"
										? (metricsData?.successRate).toFixed(2) + "%"
										: "%" + (metricsData?.successRate).toFixed(2)
									: ""}
							</Typography>
						</Box>
						<Box display="flex">
							<Typography fontWeight={500} fontSize={12} color="#3A3A3A">
								{t("marketplace_main_page_market_strategy_card_details_positions_count_title")}
							</Typography>
							<Typography fontWeight={700} fontSize={12} color="primary" marginLeft={1}>
								{metricsData?.totalTradeCount ? metricsData?.totalTradeCount : ""}
							</Typography>
						</Box>
						<Box display="flex">
							<Typography fontWeight={500} fontSize={12} color="#3A3A3A">
								{t("marketplace_main_page_market_strategy_card_details_user_count_title")}
							</Typography>
							<Typography fontWeight={700} fontSize={12} color="#FFAB00" marginLeft={1}>
								{data?.followerCount}/{data?.maxFollowerCount}
							</Typography>
						</Box>
					</Box>
				</Box>

				<Box
					sx={{
						width: "50%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flex: "1 0 auto",
							flexDirection: "column",
							justifyContent: "flex-end",
							alignItems: "flex-end",
						}}
					>
						<Chip
							backgroundColor="#FFFFFF"
							label={"Score: " + score}
							sx={{
								fontSize: "12px",
								color: "#0F20E8",
								border: "1px solid",
								borderRadius: "4px",
								ml: 1,
								height: "1.8rem",
								padding: "0.2rem 0.3rem",
								ml: "auto",
								"& .MuiChip-icon": {
									margin: "0",
								},
								"& .MuiChip-label": {
									pl: "4px",
									pr: "4px",
								},
							}}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flex: "1 0 auto",
							flexDirection: "column",
							justifyContent: "flex-end",
							alignItems: "flex-end",
						}}
					>
						<Chip
							backgroundColor="#FFFFFF"
							label={riskData.label}
							sx={{
								fontSize: "12px",
								color: riskData.color,
								border: "1px solid",
								borderRadius: "4px",
								ml: 1,
								height: "1.8rem",
								padding: "0.2rem 0.3rem",
								ml: "auto",
								"& .MuiChip-icon": {
									margin: "0",
								},
								"& .MuiChip-label": {
									pl: "4px",
									pr: "4px",
								},
							}}
						/>
					</Box>
				</Box>
			</Box>

			<Button
				variant={
					profile &&
					!profile?.strategyFollowers?.find((item) => item.strategy?.id === data.strategy?.id) &&
					"contained"
				}
				onClick={() => navigate(`/marketplace/strategy/${data?.id}`, { state: true })}
				sx={{
					width: "100%",
					marginTop: "1rem",
					border:
						profile &&
						profile?.strategyFollowers?.find((item) => item.strategy?.id === data.strategy?.id) &&
						"1px solid #36B37E",

					color:
						profile &&
						profile.strategyFollowers?.find((item) => item.strategy?.id === data.strategy?.id) &&
						"#36B37E",
				}}
			>
				{profile &&
				!profile.strategyFollowers?.find((item) => item.strategy?.id === data.strategy?.id) &&
				data?.followerCount < data?.maxFollowerCount
					? t("marketplace_main_page_market_strategy_card_start_following")
					: t("marketplace_main_page_market_strategy_card_settings")}
			</Button>
		</Card>
	);
};

export default Strategy;
