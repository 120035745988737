import React, { useEffect } from "react";

import { Config } from "services";

function dec2hex(dec) {
	return dec.toString(16).padStart(2, "0");
}

// generateId :: Integer -> String
function generateId(len) {
	var arr = new Uint8Array((len || 40) / 2);
	window.crypto.getRandomValues(arr);
	return Array.from(arr, dec2hex).join("");
}

export default function OKXAuth() {
	useEffect(() => {
		const { OKEXOAuthSDK } = window;

		if (OKEXOAuthSDK) {
			OKEXOAuthSDK.authorize({
				response_type: "code",
				access_type: "offline",
				client_id: Config.okxFastApiClientId(),
				redirect_uri: encodeURIComponent(
					"https://aws-staging.traderlands.net/api/payment/okx-fast-api/callback"
				),
				scope: "fast_api",
				state: generateId(16),
			});
		} else {
			console.error("sdk has not been loaded");
		}
	}, []);

	return <></>;
}
