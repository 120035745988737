import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Breadcrumbs, PageCenteredProgress, RouteLayout } from "components";

import { useMarketplaceApi } from "api/marketplace";
import { useQuestBoxApi } from "api/questBox";

import ClaimRequest from "./ClaimRequest";

const QuestBoxDetailView = () => {
	const [campaign, setCampaign] = useState();
	const [merchant, setMerchant] = useState();

	const { id } = useParams();
	const navigate = useNavigate();
	const { t, i18n } = useTranslation("questBox");

	const { getCampaign } = useQuestBoxApi();
	const { getMerchantSelf } = useMarketplaceApi();

	useEffect(() => {
		if (id) {
			Promise.all([
				getCampaign(id).then((res) => {
					setCampaign(res.data.campaign);
				}),
				getMerchantSelf().then((res) => setMerchant(res)),
			]).catch((e) => console.log(e));
		}
	}, [id]);

	return (
		<RouteLayout
			headerComp={
				<Breadcrumbs
					paths={[
						{
							text: t("quest_box_main_title"),
							onClick: () => navigate("/quest-box"),
						},
						{
							text: campaign?.title?.[i18n.resolvedLanguage],
						},
					]}
				/>
			}
		>
			{!campaign && <PageCenteredProgress />}
			{campaign && <ClaimRequest campaign={campaign} merchant={merchant} />}
		</RouteLayout>
	);
};

export default QuestBoxDetailView;
