import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import AddIcon from "@mui/icons-material/Add";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	DialogContent,
	FormHelperText,
	Grid,
	IconButton,
	Paper,
	Switch,
	Tooltip,
	Typography,
} from "@mui/material";

import { difference } from "lodash";
import { useSnackbar } from "notistack";

import { Chip, CoinIcon, Slider, TextField } from "components";

import { useDashboardApi } from "api/dashboard";
import useCatchError from "api/useCatchError";

import { setConfig } from "actions/ConfigActions";
import { setStrategies } from "actions/StrategyActions";

import { useFetchAuthorized } from "services";
import Config from "services/config";

import { getAvailableBudget } from "utils/budgetSettings";

import { ArrowDown, Info, slidePercentRotated } from "images";

import { RobotContext } from "../RobotDialog";
import { QuoteMap } from "../utils";
import { BUDGET, SELECTION, SUCCESS } from "../utils/constant";
import controlPositionSize from "../utils/controlPositionSize";
import SelectQuote from "./SelectQuote";

const Result = ({ executionType, budget }) => {
	const { config } = useSelector((state) => state.config);

	const { t } = useTranslation("workshop");

	const [color, setColor] = useState("#36B37E");

	useEffect(() => {
		setColor(controlPositionSize(budget?.positionBudget, budget?.quote, config) ? "#36B37E" : "#DE350B");
	}, [budget?.positionBudget]);

	return (
		<Grid container style={{ marginTop: "30px" }}>
			<Typography sx={{ color: (theme) => theme.palette.primary.main, mb: 2 }}>
				{t("controlPanel:control_panel_robot_settings_budget_page_result_title")}
			</Typography>
			<Grid
				container
				style={{
					background: "#F4F5FC",
					borderRadius: "8px",
					padding: "10px",
					gap: "16px",
				}}
			>
				<Grid container xs={12} direction="row" justifyContent="space-between">
					<Box component="span" sx={{ textOverflow: "clip", color: "#3A3A3A" }}>
						{t("workshop_budget_settings_division_summary_amount_title")}
					</Box>
					<Box component="span" sx={{ textOverflow: "ellipsis" }}>
						<span style={{ color: "#0F20E8" }}>{budget?.amount}</span>
						<span style={{ fontSize: "12px" }}>{budget?.quote}</span>
					</Box>
				</Grid>

				<Grid container xs={12} direction="row" justifyContent="space-between">
					<Box component="span" sx={{ textOverflow: "clip", color: "#3A3A3A" }}>
						{t("workshop_budget_settings_division_con_pos_title")}
					</Box>
					<Box component="span" sx={{ textOverflow: "ellipsis", color: "#0F20E8" }}>
						<span>{budget?.maximumPositionCount}</span>
					</Box>
				</Grid>

				{executionType !== "SPOT" && budget?.leverage ? (
					<Grid container xs={12} direction="row" justifyContent="space-between">
						<Box component="span" sx={{ textOverflow: "clip", color: "#3A3A3A" }}>
							{t("workshop_budget_settings_division_summary_leverage_title")}
						</Box>
						<Box component="span" sx={{ textOverflow: "ellipsis" }}>
							<span style={{ color: "#0F20E8" }}>{budget?.leverage}x</span>
						</Box>
					</Grid>
				) : (
					<></>
				)}

				<Grid container xs={12} direction="row" justifyContent="space-between">
					<Box
						component="span"
						sx={{
							textOverflow: "clip",
							color,
						}}
					>
						{t("workshop_budget_settings_division_summary_position_amount_title")}
					</Box>
					<Box component="span" sx={{ textOverflow: "ellipsis" }}>
						<span
							style={{
								color,
							}}
						>
							{budget?.positionBudget}
						</span>
						<span style={{ fontSize: "12px" }}>{budget?.quote}</span>
					</Box>
				</Grid>
			</Grid>
		</Grid>
	);
};

const Budget = ({ contentProps, strategyName }) => {
	const {
		backButton,
		setBackButton,
		setSuccessMessage,
		setSelection,
		portfolio,
		selectedStrategyFollower,
		setSelectedStrategyFollower,
	} = useContext(RobotContext);
	const { strategies } = useSelector((state) => state.strategy);
	const { config } = useSelector((state) => state.config);
	const { profile } = useSelector((state) => state.user);

	const { strategy, budgetSettings } = selectedStrategyFollower ?? {};

	const [budgets, setBudgets] = useState(budgetSettings ?? []);
	const [quoteInfos, setQuoteInfos] = useState(
		difference(strategy?.parities?.quotes, budgetSettings ? budgetSettings?.map((x) => x.quote) : [])
	);
	const [expanded, setExpanded] = useState(budgets.map((_, ix) => false));

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const fetchAuthorized = useFetchAuthorized();
	const { t } = useTranslation();

	const catchError = useCatchError();
	const { getConfig } = useDashboardApi();

	const PAGE_SETTINGS = [
		{
			title: t("controlPanel:control_panel_robot_settings_budget_title"),
			subtitle: t("controlPanel:control_panel_robot_settings_budget_text", {
				name: strategy?.marketStrategy?.name ?? strategy?.name,
			}),
		},
		{
			title: t("controlPanel:control_panel_robot_settings_budget_page_select_base_pair_title"),
			subtitle: t("controlPanel:control_panel_robot_settings_budget_page_select_base_pair_text"),
		},
	];

	const calculatePositionBudget = ({ amount, leverage = 1, maximumPositionCount }) =>
		maximumPositionCount === 0 ? 0 : ((amount * leverage) / maximumPositionCount).toFixed(4);

	const addQuoteInfos = (quote = null) => {
		quote
			? setQuoteInfos(
					difference(strategy?.parities?.quotes, [...(budgets ? budgets?.map((x) => x.quote) : []), quote])
			  )
			: setQuoteInfos(quoteInfos.filter((x) => x !== quote));
	};

	const addDifferentParity = (quote) => {
		const recommendedMaximumPositionCount =
			selectedStrategyFollower?.strategy?.recommendedSettings?.optimalPositionCount?.[quote];

		if ((budgets ?? []).find((x) => x.quote === quote)) {
			enqueueSnackbar(t("common:Quote Already Exists"), { variant: "error" });
			return;
		}

		setBackButton({
			to: SELECTION,
			label: t("controlPanel:control_panel_robot_settings_main_title"),
		});

		setBudgets([
			...budgets,
			{
				amount: 0,
				leverage: strategy?.tradeSettings?.leverage ?? 1,
				maximumPositionCount: strategy?.public === 1 ? recommendedMaximumPositionCount : 1,
				positionBudget: calculatePositionBudget({
					amount: 0,
					leverage: 1,
					maximumPositionCount: strategy?.public === 1 ? recommendedMaximumPositionCount : 1,
				}),
				...(strategy?.public === 1 ? { syncBudgetSettings: true } : {}),
				quote,
			},
		]);
		addQuoteInfos(quote);
	};

	const updateBudgets = () => {
		if (budgets.length > 0) {
			fetchAuthorized(`${Config.apiRoot()}/strategy/strategy/${strategy.id}/followed/budget`, {
				headers: {
					"Content-type": "application/json",
				},
				method: "POST",
				body: JSON.stringify({
					budget: budgets.map((item) => ({ leverage: 1, ...item })),
				}),
			})
				.then((data) => data.json())
				.then((data) => {
					dispatch(
						setStrategies(
							strategies?.map((item) =>
								item.strategy.id === strategy.id ? { ...item, budgetSettings: budgets } : item
							)
						)
					);

					setSelectedStrategyFollower({ ...selectedStrategyFollower, budgetSettings: budgets });

					setSuccessMessage({
						title: t("controlPanel:control_panel_robot_settings_budget_success_title"),
						subtitle: t("controlPanel:control_panel_robot_settings_budget_success_text"),
					});

					setSelection(SUCCESS);
				})
				.catch((err) => {
					enqueueSnackbar(t("common:Bir hata ile karşılaşıldı"), { variant: "error" });
				});
		}
	};

	const handleChange = (ix) => (_, newExpanded) => {
		setExpanded([...expanded.slice(0, ix), newExpanded ? true : false, ...expanded.slice(ix + 1)]);
	};

	const handleSwitchChange = (event, ix) => {
		const recommendedMaximumPositionCount =
			selectedStrategyFollower?.strategy?.recommendedSettings?.optimalPositionCount?.[budgets[ix].quote];

		setBudgets([
			...budgets.slice(0, ix),
			{
				...budgets[ix],
				syncBudgetSettings: event.target.checked,
				...(event.target.checked
					? {
							maximumPositionCount: recommendedMaximumPositionCount,
					  }
					: {}),

				positionBudget: calculatePositionBudget({
					amount: budgets[ix].amount,
					leverage: budgets[ix].leverage,
					maximumPositionCount: recommendedMaximumPositionCount,
				}),
			},
			...budgets.slice(ix + 1),
		]);
	};

	const getExchangePortfolio = (strategy) => {
		let platformId = strategy?.platform?.id;
		if (platformId === 17) platformId = 15; // use okx-spot for okx-futures

		return portfolio?.find((x) => x.platformId === platformId);
	};

	useEffect(() => {
		if (selectedStrategyFollower) {
			setBudgets(selectedStrategyFollower?.budgetSettings ?? []);
			addQuoteInfos();
		}
		getConfig("MINIMUM_QUOTE_AMOUNTS")
			.then((data) => {
				dispatch(setConfig({ ...config, MINIMUM_QUOTE_AMOUNTS: data?.data }));
			})
			.catch(catchError);
	}, [selectedStrategyFollower]);

	return (
		<DialogContent sx={{ paddingTop: "10vh !important" }} {...contentProps}>
			<Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
				<Grid item xs={12}>
					<Typography variant="h5">
						{backButton.to === BUDGET ? PAGE_SETTINGS[1].title : PAGE_SETTINGS[0].title}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					{strategyName}
				</Grid>
				<Grid item xs={12}>
					<Typography variant="p">
						{backButton.to === BUDGET ? PAGE_SETTINGS[1].subtitle : PAGE_SETTINGS[0].subtitle}
					</Typography>
				</Grid>
			</Grid>

			{backButton.to === BUDGET ? (
				<SelectQuote
					platformId={strategy?.platform?.id}
					portfolio={getExchangePortfolio(strategy)}
					quotes={quoteInfos}
					addParity={addDifferentParity}
				/>
			) : (
				portfolio &&
				config &&
				budgets &&
				budgets.map((item, ix) => {
					const budgetHasBeenSet = selectedStrategyFollower?.budgetSettings?.find(
						(x) => x.quote === item.quote
					);

					const validPositionSize = controlPositionSize(item?.positionBudget, item?.quote, config);

					return (
						<Grid key={ix} spacing={2} style={{ marginTop: "10px" }}>
							<Accordion
								key={ix}
								expanded={expanded[ix]}
								TransitionProps={{ unmountOnExit: true }}
								onChange={handleChange(ix)}
							>
								<AccordionSummary
									aria-controls={`panel${ix + 1}-content`}
									id={`panel${ix + 1}-header`}
									sx={{
										border: expanded[ix] ? "none" : "1px solid",
										borderColor: validPositionSize
											? (theme) => theme.palette.primary.light
											: (theme) => theme.status.danger,
										borderRadius: "8px",
										position: "relative",
									}}
								>
									{!expanded[ix] ? (
										<Grid container>
											<Grid item xs={0.7} alignSelf="center">
												<IconButton variant="text" sx={{ width: "1.9rem" }}>
													<ArrowDown />
												</IconButton>
											</Grid>

											<Grid
												item
												xs={1.3}
												style={{
													marginRight: "auto",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<CoinIcon
													width="24px"
													height="24px"
													quote={
														strategy?.platform?.id === 1 && item.quote === "USD"
															? "XBT"
															: item.quote
													}
												/>
											</Grid>
											<Grid item xs={9}>
												<Typography
													fontSize="16px"
													sx={{
														marginTop: 0.5,
														alignItems: "center",
													}}
												>
													{strategy?.platform?.id === 1 && item.quote === "USD"
														? "XBT"
														: QuoteMap[item.quote]}
												</Typography>

												{!budgetHasBeenSet && (
													<Typography
														fontSize="12px"
														sx={{ color: (ahmet) => ahmet.status.danger }}
													>
														{t(
															"controlPanel:robot_settings_budget_settings_quote_settings_not_completed_text"
														)}
													</Typography>
												)}

												{budgetHasBeenSet && validPositionSize && (
													<Typography
														fontSize="12px"
														sx={{ color: (ahmet) => ahmet.status.success }}
													>
														{t(
															"controlPanel:robot_settings_budget_settings_quote_settings_completed_text"
														)}
													</Typography>
												)}

												{budgetHasBeenSet && !validPositionSize && (
													<Typography
														fontSize="12px"
														sx={{ color: (ahmet) => ahmet.status.danger }}
													>
														{t(
															"controlPanel:robot_settings_budget_settings_quote_settings_validation_error_text"
														)}
													</Typography>
												)}
											</Grid>
											<Grid
												item
												xs={1}
												sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
											>
												<Button
													onClick={() =>
														setBudgets(budgets.filter((_, index) => index !== ix))
													}
												>
													<Typography fontSize="12px">
														{t(
															"controlPanel:robot_settings_budget_settings_delete_quote_button_text"
														)}
													</Typography>
												</Button>
											</Grid>
										</Grid>
									) : (
										<Grid
											spacing={2}
											style={{
												marginTop: 8,
												marginLeft: "auto",
												marginRight: "auto",
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}
										>
											<CoinIcon
												quote={
													strategy?.platform?.id === 1 && item.quote === "USD"
														? "XBT"
														: item.quote
												}
											/>
											<Typography variant="h5">
												{strategy?.platform?.id === 1 && item.quote === "USD"
													? "XBT"
													: QuoteMap[item.quote]}
											</Typography>
										</Grid>
									)}
								</AccordionSummary>

								<AccordionDetails>
									<Grid container>
										<Grid container>
											<Grid
												item
												xs={6}
												sx={{
													display: "flex",
													justifyContent: "flex-start",
													alignItems: "center",
													mb: -4,
												}}
											>
												<Typography
													sx={{ color: (theme) => theme.palette.primary.main, mb: 1 }}
												>
													{t("workshop:workshop_budget_settings_division_budget_title")}
												</Typography>
											</Grid>
											<Grid
												item
												xs={6}
												sx={{
													display: "flex",
													justifyContent: "flex-end",
													alignItems: "center",
													mb: -2,
												}}
											>
												{getAvailableBudget({
													portfolio,
													quote: item.quote,
													platform: strategy?.platform,
													budgetWord: t(
														"controlPanel:control_panel_robot_settings_budget_page_exchange_amount"
													),
													showLineBreak: true,
													noBudget: t(
														"controlPanel:control_panel_robot_settings_budget_page_no_budget_text"
													),
												})}
											</Grid>
										</Grid>

										<Grid item xs={12}>
											<TextField
												sx={{
													borderColor: (theme) =>
														validPositionSize
															? theme.palette.primary.main
															: theme.status.danger,
												}}
												margin="normal"
												fullWidth
												labelProps={{ sx: { color: (theme) => theme.palette.primary.main } }}
												containerProps={{ sx: { mt: 2 } }}
												type="number"
												value={item.amount}
												helperText={
													!validPositionSize && (
														<FormHelperText
															sx={{
																display: "flex",
																justifyContent: "flex-end",
																color: (theme) => theme.status.danger,
																mb: 2,
															}}
														>
															{t(
																"controlPanel:robot_settings_budget_settings_amount_validation_error_text",
																{
																	budget:
																		(config?.MINIMUM_QUOTE_AMOUNTS?.[item?.quote] *
																			item.maximumPositionCount) /
																		item.leverage,
																}
															)}
														</FormHelperText>
													)
												}
												FormHelperTextProps={{
													sx: { display: "flex", justifyContent: "flex-end" },
												}}
												InputProps={{
													inputProps: {
														min: 0,
													},
												}}
												onChange={(e) => {
													const { value: amount } = e.target;

													if (amount < 0) {
														return;
													}

													setBudgets([
														...budgets.slice(0, ix),
														{
															...item,
															amount,
															positionBudget: calculatePositionBudget({
																...item,
																amount,
															}),
														},
														...budgets.slice(ix + 1),
													]);
												}}
											/>
										</Grid>
									</Grid>
									{strategy?.public === 1 && (
										<Grid item xs={12} sx={{ mt: 2 }}>
											<Paper
												sx={{
													border: "2px dashed",
													borderColor: (theme) => theme.palette.primary.light,
													padding: 2,
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													mt: 2,
												}}
											>
												<Grid
													item
													xs={12}
													sx={{
														display: "flex",
														flexDirection: "column",
														justifyContent: "flex-start",
													}}
												>
													<Grid item sx={{ display: "flex", alignItems: "center" }}>
														<Typography
															fontSize="20px"
															sx={{
																display: "flex",
																color: (theme) =>
																	item.syncBudgetSettings
																		? theme.palette.primary.main
																		: theme.palette.text.primary,
															}}
														>
															{t(
																"controlPanel:robot_settings_budget_settings_suggested_position_count_title"
															)}
														</Typography>
														<Chip
															label={t(
																"controlPanel:robot_settings_budget_settings_suggested_position_count_label_text"
															)}
															sx={{
																marginLeft: "8px",
																backgroundColor: (theme) =>
																	item.syncBudgetSettings
																		? theme.palette.warning.main
																		: theme.palette.info.dark,

																borderRadius: "0.25rem",
															}}
														/>
													</Grid>
													<Typography
														fontSize="12px"
														sx={{
															color: (theme) =>
																item.syncBudgetSettings
																	? theme.palette.warning.main
																	: theme.palette.text.primary,
														}}
													>
														{t(
															"controlPanel:robot_settings_budget_settings_suggested_position_count_explanation_text"
														)}
													</Typography>
												</Grid>

												<Grid
													item
													sx={{
														display: "flex",
														alignItems: "center",
													}}
												>
													<Switch
														checked={item.syncBudgetSettings}
														onChange={(event) => handleSwitchChange(event, ix)}
													/>
												</Grid>
											</Paper>
										</Grid>
									)}
									<Grid container style={{ marginTop: "30px" }}>
										<Grid item xs={12}>
											<Slider
												styledValue={true}
												label={t("workshop:workshop_budget_settings_division_con_pos_title")}
												step={1}
												min={1}
												max={50}
												alwaysVisible="on"
												thumbImage={slidePercentRotated}
												disabled={item.syncBudgetSettings}
												value={item.maximumPositionCount}
												valueUnder={true}
												recommended={item.syncBudgetSettings}
												onChange={(_, value) => {
													const maximumPositionCount = value;

													setBudgets([
														...budgets.slice(0, ix),
														{
															...item,
															maximumPositionCount,
															positionBudget: calculatePositionBudget({
																...item,
																maximumPositionCount,
															}),
														},
														...budgets.slice(ix + 1),
													]);
												}}
											></Slider>
										</Grid>
									</Grid>

									{strategy?.platform?.info?.marketTypes.includes("FUTURES") ? (
										<Grid container style={{ marginTop: "30px" }}>
											<Grid item xs={12}>
												<Slider
													label={t(
														"workshop:workshop_budget_settings_division_leverage_title"
													)}
													tooltip={
														<Tooltip
															size="lg"
															title={t(
																"common:workshop_budget_settings_division_summary_leverage_tooltip"
															)}
															componentsProps={{
																tooltip: {
																	sx: {
																		backgroundColor: "#fff",
																		color: "#000",
																		display: "flex",
																		justifyContent: "space-evenly",
																		textAlign: "justify",
																		padding: "1rem",
																		boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
																	},
																},
															}}
														>
															<IconButton>
																<Info style={{ width: "16px", height: "16px" }} />
															</IconButton>
														</Tooltip>
													}
													step={1}
													min={1}
													max={
														strategy?.public == 1 && strategy?.user?.id != profile?.id
															? strategy?.tradeSettings?.leverage
															: 20
													}
													valueLabelDisplay="auto"
													value={item.leverage ?? 1}
													onChange={(e, value) => {
														const leverage = value;

														setBudgets([
															...budgets.slice(0, ix),
															{
																...item,
																leverage,
																positionBudget: calculatePositionBudget({
																	...item,
																	leverage,
																}),
															},
															...budgets.slice(ix + 1),
														]);
													}}
												></Slider>
											</Grid>
											<Grid container xs={12} direction="row" justifyContent="space-between">
												<Box component="span" sx={{ textOverflow: "clip" }}>
													1X
												</Box>
												<Box component="span" sx={{ textOverflow: "ellipsis" }}>
													{strategy?.public == 1 && strategy?.user?.id != profile?.id
														? strategy?.tradeSettings?.leverage
														: 10}
													X
												</Box>
											</Grid>
										</Grid>
									) : (
										<></>
									)}
									<Result executionType={strategy?.executionType} budget={item} />
								</AccordionDetails>
							</Accordion>
						</Grid>
					);
				})
			)}
			{backButton.to !== BUDGET && (
				<Grid container spacing={2} sx={{ paddingTop: "3vh" }}>
					{quoteInfos?.length > 0 && (
						<Grid item xs={12}>
							<Button
								fullWidth
								variant="outlined"
								startIcon={<AddIcon />}
								onClick={() => {
									setBackButton({
										to: BUDGET,
										label: t(
											"controlPanel:control_panel_robot_settings_budget_page_select_base_pair_back_button_text"
										),
									});
								}}
							>
								{t("workshop:workshop_budget_settings_division_summary_add_another_parity")}
							</Button>
						</Grid>
					)}
					<Grid item xs={12}>
						<Button
							fullWidth
							variant="contained"
							disabled={
								!budgets.every((budget) =>
									controlPositionSize(budget?.positionBudget, budget?.quote, config)
								)
							}
							onClick={() => {
								updateBudgets();
							}}
						>
							{t("workshop:workshop_budget_settings_division_summary_save_button_title")}
						</Button>
					</Grid>
				</Grid>
			)}
		</DialogContent>
	);
};

export default Budget;
