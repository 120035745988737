import React from "react";

import { useTheme } from "@mui/material/styles";

export default function ({ width, height, color: colorProps }) {
	const theme = useTheme();

	const color = colorProps ?? theme.palette.primary.main;

	return (
		<svg
			width={width || "24"}
			height={height || "30"}
			viewBox="0 0 24 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M0 28.4883C0 21.863 5.37471 16.4883 12 16.4883H23.4353C23.7472 16.4883 24 16.7411 24 17.053C24 23.6783 18.6253 29.053 12 29.053H0.564706C0.252827 29.053 0 28.8002 0 28.4883ZM1.14384 27.9236H12C17.8121 27.9236 22.5621 23.357 22.8562 17.6177H12C6.18787 17.6177 1.43786 22.1843 1.14384 27.9236Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M0 17.0547C0 10.4294 5.37471 5.05469 12 5.05469C12.3119 5.05469 12.5647 5.30751 12.5647 5.61939V17.0547C12.5647 17.3666 12.3119 17.6194 12 17.6194H0.564706C0.252827 17.6194 0 17.3666 0 17.0547ZM1.14384 16.49H11.4353V6.19853C5.88302 6.48297 1.42828 10.9377 1.14384 16.49Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.4355 5.61939C11.4355 5.30751 11.6884 5.05469 12.0003 5.05469C18.6255 5.05469 24.0003 10.4294 24.0003 17.0547C24.0003 17.3666 23.7474 17.6194 23.4355 17.6194H12.0003C11.6884 17.6194 11.4355 17.3666 11.4355 17.0547V5.61939ZM12.565 6.19853V16.49H22.8564C22.572 10.9377 18.1172 6.48297 12.565 6.19853Z"
				fill={color}
			/>
			<path
				d="M17.2239 14.1216C17.9943 14.1216 18.6187 13.4972 18.6187 12.7268C18.6187 11.9565 17.9943 11.332 17.2239 11.332C16.4536 11.332 15.8291 11.9565 15.8291 12.7268C15.8291 13.4972 16.4536 14.1216 17.2239 14.1216Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M17.2232 11.8989C16.7647 11.8989 16.3931 12.2706 16.3931 12.729C16.3931 13.1875 16.7647 13.5591 17.2232 13.5591C17.6817 13.5591 18.0533 13.1875 18.0533 12.729C18.0533 12.2706 17.6817 11.8989 17.2232 11.8989ZM15.2637 12.729C15.2637 11.6468 16.141 10.7695 17.2232 10.7695C18.3054 10.7695 19.1827 11.6468 19.1827 12.729C19.1827 13.8113 18.3054 14.6886 17.2232 14.6886C16.141 14.6886 15.2637 13.8113 15.2637 12.729Z"
				fill={color}
			/>
			<path
				d="M6.77668 14.1216C7.54702 14.1216 8.17149 13.4972 8.17149 12.7268C8.17149 11.9565 7.54702 11.332 6.77668 11.332C6.00634 11.332 5.38184 11.9565 5.38184 12.7268C5.38184 13.4972 6.00634 14.1216 6.77668 14.1216Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M6.77693 11.8989C6.31845 11.8989 5.94679 12.2706 5.94679 12.729C5.94679 13.1875 6.31845 13.5591 6.77693 13.5591C7.23539 13.5591 7.60703 13.1875 7.60703 12.729C7.60703 12.2706 7.23539 11.8989 6.77693 11.8989ZM4.81738 12.729C4.81738 11.6468 5.69473 10.7695 6.77693 10.7695C7.85915 10.7695 8.73644 11.6468 8.73644 12.729C8.73644 13.8113 7.85915 14.6886 6.77693 14.6886C5.69473 14.6886 4.81738 13.8113 4.81738 12.729Z"
				fill={color}
			/>
			<path
				d="M11.9997 24.3102C13.6939 24.3102 15.0717 22.9324 15.0717 21.2383H8.92773C8.92773 22.9324 10.3056 24.3102 11.9997 24.3102Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8.36328 21.2405C8.36328 20.9286 8.61611 20.6758 8.92799 20.6758H15.072C15.3839 20.6758 15.6367 20.9286 15.6367 21.2405C15.6367 23.2465 14.006 24.8772 12 24.8772C9.994 24.8772 8.36328 23.2465 8.36328 21.2405ZM12 23.7477C13.1881 23.7477 14.1862 22.9165 14.4431 21.8052H9.55685C9.81375 22.9165 10.8119 23.7477 12 23.7477Z"
				fill={color}
			/>
			<path
				d="M18.6127 7.76041V3.54207L13.6037 5.16278L11.9943 1.96094L10.3849 5.16278L5.37598 3.54207V7.76041C6.73127 9.1157 17.2517 9.1157 18.607 7.76041H18.6127Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.9946 1.39453C12.2081 1.39453 12.4033 1.5149 12.4992 1.70562L13.891 4.47468L18.4391 3.00309C18.611 2.94748 18.7991 2.97745 18.9451 3.08372C19.0912 3.18999 19.1776 3.35973 19.1776 3.54037V7.75871C19.1776 7.94562 19.0868 8.11132 18.9469 8.21411C18.6726 8.45703 18.2732 8.62158 17.8687 8.74393C17.4109 8.88242 16.8525 8.99328 16.2378 9.08021C15.0064 9.25433 13.4943 9.33989 11.9918 9.33989C10.4892 9.33989 8.97711 9.25433 7.74569 9.08021C7.13099 8.99328 6.57259 8.88242 6.11477 8.74393C5.67994 8.61239 5.25097 8.43207 4.97692 8.15802C4.87102 8.05211 4.81152 7.90848 4.81152 7.75871V3.54037C4.81152 3.35973 4.89795 3.18999 5.04402 3.08372C5.1901 2.97745 5.3782 2.94748 5.55007 3.00309L10.0982 4.47468L11.49 1.70562C11.5859 1.5149 11.7811 1.39453 11.9946 1.39453ZM11.9946 3.21663L10.8897 5.41469C10.7646 5.66369 10.4765 5.78415 10.2113 5.69836L5.94094 4.31662V7.46932C6.05045 7.52638 6.21274 7.59361 6.44179 7.6629C6.82401 7.77852 7.32047 7.87943 7.90382 7.96192C9.06852 8.12661 10.5253 8.21047 11.9918 8.21047C13.4582 8.21047 14.915 8.12661 16.0797 7.96192C16.663 7.87943 17.1595 7.77852 17.5417 7.6629C17.7747 7.59241 17.9387 7.52404 18.0482 7.46635V4.31662L13.7778 5.69836C13.5127 5.78415 13.2246 5.66369 13.0994 5.41469L11.9946 3.21663Z"
				fill={color}
			/>
			<path
				d="M12.0003 3.35609C12.7706 3.35609 13.3951 2.73162 13.3951 1.96128C13.3951 1.19094 12.7706 0.566406 12.0003 0.566406C11.23 0.566406 10.6055 1.19094 10.6055 1.96128C10.6055 2.73162 11.23 3.35609 12.0003 3.35609Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.9996 1.12941C11.5411 1.12941 11.1695 1.5011 11.1695 1.95958C11.1695 2.41803 11.5411 2.78968 11.9996 2.78968C12.458 2.78968 12.8297 2.41804 12.8297 1.95958C12.8297 1.50108 12.458 1.12941 11.9996 1.12941ZM10.04 1.95958C10.04 0.877382 10.9173 0 11.9996 0C13.0818 0 13.9591 0.8774 13.9591 1.95958C13.9591 3.0418 13.0818 3.91909 11.9996 3.91909C10.9174 3.91909 10.04 3.04182 10.04 1.95958Z"
				fill={color}
			/>
		</svg>
	);
}
