import { useTranslation } from "react-i18next";

import { Box, Grid, Typography } from "@mui/material";

const NoActiveCampaign = ({ handleDialog }) => {
	const { t } = useTranslation("questBox");

	return (
		<Box
			sx={{
				minHeight: "350px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
			}}
		>
			<Grid container justifyContent={"center"} pb={2}>
				<Grid xs={10}>
					<Box>
						<Typography
							color={(theme) => theme.palette.primary.main}
							fontWeight={"Bold"}
							sx={{ textAlign: "center", fontSize: "24px" }}
						>
							{t("quest_box_no_active_campaign_text_1")}
						</Typography>

						<Typography sx={{ textAlign: "center", fontSize: "16px" }}>
							{t("quest_box_no_active_campaign_text_2")}
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default NoActiveCampaign;
