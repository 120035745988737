import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { PageCenteredProgress, RouteLayout } from "components";

import { useUserApi } from "api/user";

import { setParities } from "actions/ParityActions";
import { setStrategies } from "actions/StrategyBuilderActions";
import { setProfile } from "actions/UserActions";

import { useFetchAuthorized } from "services";
import Config from "services/config";

import useQuery from "utils/useQuery";

import CreateStrategy from "./CreateStrategy";
import MerchantStatus from "./MerchantStatus";
import MyStrategies from "./MyStrategies";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export const WorkshopContext = createContext();

export default function Workshop() {
	const [tab, setTab] = useState(0);
	const [merchantStatus, setMerchantStatus] = useState();
	const [merchantStrategies, setMerchantStrategies] = useState();
	const [processing, setProcessing] = useState();

	const theme = useTheme();
	const { t, i18n } = useTranslation("workshop");
	const fetchAuthorized = useFetchAuthorized();
	const dispatch = useDispatch();
	const query = useQuery();
	const navigate = useNavigate();
	const { getProfile } = useUserApi();

	const getParities = () => {
		fetchAuthorized(`${Config.apiRoot()}/platform/parities?status=ACTIVE`, {
			headers: {
				"Content-type": "application/json",
			},
			method: "GET",
		})
			.then((data) => data.json())
			.then((data) => {
				dispatch(setParities([...data?.data?.parities].sort((a, b) => a.symbol.localeCompare(b.symbol))));
			});
	};

	const getStrategies = () => {
		fetchAuthorized(`${Config.apiRoot()}/strategy/strategies`, {
			headers: {
				"Content-type": "application/json",
			},
			method: "GET",
		})
			.then((data) => data.json())
			.then((data) => {
				setMerchantStrategies(data?.data?.strategies);
				dispatch(setStrategies(data?.data?.strategies));
			});
	};

	useEffect(() => {
		setProcessing(true);

		getParities();
		getStrategies();
		getProfile().then((data) => {
			setMerchantStatus(data?.data.profile.merchant);
			dispatch(setProfile(data?.data.profile));
			setProcessing(false);
		});
	}, []);

	useEffect(() => {
		const currentTab = query.get("t");

		if (!currentTab || currentTab === "create") {
			setTab(0);
		}

		if (currentTab === "strategies") {
			setTab(1);
		}
	}, [query]);

	useEffect(() => {}, []);

	return (
		<>
			{processing && <PageCenteredProgress />}
			<RouteLayout header={t("workshop_main_page_title")}>
				{merchantStatus?.progressStatus === "PENDING" ? (
					<MerchantStatus
						status={"PENDING"}
						title={t("workshop_main_page_info_title_3")}
						buttonText={t("workshop_main_page_info_button_3")}
						buttonAction={(x) => navigate("/")}
						icon={"https://cdn2.traderlands.com/merchant/workshop-status-images/pending.svg"}
					/>
				) : merchantStatus?.progressStatus === "ACCEPTED" ? (
					<MerchantStatus
						status={"ACCEPTED"}
						title={t("workshop_main_page_info_title_5")}
						buttonText={t("workshop_main_page_info_button_5")}
						buttonAction={(x) => navigate("/expert-panel/" + merchantStatus?.id)}
						icon={"https://cdn2.traderlands.com/merchant/workshop-status-images/go_creator_panel.svg"}
					/>
				) : merchantStatus?.progressStatus === "REJECTED" ? (
					<MerchantStatus
						status={"REJECTED"}
						title={t("workshop_main_page_info_title_4")}
						buttonText={t("workshop_main_page_info_button_4")}
						buttonAction={(x) => navigate("/workshop/expert-application")}
						icon={"https://cdn2.traderlands.com/merchant/workshop-status-images/rejected.svg"}
					/>
				) : merchantStrategies?.length > 0 ? (
					<MerchantStatus
						title={t("workshop_main_page_info_title_2")}
						buttonText={t("workshop_main_page_info_button_2")}
						buttonAction={(x) => navigate("/workshop/expert-application")}
						icon={"https://cdn2.traderlands.com/merchant/workshop-status-images/apply.svg"}
					/>
				) : (
					<MerchantStatus
						title={t("workshop_main_page_info_title_1")}
						buttonText={t("workshop_main_page_info_button_1")}
						buttonAction={(x) =>
							i18n.resolvedLanguage === "en"
								? window.open(
										"https://www.youtube.com/watch?v=XpKfINGfPuc&list=PLsU0nkggS3KjTlVHSPIESMDIYNmd0abCm"
								  )
								: window.open("https://help.traderlands.com/tr/collections/3915860-workshop")
						}
						icon={"https://cdn2.traderlands.com/merchant/workshop-status-images/guide.svg"}
					/>
				)}

				<Grid container>
					<Grid item xs={12}>
						<Paper sx={{ padding: "24px", marginBottom: "16px" }}>
							<Typography sx={{ color: "#3A3A3A", fontSize: "14px", mt: "8px", mb: "8px" }}>
								{t("workshop_main_page_main_text_1")}
							</Typography>
							<Tabs
								sx={{ textTransform: "null" }}
								variant="fullWidth"
								centered
								value={tab}
								onChange={(_, newValue) => {
									navigate(`/workshop?t=${newValue === 0 ? "create" : "strategies"}`);
								}}
							>
								<Tab
									sx={{ textTransform: "none" }}
									fullWidth
									label={t("workshop_main_page_tab_1_title")}
									{...a11yProps(0)}
								></Tab>
								<Tab
									sx={{ textTransform: "none" }}
									fullWidth
									label={t("workshop_main_page_tab_2_title")}
									{...a11yProps(1)}
								></Tab>
							</Tabs>
							{tab === 0 ? <CreateStrategy /> : <MyStrategies />}
						</Paper>
					</Grid>
				</Grid>
			</RouteLayout>
		</>
	);
}
