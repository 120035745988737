import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Button, DialogContent, Grid, Typography } from "@mui/material";

import { discord, telegram } from "images";

import { StrategyContext } from "./MyStrategiesDialog";
import SelectionCard from "./SelectionCard";
import { ADD_CONNECTION_SETTINGS, SHOW_CONNECTIONS } from "./utils/constant";

export default function CommercialSettings({ contentProps }) {
	const { t } = useTranslation("workshop");
	const { setSelection, selectedNetwork, setSelectedNetwork } = useContext(StrategyContext);
	const handleSetSelectedNetwork = (value) => {
		setSelectedNetwork(value);
	};

	return (
		<>
			<DialogContent sx={{ paddingTop: "10vh !important" }} {...contentProps}>
				<Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
					<Grid item xs={12}>
						<Typography variant="h5">{t("workshop_commercial_settings_header")}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="p">{t("workshop_commercial_settings_header_subtext")}</Typography>
					</Grid>
					<Grid item xs={12}>
						<SelectionCard
							icon={discord}
							title={"Discord"}
							value={"discord"}
							status={selectedNetwork}
							handleSetSelectedNetwork={handleSetSelectedNetwork}
						></SelectionCard>
						<SelectionCard
							icon={telegram}
							title={"Telegram"}
							value={"telegram"}
							status={selectedNetwork}
							handleSetSelectedNetwork={handleSetSelectedNetwork}
						></SelectionCard>
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{ paddingTop: "3vh" }}>
					<Grid item xs={12}>
						<Button
							fullWidth
							variant="outlined"
							onClick={() => {
								setSelection(SHOW_CONNECTIONS);
							}}
						>
							{t("workshop_commercial_settings_edit_button")}
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button
							fullWidth
							variant="contained"
							onClick={() => {
								setSelection(ADD_CONNECTION_SETTINGS);
							}}
						>
							{t("workshop_commercial_settings_add_button")}
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</>
	);
}
