import { useTheme } from "@mui/material/styles";

export default function MenuRobots({ active }) {
	const theme = useTheme();

	const color = active ? theme.palette.primary.main : theme.palette.primary.light;

	return (
		<svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_19601_129536)">
				<path
					d="M0.611328 1.2041V19.5932C0.611328 29.7469 8.8467 37.9822 19.0004 37.9822V19.5932C19.0004 9.43947 10.765 1.2041 0.611328 1.2041Z"
					fill="white"
				/>
				<path
					d="M19 38.5928C8.52862 38.5928 0 30.0764 0 19.5928V1.20371C0 0.861584 0.26881 0.592773 0.610932 0.592773C11.0945 0.592773 19.6109 9.12139 19.6109 19.5928V37.9818C19.6109 38.324 19.3421 38.5928 19 38.5928ZM1.22186 1.82686V19.5928C1.22186 29.1966 8.87074 37.041 18.3891 37.3587V19.5928C18.3891 9.98891 10.7402 2.14454 1.22186 1.82686Z"
					fill={color}
				/>
				<path d="M37.3891 1.2041C27.2354 1.2041 19 9.43947 19 19.5932H37.3891V1.2041Z" fill="white" />
				<path
					d="M37.3887 20.2037H18.9996C18.6575 20.2037 18.3887 19.9349 18.3887 19.5928C18.3887 9.12139 26.9173 0.592773 37.3887 0.592773C37.7308 0.592773 37.9996 0.861584 37.9996 1.20371V19.5928C37.9996 19.9349 37.7308 20.2037 37.3887 20.2037ZM19.6228 18.9818H36.7777V1.82686C27.4549 2.14454 19.9404 9.67123 19.6228 18.9818Z"
					fill={color}
				/>
				<path d="M19 38C28.9389 38 37 29.9389 37 20L19 20L19 38Z" fill="white" />
				<path
					d="M18.9996 38.5924C18.6575 38.5924 18.3887 38.3236 18.3887 37.9814V19.5924C18.3887 19.2503 18.6575 18.9814 18.9996 18.9814H37.3887C37.7308 18.9814 37.9996 19.2503 37.9996 19.5924C37.9996 30.0638 29.471 38.5924 18.9996 38.5924ZM19.6105 20.2033V37.3583C28.9334 37.0406 36.4478 29.5139 36.7655 20.2033H19.6105Z"
					fill={color}
				/>
				<path
					d="M28.2009 21.6097C29.4358 21.6097 30.4369 20.6086 30.4369 19.3737C30.4369 18.1388 29.4358 17.1377 28.2009 17.1377C26.9659 17.1377 25.9648 18.1388 25.9648 19.3737C25.9648 20.6086 26.9659 21.6097 28.2009 21.6097Z"
					fill="white"
				/>
				<path
					d="M28.2005 22.2203C26.6243 22.2203 25.3535 20.9373 25.3535 19.3733C25.3535 17.8093 26.6365 16.5264 28.2005 16.5264C29.7644 16.5264 31.0474 17.8093 31.0474 19.3733C31.0474 20.9373 29.7644 22.2203 28.2005 22.2203ZM28.2005 17.7482C27.3085 17.7482 26.5754 18.4814 26.5754 19.3733C26.5754 20.2653 27.3085 20.9984 28.2005 20.9984C29.0924 20.9984 29.8255 20.2653 29.8255 19.3733C29.8255 18.4814 29.0924 17.7482 28.2005 17.7482Z"
					fill={color}
				/>
				<path
					d="M9.38445 16.807C10.6194 16.807 11.6205 15.8059 11.6205 14.571C11.6205 13.3361 10.6194 12.335 9.38445 12.335C8.14953 12.335 7.14844 13.3361 7.14844 14.571C7.14844 15.8059 8.14953 16.807 9.38445 16.807Z"
					fill="white"
				/>
				<path
					d="M9.38405 17.4302C7.80785 17.4302 6.53711 16.1473 6.53711 14.5833C6.53711 13.0193 7.82007 11.7363 9.38405 11.7363C10.948 11.7363 12.231 13.0193 12.231 14.5833C12.231 16.1473 10.948 17.4302 9.38405 17.4302ZM9.38405 12.9582C8.49209 12.9582 7.75897 13.6913 7.75897 14.5833C7.75897 15.4752 8.49209 16.2084 9.38405 16.2084C10.276 16.2084 11.0091 15.4752 11.0091 14.5833C11.0091 13.6913 10.276 12.9582 9.38405 12.9582Z"
					fill={color}
				/>
				<path
					d="M19.0125 31.2615C21.7373 31.2615 23.9488 29.05 23.9488 26.3252H14.0762C14.0762 29.05 16.2877 31.2615 19.0125 31.2615Z"
					fill="white"
				/>
				<path
					d="M19.0121 31.8721C15.9574 31.8721 13.4648 29.3795 13.4648 26.3248C13.4648 25.9827 13.7337 25.7139 14.0758 25.7139H23.9484C24.2906 25.7139 24.5594 25.9827 24.5594 26.3248C24.5594 29.3795 22.0668 31.8721 19.0121 31.8721ZM14.7234 26.9357C15.0166 29.0373 16.825 30.6502 19.0121 30.6502C21.1992 30.6502 22.9954 29.0251 23.3009 26.9357H14.7356H14.7234Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_19601_129536">
					<rect width="38" height="38" fill="white" transform="translate(0 0.592773)" />
				</clipPath>
			</defs>
		</svg>
	);
}
