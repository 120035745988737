import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Button, Card, Typography } from "@mui/material";

import { useDashboardApi } from "api/dashboard";

import { setConfig } from "actions/ConfigActions";

import { AlgorithmicTradeMadeSimple } from "images";
import blueBackground from "images/blue-bg.png";

function QuestBoxBanner() {
	const { config } = useSelector((state) => state.config);

	const { t, i18n } = useTranslation("questBox");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { getConfig } = useDashboardApi();

	useEffect(() => {
		getConfig("LEADERBOARD_FLAG").then((data) => {
			dispatch(setConfig({ ...config, LEADERBOARD_FLAG: data?.data }));
		});
	}, []);

	const { title, text, leaderboardCampaignOn, campaignId, imageUrl, comingSoon } = config?.LEADERBOARD_FLAG ?? {};

	return (
		<Card
			sx={{
				width: "84vw",
				display: "flex",
				borderRadius: "1.1rem",
				height: "8.5rem",
				cursor: leaderboardCampaignOn ? "pointer" : "default",
			}}
			onClick={() => (leaderboardCampaignOn ? navigate(`/quest-box/${campaignId}/full-list`) : "none")}
		>
			<Box padding="0.8rem">
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<Typography
						variant="h3"
						sx={{
							fontSize: "1rem",
							fontWeight: 700,
							color: "#0F20E8",
							maxWidth: "100%",
							marginInlineEnd: "1rem",
							mt: 1,
							width: "8rem",
						}}
					>
						{leaderboardCampaignOn ? title?.[i18n.resolvedLanguage] : t("quest_box_leaderboard_main_title")}
					</Typography>

					{comingSoon && (
						<Button
							sx={{
								backgroundColor: "#F4F5FC",
								padding: "1rem",
								height: "2rem",
								width: "10rem",
								cursor: leaderboardCampaignOn ? "pointer" : "default",
								mt: 1,
							}}
						>
							{t("quest_box_coming_soon_chip_text")}
						</Button>
					)}
				</Box>

				<Typography color="#3A3A3A" fontSize="12px" sx={{ mt: "1rem", width: "15rem" }}>
					{leaderboardCampaignOn ? text?.[i18n.resolvedLanguage] : t("quest_box_leaderboard_main_text")}
				</Typography>
			</Box>

			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					width: "100%",
					backgroundImage: leaderboardCampaignOn ? "none" : `url(${blueBackground})`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "right",
					backgroundSize: "16rem 10rem",
				}}
			>
				{leaderboardCampaignOn ? (
					<></>
				) : (
					<AlgorithmicTradeMadeSimple
						style={{
							width: "6rem",
							marginInlineEnd: "2rem",
						}}
					/>
				)}
				<img
					src={leaderboardCampaignOn ? imageUrl : "none"}
					alt="imageUrl"
					style={{
						width: "15rem",
						marginInlineEnd: "-2rem",
					}}
				/>
			</div>
		</Card>
	);
}

export default QuestBoxBanner;
