import { usePaymentApi } from "api/payment";

import { Config } from "services";

export default function useFastApiConnection() {
	const { startOkxFastApiConnection } = usePaymentApi();

	return (cb) =>
		startOkxFastApiConnection()
			.then((data) => data?.data?.stateCode)
			.then((state) => {
				const { OKEXOAuthSDK } = window;

				if (OKEXOAuthSDK) {
					OKEXOAuthSDK.authorize({
						response_type: "code",
						access_type: "offline",
						client_id: Config.okxFastApiClientId(),
						redirect_uri: encodeURIComponent(`${Config.apiRoot()}/payment/okx-fast-api/callback`),
						scope: "fast_api",
						state,
					});
				} else {
					console.error("sdk has not been loaded");
				}
			})
			.finally(() => cb && cb());
}
