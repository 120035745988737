import React from "react";

import { Tooltip as MuiTooltip, Box } from "@mui/material";

const Tooltip = ({ title, children, icon, iconProps, tooltipProps, ...props }) => {
	return (
		<MuiTooltip
			title={title}
			componentsProps={{
				tooltip: {
					sx: {
						backgroundColor: "#fff",
						color: "#000",
						display: "flex",
						justifyContent: "space-evenly",
						textAlign: "justify",
						padding: "1rem",
						boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
						...tooltipProps?.sx,
					},
				},
			}}
		>
			<Box size="lg" {...props} sx={{ position: "relative" }}>
				{icon && (
					<Box
						sx={{
							position: "absolute",
							display: "flex",
							top: "0",
							right: "0",
							padding: "2px",
							...iconProps?.sx,
						}}
					>
						{icon}
					</Box>
				)}
				{children}
			</Box>
		</MuiTooltip>
	);
};

export default Tooltip;
