import { Grid, Slider as MuiSlider, SliderThumb, Typography } from "@mui/material";

import { slideIcon } from "images";

function ThumbComponent(props) {
	const { children, image, thumbColor, recommended, ...other } = props;
	return (
		<SliderThumb {...other} sx={{ mt: "2px" }}>
			{children}
			<span style={{ color: "red" }}>
				<img
					src={image ?? slideIcon}
					alt=""
					style={{ filter: recommended ? `hue-rotate(${thumbColor}deg)` : "none" }}
				/>
			</span>
		</SliderThumb>
	);
}

export default function Slider({
	label,
	step,
	min,
	max,
	marks,
	value,
	onChange,
	labelProps,
	tooltip,
	containerProps,
	alwaysVisible = "auto",
	disabled,
	thumbImage,
	valueUnder = false,
	recommended = false,
	...rest
}) {
	return (
		<Grid container spacing={1} {...containerProps}>
			{label && (
				<>
					<Grid item xs={10}>
						<Typography
							component="span"
							sx={{ color: (theme) => theme.palette.primary.main }}
							{...labelProps}
						>
							{label}
							{tooltip && tooltip}
						</Typography>
					</Grid>
					<Grid item xs={2} sx={{ textAlign: "right" }}>
						<Typography
							component="span"
							sx={{ color: (theme) => theme.palette.primary.main }}
							{...labelProps}
						>
							{value}
						</Typography>
					</Grid>
				</>
			)}
			<Grid item xs={12}>
				<MuiSlider
					{...rest}
					components={{
						Thumb: (props) => (
							<ThumbComponent image={thumbImage} {...props} recommended={recommended} thumbColor={180} />
						),
					}}
					step={step}
					min={min}
					max={max}
					valueLabelDisplay={alwaysVisible}
					marks={marks}
					value={value}
					onChange={onChange}
					disabled={disabled}
					size="small"
					sx={{
						...rest?.sx,
						"& .MuiSlider-markLabel[data-index='0']": {
							left: "12px !important",
						},
						"& .MuiSlider-markLabel[data-index='1']": {
							left: "calc(100% - 12px) !important",
						},
						"& .MuiSlider-valueLabel": {
							fontSize: 12,
							fontWeight: "normal",
							top: valueUnder ? 35 : -8,
							backgroundColor: "unset",
							color: (theme) => (recommended ? theme.palette.warning.main : theme.palette.primary.main),
							"&:before": {
								display: "none",
							},
						},
					}}
				/>
			</Grid>
		</Grid>
	);
}
