import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Button, CircularProgress, Grid, IconButton, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Chip, Dialog } from "components";
import StrategyExchangeType from "components/StrategyExchangeType";

import { setActiveStep } from "actions/StrategyBuilderActions";

import Config from "services/config";

import {
	Edit,
	EditIcon,
	LightningIcon,
	MarketPlace as MarketplaceIcon,
	Private as PrivateIcon,
	SettingsBigger,
	Signal as SignalIcon,
	SpotRight,
	Uri as UriIcon,
} from "images";

import MyStrategiesDialog from "./MyStrategiesDialog";

const StrategyStatus = ({ isPublic, id }) => {
	const { t } = useTranslation("workshop");
	const { profile } = useSelector((state) => state.user);
	const theme = useTheme();

	const profileStrategyFollowers = [...profile?.strategyFollowers];

	const isFollowerContains = profileStrategyFollowers.some((follower) => follower?.strategy.id === id);

	return (
		<Box
			sx={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			{isPublic === 1 && (
				<>
					<MarketplaceIcon width="20" height="20" fill={theme.palette.success.main} />
					<Typography sx={{ fontSize: "14px" }} color={(theme) => theme.palette.success.main}>
						{t("workshop_strategy_status_marketplace")}
					</Typography>
				</>
			)}
			{isPublic === 0 && isFollowerContains && (
				<>
					<LightningIcon width="20" height="20" />
					<Typography sx={{ fontSize: "14px" }} color={(theme) => theme.palette.primary.main}>
						{t("workshop_strategy_status_ready")}
					</Typography>
				</>
			)}
			{isPublic === 0 && !isFollowerContains && (
				<>
					<EditIcon width="20" height="20" fill={theme.palette.text.dark} />
					<Typography sx={{ fontSize: "14px" }} color={(theme) => theme.palette.text.dark}>
						{t("workshop_strategy_status_draft")}
					</Typography>
				</>
			)}
		</Box>
	);
};

const StrategyCard = ({ strategy }) => {
	const { parities: allParities } = useSelector((state) => state.parity);
	const { name, executionType, platform, labels, id, webhookToken } = strategy;
	const parities = allParities.filter((x) => strategy?.parities?.symbols?.some((y) => y == x.id));
	const theme = useTheme();

	const [open, setOpen] = useState(false);
	const [settingsOpen, setSettingsOpen] = useState(false);
	const { t } = useTranslation("workshop");

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const isPublic = strategy?.public;

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Dialog
				dialogProps={{ open, onClose: handleClose }}
				title={t("workshop_my_strategies_parities_title")}
				content={
					<Grid container>
						{parities &&
							parities?.map(({ base, quote }, ix) => (
								<Button
									key={ix}
									sx={{
										ml: 2,
										mt: 1,
										borderRadius: "8px",
										minWidth: "150px",
										maxWidth: "150px",
										maxHeight: "100px",
										padding: "8px",
										cursor: "pointer",
										color: "black",
										border: "1px solid #CFD2FA",
									}}
									startIcon={
										<img
											width="24px"
											height="24px"
											style={{ marginLeft: 8 }}
											src={`${Config.cdnRoot()}/general/crypto-icons/${base}.png`}
											alt="crpyto-icon"
										/>
									}
								>
									<Grid container sx={{ marginLeft: 1 }}>
										<Grid item>
											<Typography
												variant="subtitle1"
												fontWeight="bold"
												sx={{ textAlign: "left" }}
											>
												{base}
											</Typography>
											<Grid container>
												<Grid item>
													<SpotRight style={{ marginTop: 2, marginRight: 4 }} />
												</Grid>
												<Grid item>
													<Typography variant="p">{quote}</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Button>
							))}
					</Grid>
				}
			></Dialog>
			<Paper sx={{ mt: 2, padding: 2, borderColor: theme.palette.primary.light }} variant="outlined">
				<Grid container>
					<Grid item xs={1}>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								height: "100%",
								minHeight: "69px",
								width: "70px",
								border: `1px solid ${theme.palette.primary.light}`,
								borderRadius: "8px",
							}}
						>
							{strategy?.strategyTypeId === 1 && <UriIcon active={true} />}
							{strategy?.strategyTypeId === 2 && <SignalIcon />}
							{strategy?.strategyTypeId === 3 && <PrivateIcon />}
						</Box>
					</Grid>
					<Grid item display={"flex"} flexDirection={"column"} justifyContent={"center"} xs={5}>
						<Grid item xs={12}>
							<Typography
								sx={{
									ml: 2,
									fontSize: "16px",
									fontWeight: "bold",
									color: (theme) => theme.palette.primary.main,
								}}
							>
								{`${name}${webhookToken ? " (tradingview)" : ""}`}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Chip
								component={(props) => <StrategyExchangeType {...props} value={platform?.exchange} />}
								variant="contained"
								backgroundColor={theme.palette.info.light}
								sx={{ fontSize: "12px", ml: "16px", borderRadius: "4px" }}
							/>
							<Chip
								variant="contained"
								backgroundColor={"#6A1FC2"}
								label={executionType}
								sx={{ fontSize: "12px", ml: "4px", borderRadius: "4px" }}
							/>
							{labels &&
								labels.map(
									(label, ix) =>
										label && (
											<Chip
												key={ix}
												variant="contained"
												backgroundColor={"#0F20E8"}
												label={label}
												sx={{ fontSize: "12px", ml: "4px", borderRadius: "4px" }}
											/>
										)
								)}
						</Grid>
					</Grid>
					<Grid item xs={2}>
						<Grid container>
							{parities &&
								parities.slice(0, 5).map(({ base }, ix) => (
									<Grid item key={ix}>
										<img
											style={{ marginRight: "-8px" }}
											width="24px"
											height="24px"
											src={`${Config.cdnRoot()}/general/crypto-icons/${base}.png`}
											alt="crpyto-icon"
										/>
									</Grid>
								))}
							{strategy?.parities?.symbols?.length > 5 ? (
								<Grid item>
									<Button
										sx={{
											mt: "-4px",
											ml: 2,
											fontWeight: "bold",
											paddingLeft: 0,
											paddingRight: 0,
											minWidth: "32px",
											"&:hover": {
												textDecoration: "underline",
											},
										}}
										onClick={() => setOpen(true)}
										variant="text"
									>{`+ ${strategy?.parities.symbols.length - 5}`}</Button>
								</Grid>
							) : (
								<></>
							)}
						</Grid>
						{strategy?.parities?.symbols?.length > 5 && (
							<Button
								sx={{
									fontSize: "14px",
									whiteSpace: "nowrap",
									"&:hover": {
										textDecoration: "underline",
									},
								}}
								onClick={() => setOpen(true)}
								variant="text"
							>
								{t("workshop_my_strategies_view_all_button")}
							</Button>
						)}
					</Grid>
					<Grid item xs={1.5}>
						<StrategyStatus isPublic={isPublic} id={id} />
					</Grid>
					<Grid item xs={1.8} sx={{ display: "flex", justifyContent: "center" }}>
						<Button
							variant="text"
							startIcon={<Edit />}
							onClick={() => {
								dispatch(setActiveStep(0));
								navigate(`/strategy-builder/${id}`);
							}}
						>
							{t("workshop_my_strategies_edit_button")}
						</Button>
					</Grid>
					<Grid item xs={0.7} sx={{ display: "flex", justifyContent: "center" }}>
						<IconButton
							onClick={() => {
								setSettingsOpen(true);
							}}
						>
							<SettingsBigger />
						</IconButton>
					</Grid>
					{settingsOpen && (
						<MyStrategiesDialog
							open={settingsOpen}
							strategy={strategy}
							onClose={() => {
								setSettingsOpen(false);
							}}
						/>
					)}
				</Grid>
			</Paper>
		</>
	);
};

export default function MyStrategies() {
	const { strategies } = useSelector((state) => state.strategyBuilder);

	return (
		<React.Fragment>
			{strategies ? (
				<>
					{strategies.map((strategy, ix) => (
						<StrategyCard key={ix} strategy={strategy} />
					))}
				</>
			) : (
				<CircularProgress />
			)}
		</React.Fragment>
	);
}
