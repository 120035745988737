import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Box, Button, Grid, Icon, List, ListItem, Paper, Typography } from "@mui/material";

import { Breadcrumbs, RouteLayout } from "components";

import { FollowerThin, RedMinus, TelegramTraderLogo } from "images";

export default function DeleteAccount() {
	const { t } = useTranslation("accountCenter");
	const navigate = useNavigate();

	const logos = [
		{ logo: TelegramTraderLogo, backgroundColor: "white" },
		{ logo: RedMinus, backgroundColor: "none", height: "100px" },
		{ logo: FollowerThin, backgroundColor: "white" },
	];

	const warningStatements = [
		t("account_center_settings_delete_flow_warning_statement_1"),
		t("account_center_settings_delete_flow_warning_statement_2"),
		t("account_center_settings_delete_flow_warning_statement_3"),
	];

	return (
		<RouteLayout
			headerComp={
				<Breadcrumbs
					paths={[
						{
							text: t("account_center_menu_main_title"),
							onClick: () => navigate("/account-center"),
						},
						{
							text: t("account_center_settings_main_title"),
							onClick: () => navigate("/settings"),
						},
						{
							text: t("account_center_settings_delete_account_title"),
						},
					]}
				/>
			}
		>
			<Grid container sx={{ mb: 2 }}>
				<Grid item xs={12}>
					<Paper sx={{ backgroundColor: "#FFFFFF", padding: 4 }}>
						<Grid container>
							<Grid item xs={12}>
								<Typography sx={{ mt: 2, fontSize: "24px", fontWeight: 700 }}>
									{t("account_center_settings_delete_flow_main_title")}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography sx={{ mt: 2, fontSize: "14px" }}>
									{t("account_center_settings_delete_flow_main_text")}
								</Typography>
							</Grid>
							<Paper
								sx={{
									mt: 2,
									backgroundColor: "#F4F5FC",
									width: "100%",
									padding: 5,
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<Typography sx={{ mt: 2, textAlign: "center", fontWeight: "bold" }}>
									{t("account_center_settings_delete_flow_warning_title")}
								</Typography>

								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center",
										mt: 3,
									}}
								>
									{logos.map((item, index) => (
										<Icon
											key={index}
											color="primary"
											sx={{
												width: 64,
												height: 64,
												backgroundColor: item.backgroundColor,
												borderRadius: "0.4rem",
												justifyContent: "center",
											}}
										>
											<Box sx={{ mt: "25%" }}>
												<item.logo />
											</Box>
										</Icon>
									))}
								</Box>

								<Typography sx={{ mt: 2, mb: 2, fontSize: 16, color: "#DE350B", fontWeight: "bold" }}>
									{t("account_center_settings_delete_flow_warning_subtitle")}
								</Typography>
								<Grid item xs={10} textAlign="left">
									<List sx={{ listStyleType: "disc", display: "column", alignSelf: "flex-end" }}>
										{warningStatements.map((statement, index) => (
											<ListItem key={index} sx={{ display: "list-item", fontSize: 14 }}>
												{statement}
											</ListItem>
										))}
									</List>
								</Grid>
							</Paper>
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs={8} sx={{ mt: 2 }}></Grid>
				<Grid item xs={4} sx={{ mt: 2 }}>
					<Button variant="contained" fullWidth onClick={() => navigate("/delete-account/delete-reason")}>
						{t("common:Next")}
					</Button>
				</Grid>
			</Grid>
		</RouteLayout>
	);
}
