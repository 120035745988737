import { abortController } from "services/AuthProvider";

export class ApiError extends Error {
	constructor(message) {
		super(message);
		this.name = "ApiError";
		this.message = message;
	}
}

export const errorHandler = async (res) => {
	if (res.status === 204) return;

	const json = await res.json();

	if (!res?.ok) {
		if (
			res?.status === 401 ||
			json?.message === "Unauthorized" ||
			(res?.status === 400 && json?.error?.code === 702 && json?.error.detail === "invalid token")
		) {
			setTimeout(() => {
				abortController.abort();
				localStorage.clear();
				return;
			}, 300);
		}

		if (json?.error?.code === "001" || json?.error?.code === "010") {
			throw new ApiError(json?.error?.detail);
		}

		throw new ApiError(`${json?.error?.code}.${json?.error?.detail}`);
	}

	return json;
};
