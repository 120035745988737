export const QuoteMap = {
	ETH: "Ethereum",
	USDT: "Tether",
	USD: "USD",
	BTC: "Bitcoin",
	TRY: "Turkish Lira",
};

export const MINIMUM_POSITION_SIZE = {
	USDT: 20,
	BTC: 0.001,
	USD: 20,
	TRY: 750,
};
