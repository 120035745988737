import React from "react";

import { useTheme } from "@mui/material/styles";

export default function ({ fill, width, height }) {
	const theme = useTheme();

	return (
		<svg
			width={width ? width : "16"}
			height={height ? height : "16"}
			viewBox="0 0 16 16"
			fill={fill ? fill : "none"}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.27695 11.7318C7.0404 11.9684 6.65687 11.9684 6.42032 11.7318L4.01549 9.32702C3.77894 9.09046 3.77894 8.70693 4.01549 8.47038L10.8549 1.63096C11.7533 0.732529 13.218 0.732529 14.1164 1.63096C15.0148 2.52941 15.0148 3.994 14.1164 4.89242L7.27695 11.7318ZM6.84863 10.4469L13.2597 4.03578C13.6851 3.61045 13.6851 2.91291 13.2598 2.4876C12.8344 2.06227 12.1369 2.06227 11.7115 2.4876L5.30045 8.8987L6.84863 10.4469Z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.27597 11.7279C7.03942 11.9645 6.65589 11.9645 6.41934 11.7279L4.01452 9.32311C3.77796 9.08656 3.77796 8.70303 4.01452 8.46647L10.0965 2.38452C10.333 2.14796 10.7166 2.14796 10.9531 2.38452L13.3579 4.78934C13.5945 5.02589 13.5945 5.40942 13.3579 5.64598L7.27597 11.7279ZM6.84766 10.443L12.073 5.21766L10.5248 3.66947L5.29947 8.89479L6.84766 10.443Z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.31223 8.30338C4.51425 8.25868 4.72511 8.32018 4.87141 8.46649L7.27391 10.869C7.42009 11.0152 7.48163 11.2258 7.43713 11.4277C7.39263 11.6296 7.24825 11.7948 7.05416 11.866L3.26842 13.2544C3.04707 13.3356 2.79873 13.281 2.63189 13.1144C2.46504 12.9478 2.41 12.6996 2.49082 12.4781L3.87406 8.68717C3.94498 8.4928 4.11021 8.34808 4.31223 8.30338ZM4.6865 9.99485L4.07606 11.6678L5.74677 11.0551L4.6865 9.99485Z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 4.95144C0 4.6169 0.271196 4.3457 0.605733 4.3457C2.81152 4.3457 4.87005 4.99518 6.59764 6.11206C6.87858 6.29369 6.95909 6.66867 6.77747 6.94961C6.59584 7.23055 6.22086 7.31106 5.93992 7.12944C4.56101 6.23797 2.94653 5.68128 1.21147 5.57557V14.7878H10.4238C10.311 12.9272 9.68216 11.2077 8.67464 9.77155C8.48251 9.49769 8.54877 9.11993 8.82264 8.9278C9.0965 8.73567 9.47426 8.80193 9.66639 9.0758C10.9217 10.8652 11.6535 13.0454 11.6535 15.3935C11.6535 15.7281 11.3823 15.9993 11.0478 15.9993H0.605733C0.271196 15.9993 0 15.7281 0 15.3935V4.95144Z"
			/>
		</svg>
	);
}
