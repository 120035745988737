import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Circle as CircleIcon } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";

import { ParityAnalytics } from "components/ParityAnalytics";

import { useMarketplaceApi } from "api/marketplace";

import PositionHistory from "../PositionHistory";
import ProfitBarChart from "../ProfitBarChart";
import { MarketStrategyContext } from "../Strategy";
import VolumeAreaChart from "../VolumeAreaChart";

export default function StrategyAnalytics() {
	const { t } = useTranslation("marketplace");
	const { marketStrategy } = useContext(MarketStrategyContext);
	const [weeklyUserData, setWeeklyUserData] = useState();
	const { getWeeklyUserBrief } = useMarketplaceApi();
	useEffect(() => {
		Promise.all([getWeeklyUserBrief(marketStrategy.id).then((data) => setWeeklyUserData(data.reverse()))]);
	}, []);
	const tabs = [
		{ key: "open", label: t("marketplace_strategy_page_position_ongoing_tab") },
		{ key: "closed", label: t("marketplace_strategy_page_position_completed_tab") },
		{ key: "parityBased", label: t("marketplace_strategy_page_position_parity_base_tab") },
		{ key: "profitChart", label: t("marketplace_user_stats_bar_chart_tab_title") },
		{ key: "volumeChart", label: t("marketplace_user_stats_area_line_chart_tab_title") },
	];
	const [openTab, setOpenTab] = useState("open");
	const handleTabChange = (tab) => {
		setOpenTab(tab);
	};

	let renderComponent;

	switch (openTab) {
		case "open":
		case "closed":
			renderComponent = <PositionHistory type={openTab} />;
			break;
		case "parityBased":
			renderComponent = <ParityAnalytics marketStrategyId={marketStrategy.id}></ParityAnalytics>;
			break;
		case "profitChart":
			renderComponent = <ProfitBarChart data={weeklyUserData}></ProfitBarChart>;
			break;
		case "volumeChart":
			renderComponent = <VolumeAreaChart data={weeklyUserData}></VolumeAreaChart>;
			break;
		default:
			renderComponent = null;
	}

	return (
		<>
			<Grid container>
				<Grid item xs={12} container direction={"row"}>
					{tabs.map((tab) => (
						<Grid key={tab.key} item>
							<Button
								size="large"
								sx={{
									fontSize: "1.25rem",
									...(tab.key !== openTab ? { color: "#AEAEAE" } : {}),
								}}
								startIcon={tab.key === openTab ? <CircleIcon sx={{ width: 8, height: 8 }} /> : <></>}
								onClick={() => handleTabChange(tab.key)}
							>
								{tab.label}
							</Button>
						</Grid>
					))}
				</Grid>
				<Grid item xs={12} container direction={"row"}>
					{renderComponent}
				</Grid>
			</Grid>
		</>
	);
}
