export default function ({ color }) {
	return (
		<svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M17.9289 0.247828C18.1921 0.549104 18.1612 1.00667 17.8599 1.26983L1.2008 15.8212C0.986839 16.0081 0.683378 16.0526 0.424728 15.9351C0.166078 15.8176 0 15.5598 0 15.2757V7.35175C0 6.95172 0.324284 6.62744 0.724308 6.62744C1.12433 6.62744 1.44862 6.95172 1.44862 7.35175V13.6813L16.9069 0.17881C17.2082 -0.0843483 17.6657 -0.0534478 17.9289 0.247828Z"
				fill={color ?? "white"}
			/>
		</svg>
	);
}
