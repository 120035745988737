import React from "react";

import { useTheme } from "@mui/material/styles";

export default function ({ active, width, height }) {
	const theme = useTheme();

	const color = active ? theme.palette.primary.main : theme.palette.info.dark;

	return (
		<svg
			width={width ?? "28"}
			height={height ?? "28"}
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 18C0 8.06206 8.06206 0 18 0H35.1529C35.6208 0 36 0.379241 36 0.847059C36 10.785 27.9379 18.8471 18 18.8471H0.847059C0.379241 18.8471 0 18.4678 0 18ZM1.71576 17.1529H18C26.7182 17.1529 33.8432 10.3031 34.2842 1.69412H18C9.2818 1.69412 2.1568 8.54399 1.71576 17.1529Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.1533 18.0004C17.1533 17.5326 17.5326 17.1533 18.0004 17.1533H35.1533C35.6211 17.1533 36.0004 17.5326 36.0004 18.0004C36.0004 27.9383 27.9383 36.0004 18.0004 36.0004C17.5326 36.0004 17.1533 35.6211 17.1533 35.1533V18.0004ZM18.8474 18.8474V34.2846C27.1758 33.858 33.858 27.1758 34.2846 18.8474H18.8474Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 18.0004C0 17.5326 0.379241 17.1533 0.847059 17.1533H18C18.4678 17.1533 18.8471 17.5326 18.8471 18.0004V35.1533C18.8471 35.6211 18.4678 36.0004 18 36.0004C8.06206 36.0004 0 27.9383 0 18.0004ZM1.71576 18.8474C2.14242 27.1758 8.82453 33.858 17.1529 34.2846V18.8474H1.71576Z"
				fill={color}
			/>
			<path
				d="M21.6596 12.2314C22.8151 12.2314 23.7519 11.2946 23.7519 10.1391C23.7519 8.98358 22.8151 8.04688 21.6596 8.04688C20.5041 8.04688 19.5674 8.98358 19.5674 10.1391C19.5674 11.2946 20.5041 12.2314 21.6596 12.2314Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.659 8.89431C20.9713 8.89431 20.4138 9.45177 20.4138 10.1395C20.4138 10.8272 20.9714 11.3847 21.659 11.3847C22.3467 11.3847 22.9042 10.8272 22.9042 10.1395C22.9042 9.4518 22.3467 8.89431 21.659 8.89431ZM18.7197 10.1395C18.7197 8.51614 20.0357 7.2002 21.659 7.2002C23.2823 7.2002 24.5983 8.51611 24.5983 10.1395C24.5983 11.7628 23.2824 13.0788 21.659 13.0788C20.0356 13.0788 18.7197 11.7627 18.7197 10.1395Z"
				fill={color}
			/>
			<path
				d="M13.392 12.2314C14.5475 12.2314 15.4843 11.2946 15.4843 10.1391C15.4843 8.98358 14.5475 8.04688 13.392 8.04688C12.2365 8.04688 11.2998 8.98358 11.2998 10.1391C11.2998 11.2946 12.2365 12.2314 13.392 12.2314Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.3924 8.89431C12.7047 8.89431 12.1472 9.45177 12.1472 10.1395C12.1472 10.8272 12.7048 11.3847 13.3924 11.3847C14.0801 11.3847 14.6376 10.8272 14.6376 10.1395C14.6376 9.4518 14.0801 8.89431 13.3924 8.89431ZM10.4531 10.1395C10.4531 8.51614 11.7691 7.2002 13.3924 7.2002C15.0157 7.2002 16.3317 8.51611 16.3317 10.1395C16.3317 11.7628 15.0157 13.0788 13.3924 13.0788C11.769 13.0788 10.4531 11.7627 10.4531 10.1395Z"
				fill={color}
			/>
			<path
				d="M17.9996 28.148C20.5408 28.148 22.6076 26.0812 22.6076 23.54H13.3916C13.3916 26.0812 15.4584 28.148 17.9996 28.148Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5449 23.5394C12.5449 23.0716 12.9242 22.6924 13.392 22.6924H22.608C23.0758 22.6924 23.455 23.0716 23.455 23.5394C23.455 26.5484 21.009 28.9944 18 28.9944C14.991 28.9944 12.5449 26.5484 12.5449 23.5394ZM18 27.3003C19.7822 27.3003 21.2793 26.0535 21.6647 24.3865H14.3353C14.7206 26.0535 16.2178 27.3003 18 27.3003Z"
				fill={color}
			/>
		</svg>
	);
}
