import React from "react";

export default function LeaderboardArrow({ down, style }) {
	return (
		<svg
			width="11"
			height="12"
			viewBox="0 0 11 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				...style,
				...(down ? { transform: "rotate(-180deg)" } : {}),
			}}
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M10.5443 6.32022C10.0678 6.79528 9.29648 6.79416 8.82141 6.31771L5.45554 2.94204L2.07873 6.31895C1.60298 6.79471 0.83163 6.79473 0.355866 6.31898C-0.119896 5.84323 -0.119908 5.07188 0.35584 4.59612L4.59534 0.356492C4.82402 0.127804 5.13425 -0.000569848 5.45766 -0.000336646C5.78106 -0.000104398 6.09111 0.128715 6.31946 0.357731L10.5468 4.59736C11.0218 5.0738 11.0207 5.84515 10.5443 6.32022Z"
				fill={down ? "#DE350C" : "#36B37E"}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M5.45753 12C4.78472 12 4.23929 11.4546 4.23929 10.7818L4.23929 1.2184C4.23929 0.545576 4.78472 0.000148608 5.45754 0.000148667C6.13035 0.000148726 6.67578 0.545576 6.67578 1.2184L6.67578 10.7818C6.67578 11.4546 6.13035 12 5.45753 12Z"
				fill={down ? "#DE350C" : "#36B37E"}
			/>
		</svg>
	);
}
