import React from "react";
import { useSelector } from "react-redux";

export default function Layout({ children }) {
	const { jwt: selectedJWT } = useSelector((state) => state.jwt);

	return (
		<div
			style={
				!selectedJWT
					? {
							marginLeft: "5vw",
					  }
					: {}
			}
		>
			{children}
		</div>
	);
}
