import { useTranslation } from "react-i18next";

import { Box, Button, Typography } from "@mui/material";

import { CartCheck, StepperEye } from "images";

import Dots from "./Dots";

const RegisterChecklist = ({ checklist, eligibityStatus, completedList }) => {
	const { t, i18n } = useTranslation("questBox");
	return (
		<Box marginTop={9}>
			<Typography fontWeight={700} gutterBottom>
				{t("quest_box_campaign_sign_up_date_title")}
			</Typography>

			{checklist.map((step, index) => (
				<Box display="flex" flexDirection="column" justifyContent="flex-start">
					<Box display="flex">
						{completedList?.[index].completed ? (
							<CartCheck />
						) : eligibityStatus?.[index].completed ? (
							<CartCheck />
						) : (
							<StepperEye />
						)}

						<Typography color="primary" fontSize={12} marginX={2}>
							{step.description?.[i18n.resolvedLanguage]}
						</Typography>
					</Box>
					{eligibityStatus !== undefined &&
						eligibityStatus?.[index].completed === false &&
						step.direction && (
							<Button
								sx={{ mt: 1, alignSelf: "start", textDecoration: "underline" }}
								variant="text"
								onClick={() => window.location.replace(step.direction)}
							>
								{t("quest_box_campaign_button_text_complete_now")}
							</Button>
						)}
					{index + 1 !== checklist.length && (
						<Box display="flex" flexDirection="column" gap={1} marginY={1} marginX={1}>
							<Dots />
							<Dots />
							<Dots />
						</Box>
					)}
				</Box>
			))}
		</Box>
	);
};

export default RegisterChecklist;
