import { useTheme } from "@mui/material/styles";

export default function ({ active }) {
	const theme = useTheme();

	const color = active ? theme.palette.primary.main : theme.palette.primary.light;

	return (
		<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0.669922 1.67973C0.669922 1.07942 1.15657 0.592773 1.75688 0.592773C6.66522 0.592773 11.2131 2.13607 14.9416 4.76389L13.4999 6.40919C10.4405 4.30378 6.78786 2.99832 2.84383 2.79468V22.4189H22.468C22.2377 17.9587 20.5984 13.8711 17.9865 10.5928L19.4999 9.00664C22.7313 12.9563 24.6699 18.0046 24.6699 23.5058C24.6699 24.1061 24.1833 24.5928 23.583 24.5928H1.75688C1.15657 24.5928 0.669922 24.1061 0.669922 23.5058V1.67973Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M16.6261 4.94051C15.3834 4.94051 14.3761 5.94788 14.3761 7.19052C14.3761 8.43316 15.3834 9.44053 16.6261 9.44053C17.8687 9.44053 18.8761 8.43316 18.8761 7.19052C18.8761 5.94788 17.8687 4.94051 16.6261 4.94051ZM12.2021 7.19052C12.2021 4.74726 14.1828 2.7666 16.6261 2.7666C19.0693 2.7666 21.05 4.74726 21.05 7.19052C21.05 9.63378 19.0693 11.6144 16.6261 11.6144C14.1828 11.6144 12.2021 9.63378 12.2021 7.19052Z"
				fill={color}
			/>
			<path
				d="M15.213 23.506V20.169C15.213 18.332 13.7238 16.832 11.876 16.832C10.0282 16.832 8.53906 18.3212 8.53906 20.169V23.506H15.213Z"
				fill="#F1F1F5"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M7.45215 20.169C7.45215 17.7209 9.42793 15.7451 11.8761 15.7451C14.3265 15.7451 16.3 17.7341 16.3 20.169V23.506H15.213V24.593H8.5391C7.9388 24.593 7.45215 24.1063 7.45215 23.506V20.169ZM14.1261 22.419V20.169C14.1261 18.9301 13.1213 17.919 11.8761 17.919C10.6286 17.919 9.62606 18.9215 9.62606 20.169V22.419H14.1261Z"
				fill={color}
			/>
		</svg>
	);
}
