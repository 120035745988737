export default function Security({ width, height, color }) {
	return (
		<svg
			width={width ?? "45"}
			height={height ?? "50"}
			viewBox="0 0 45 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M33.8261 21.8494V12.6293C33.8261 6.21236 28.6137 1 22.1967 1C15.7797 1 10.5674 6.21236 10.5674 12.6293V21.8494"
				stroke={color ?? "white"}
				stroke-width="4"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M34.1042 21.8496H10.1969C5.1176 21.8496 1 25.9672 1 31.0465V39.8033C1 44.8826 5.1176 49.0002 10.1969 49.0002H34.1042C39.1836 49.0002 43.3012 44.8826 43.3012 39.8033V31.0465C43.3012 25.9672 39.1836 21.8496 34.1042 21.8496Z"
				stroke={color ?? "white"}
				stroke-width="4"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
