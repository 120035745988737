import { Grid, Typography } from "@mui/material";

import { SpotDown, SpotUp } from "images";

export default function PercentChange({ value }) {
	value = parseFloat(value);
	return (
		<Grid item>
			<Typography
				variant="subtitle1"
				sx={{
					fontSize: "1.15rem",
					color: (theme) =>
						value < 0
							? theme.palette.danger.main
							: value === 0
							? theme.palette.primary.main
							: theme.palette.success.main,
				}}
			>
				{`${value}%`}
				{value < 0 ? <SpotDown /> : value === 0 ? "" : <SpotUp />}
			</Typography>
		</Grid>
	);
}
