import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, DialogContent, Grid, List, Typography } from "@mui/material";

import { usePlatformApi } from "api/platform";

import { setPlatforms } from "actions/PlatformActions";

import { useFetchAuthorized } from "services";
import Config from "services/config";

import PlatformItem from "../presets/PlatformItem";
import { StrategyContext } from "./MyStrategiesDialog";
import { SUCCESS } from "./utils/constant";

const DuplicateStrategy = ({ contentProps, strategy }) => {
	const { setSelection, setSuccessMessage } = useContext(StrategyContext);
	const { platforms } = useSelector((state) => state.platform);
	const [chosenPlatform, setChosenPlatform] = useState(
		strategy?.platformId && platforms?.find((x) => x.id === strategy?.platformId)
	);

	const { t } = useTranslation("workshop");
	const fetchAuthorized = useFetchAuthorized();
	const dispatch = useDispatch();
	const { getPlatforms } = usePlatformApi();

	useEffect(() => {
		if (!platforms) {
			getPlatforms().then((data) => {
				dispatch(setPlatforms(data?.data?.platforms));
			});
		}
	}, [dispatch, getPlatforms, platforms]);

	const duplicateStrategy = () => {
		fetchAuthorized(`${Config.apiRoot()}/strategy/strategy/${strategy?.id}/duplicate`, {
			headers: {
				"Content-type": "application/json",
			},
			method: "POST",
			body: JSON.stringify({ platformId: chosenPlatform?.id }),
		}).then((_) => {
			setSuccessMessage({
				title: t("workshop_actions_duplicate_flow_success_text"),
			});

			setSelection(SUCCESS);
		});
	};

	return (
		<DialogContent sx={{ paddingTop: "10vh !important" }} {...contentProps}>
			<Grid container spacing={2} direction="column" alignItems="stretch" justifyContent="center">
				<Grid item xs={12}>
					<Typography fontWeight={"bold"} sx={{ fontSize: "24px", textAlign: "center" }}>
						{t("workshop_actions_duplicate_main_title")}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography sx={{ fontSize: "14px", textAlign: "center" }}>
						{t("workshop_actions_duplicate_main_text")}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<List>
						{platforms &&
							platforms
								.filter((x) => x.info?.trading && x.info?.strategyBuilding)
								.map((platform, ix) => (
									<PlatformItem
										key={ix}
										platform={platform}
										checked={chosenPlatform?.id === platform.id}
										strategy={strategy}
										onChange={() => setChosenPlatform(platform)}
									/>
								))}
					</List>
				</Grid>
			</Grid>
			<Grid container spacing={2} sx={{ paddingTop: "3vh" }}>
				<Grid item xs={12}>
					<Button fullWidth variant="contained" onClick={duplicateStrategy}>
						{t("workshop_actions_button_next")}
					</Button>
				</Grid>
			</Grid>
		</DialogContent>
	);
};

export default DuplicateStrategy;
