import { useTheme } from "@mui/material/styles";

export default function ({ active, disabled }) {
	const theme = useTheme();

	const color = disabled
		? theme.palette.info.dark
		: active
		? theme.palette.primary.main
		: theme.palette.primary.light;

	return (
		<svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M21.4365 1.29883C21.861 1.72332 21.861 2.41154 21.4365 2.83603L14.4877 9.78486L21.4365 16.7337C21.861 17.1582 21.861 17.8464 21.4365 18.2709C21.012 18.6954 20.3238 18.6954 19.8993 18.2709L12.9505 11.3221L6.00172 18.2709C5.79787 18.4747 5.5214 18.5892 5.23312 18.5892C4.94484 18.5892 4.66836 18.4747 4.46452 18.2709C-0.220856 13.5855 -0.220856 5.98421 4.46452 1.29883C4.66836 1.09499 4.94484 0.980468 5.23312 0.980468C5.5214 0.980468 5.79787 1.09499 6.00172 1.29883L12.9505 8.24766L19.8993 1.29883C20.3238 0.874348 21.012 0.874347 21.4365 1.29883ZM11.4133 9.78486L5.27518 3.64669C2.40749 7.22428 2.40749 12.3454 5.27518 15.923L11.4133 9.78486Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M21.4362 1.29883C26.1215 5.98421 26.1215 13.5855 21.4362 18.2709C21.2323 18.4747 20.9558 18.5892 20.6676 18.5892C20.3793 18.5892 20.1028 18.4747 19.899 18.2709L12.9502 11.3221L6.00136 18.2709C5.57687 18.6954 4.88865 18.6954 4.46416 18.2709C4.03968 17.8464 4.03968 17.1582 4.46416 16.7337L11.413 9.78486L4.46416 2.83603C4.03968 2.41154 4.03968 1.72331 4.46416 1.29883C4.88865 0.874346 5.57688 0.874347 6.00136 1.29883L12.9502 8.24766L19.899 1.29883C20.1028 1.09499 20.3793 0.980469 20.6676 0.980469C20.9558 0.980468 21.2323 1.09499 21.4362 1.29883ZM14.4874 9.78486L20.6255 15.923C23.4932 12.3454 23.4932 7.22428 20.6255 3.6467L14.4874 9.78486Z"
				fill={color}
			/>
		</svg>
	);
}
