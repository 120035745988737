import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Button, Grid, Table, TableContainer, Typography } from "@mui/material";

import { CustomTableHead, RobotDialog, RouteLayout } from "components";

import { useDashboardApi } from "api/dashboard";
import { usePlatformApi } from "api/platform";
import { useStrategyApi } from "api/strategy";
import useCatchError from "api/useCatchError";

import { setConfig } from "actions/ConfigActions";
import { setParities } from "actions/ParityActions";
import { setStrategies } from "actions/StrategyActions";

import useQuery from "utils/useQuery";

import { FollowNoStrategy, SignalVirtual, Virtual } from "images";

import { STRATEGIES_HEAD_CELLS } from "./Constants";
import StrategyTable from "./StrategyTable";
import { Filter } from "./filter";

export const StrategiesContext = createContext();

const omitFields = {
	own: ["head-cell-expert"],
	archived: ["head-cell-expert", "settings"],
	market: [],
};

export default function Strategies() {
	const { strategies } = useSelector((state) => state.strategy);
	const { config } = useSelector((state) => state.config);

	const { profile } = useSelector((state) => state.user);

	const [open, setOpen] = useState(false);
	const [portfolio, setPortfolio] = useState(false);
	const [selectedStrategyFollower, setSelectedStrategyFollower] = useState();
	const [showOwnStrategies, setShowOwnStrategies] = useState(false);
	const [showMarketplaceDirection, setShowMarketplaceDirection] = useState(false);

	const [processing, setProcessing] = useState(false);
	const [archivedShown, setArchivedShown] = useState(false);
	const [order, setOrder] = useState();

	const dispatch = useDispatch();
	const { t } = useTranslation("controlPanel");
	const query = useQuery();
	const navigate = useNavigate();

	const { getFollowedStrategies, getArchive } = useStrategyApi();
	const { getParities } = usePlatformApi();
	const { getPortfolio, getConfig } = useDashboardApi();
	const catchError = useCatchError();

	useEffect(() => {
		getConfig("MINIMUM_QUOTE_AMOUNTS")
			.then((data) => {
				dispatch(setConfig({ ...config, MINIMUM_QUOTE_AMOUNTS: data?.data }));
			})
			.catch(catchError);
	}, []);

	useEffect(() => {
		setProcessing(true);

		Promise.all([
			getParities().then((data) => dispatch(setParities(data))),
			getPortfolio().then((data) => setPortfolio(data)),
			getArchive().then((data) => {
				if (data.length > 0) setArchivedShown(true);
				if (query.get("archived")) dispatch(setStrategies(data));
			}),
			getFollowedStrategies().then((data) => {
				const ownStrategies = data.filter((strat) => strat.strategy.user.id === profile?.id);
				if (ownStrategies?.length > 0) setShowOwnStrategies(true);
				if (query.get("own")) dispatch(setStrategies(ownStrategies));
				const marketPlaceStrategies = data.filter((strat) => strat.strategy.user.id !== profile?.id);
				if (!marketPlaceStrategies?.length) setShowMarketplaceDirection(true);
				if (!query.get("archived") && !query.get("own")) dispatch(setStrategies(marketPlaceStrategies));
			}),
		]).finally(() => {
			setProcessing(false);
		});
	}, [query]);

	const handleOrderChange = ({ orderBy, orderDirection }) => {
		if (query.get("archived")) {
			getArchive(null, orderBy, orderDirection).then((data) => {
				dispatch(setStrategies(data));
			});
		} else {
			getFollowedStrategies(null, orderBy, orderDirection).then((data) => {
				dispatch(setStrategies(data));
			});
		}
		setOrder({ [orderBy]: orderDirection });
	};

	const isArchived = query.get("archived");
	const isOwn = query.get("own");
	const isMarket = !isArchived && !isOwn;

	return (
		<RouteLayout header={t("control_panel_closed_robots_title")}>
			<Filter archivedShown={archivedShown} showOwnStrategies={showOwnStrategies} />
			<TableContainer>
				<Table
					sx={{
						minWidth: 750,
						borderCollapse: "separate",
						borderSpacing: "0 0.75rem",
					}}
				>
					{/* kendime not, burası order kısmında settings ve archived de order etmemek iç  */}
					<CustomTableHead
						headCells={STRATEGIES_HEAD_CELLS(t, handleOrderChange).filter(
							(cell) =>
								(isArchived && !omitFields.archived.includes(cell.id)) ||
								(isOwn && !omitFields.own.includes(cell.id)) ||
								(isMarket && !omitFields.market.includes(cell.id))
						)}
						onOrderChange={handleOrderChange}
						order={order}
					/>
					<StrategyTable
						strategies={strategies}
						setOpen={setOpen}
						setSelectedStrategyFollower={setSelectedStrategyFollower}
					/>
				</Table>

				{open && (
					<RobotDialog
						selectedStrategyFollower={selectedStrategyFollower}
						setSelectedStrategyFollower={setSelectedStrategyFollower}
						portfolio={portfolio}
						open={open}
						onClose={() => {
							setOpen();
							setSelectedStrategyFollower();
						}}
						onSave={(_) => {
							setOpen(false);
							setSelectedStrategyFollower();
						}}
					/>
				)}
			</TableContainer>

			{showMarketplaceDirection && !query.get("own") && !query.get("archived") ? (
				<Box
					width="100%"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					sx={{ borderRadius: "8px", backgroundColor: "#fff", py: "50px" }}
				>
					<Box>
						<FollowNoStrategy />
					</Box>
					<Typography color="primary" fontSize={24} fontWeight={700} mt={4}>
						{t("control_panel_no_robots_followed_text")}
					</Typography>
					<Button
						onClick={() => navigate("/marketplace")}
						variant="contained"
						sx={{
							marginTop: "1rem",
							width: "360px",
							height: "42px",
						}}
					>
						{t("control_panel_no_robots_followed_button_text")}
					</Button>
				</Box>
			) : (
				<></>
			)}

			{strategies?.length ? (
				<Grid container>
					<Grid xs={9}></Grid>
					<Grid xs={0.3}>
						<SignalVirtual />
					</Grid>
					<Grid xs={1.2}>
						<Typography sx={{ color: (theme) => theme.palette.primary.main, fontSize: "14px" }}>
							{t("control_panel_robots_help_virtual_signal")}
						</Typography>
					</Grid>
					<Grid xs={0.3}>
						<Virtual width={24} height={24} />
					</Grid>
					<Grid xs={1.2}>
						<Typography sx={{ color: (theme) => theme.palette.primary.main, fontSize: "14px" }}>
							{t("control_panel_robots_help_virtual_trade")}
						</Typography>
					</Grid>
				</Grid>
			) : (
				<></>
			)}
		</RouteLayout>
	);
}
