import { Grid, Typography } from "@mui/material";

import { SpotDown, SpotUp } from "images";

export default function Profit({ profit }) {
	return (
		<Grid container>
			<Grid item>
				<Typography
					sx={{
						...(profit !== undefined &&
							profit !== null && {
								color: profit === 0 ? "#CFD2FA" : profit < 0 ? "#DE350B" : "#36B37E",
							}),
					}}
				>
					{`${
						profit !== undefined && profit !== null
							? profit === 0
								? "0"
								: profit < 0
								? "-" + parseFloat(profit.toString().substring(1)).toFixed(2) + "%"
								: profit.toFixed(2) + "%"
							: "-"
					}`}
				</Typography>
			</Grid>
			<Grid item>{profit ? profit < 0 ? <SpotDown /> : <SpotUp /> : <></>}</Grid>
		</Grid>
	);
}
