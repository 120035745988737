import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";

import dayjs from "dayjs";

export default function Disclaimer({ campaign, width }) {
	const { maxAttendeeCount, startDate, endDate, termsConditionsUrl } = campaign ?? {};

	const { t } = useTranslation("questBox");

	return (
		<Typography width={width} fontSize={12} variant="h5" textAlign="center">
			{t("quest_box_campaign_disclaimer_text_1", {
				max_attendee_count: maxAttendeeCount,
				start_date: dayjs(startDate).format("MMMM D, YYYY"),
				end_date: dayjs(endDate).format("MMMM D, YYYY"),
			})}
			<Typography
				color="primary"
				sx={{ cursor: "pointer" }}
				fontSize={12}
				variant="span"
				marginX={1}
				{...(termsConditionsUrl && {
					onClick: () => window.open(termsConditionsUrl, "_blank").focus(),
				})}
			>
				{t("quest_box_campaign_disclaimer_text_2")}
			</Typography>
			{t("quest_box_campaign_disclaimer_text_3")}
		</Typography>
	);
}
