import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, Grid, Paper, Typography, useMediaQuery } from "@mui/material";

import { Dialog } from "components";

import { InfoWarning, InfoWarningGrey, WarningGrey } from "images";

export default function WithdrawDisabled({ type }) {
	const { t } = useTranslation("wallet");

	const navigate = useNavigate();

	const inMobileKycMessage = useMediaQuery("(max-width:900px)", { noSsr: true });

	const [kycNeededDialog, setKycNeededDialog] = useState(false);

	const openKycNeededDialog = () => setKycNeededDialog(true);
	const closeKycNeededDialog = () => setKycNeededDialog(false);

	const typeTitles = (t) => ({
		KYC_REQUIRED: t("wallet_no_kyc_warning_title"),
		BALANCE_TOO_LOW: t("wallet_not_enough_real_credits_warning_title"),
		PENDING_WITHDRAW: t("wallet_withdrawal_pending_request_title"),
		BOTH_ON_MAINTENANCE: t("wallet_maintenance_both_disabled_title"),
		ONLY_WITHDRAW_MAINTENANCE: t("wallet_maintenance_withdraw_disabled_title"),
		ONLY_DEPOSIT_MAINTENANCE: t("wallet_maintenance_deposit_disabled_title"),
	});
	const typeTexts = (t) => ({
		BALANCE_TOO_LOW: t("wallet_not_enough_real_credits_warning_text"),
		PENDING_WITHDRAW: t("wallet_withdrawal_pending_request_text"),
		BOTH_ON_MAINTENANCE: t("wallet_maintenance_deposit_disabled_text"),
		ONLY_WITHDRAW_MAINTENANCE: t("wallet_maintenance_both_disabled_text"),
		ONLY_DEPOSIT_MAINTENANCE: t("wallet_maintenance_withdraw_disabled_text"),
	});

	const KycNeededDialog = ({ open, onClose }) => (
		<Dialog
			dialogProps={{ open, onClose }}
			title={<WarningGrey />}
			content={
				<Paper
					sx={{
						boxShadow: 0,
						backgroundColor: "#FFFFFF",
						padding: 2,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						textAlign: "center",
					}}
				>
					<Typography fontWeight={"Bold"} sx={{ fontSize: "24px" }}>
						{t("wallet_withdrawal_kyc_needed_modal_title")}
					</Typography>
					<Typography sx={{ whiteSpace: "pre-line", mt: 2, fontSize: "14px" }}>
						{t("wallet_withdrawal_kyc_needed_modal_text")}
					</Typography>
					<Button
						variant="contained"
						sx={{ width: 300, mt: 2, fontWeight: 400 }}
						color="primary"
						onClick={() => navigate("/security")}
					>
						{t("wallet_withdrawal_kyc_needed_modal_navigate_text")}
					</Button>
					<Button
						variant="outlined"
						sx={{ width: 300, mt: 2 }}
						color="primary"
						onClick={() => closeKycNeededDialog()}
					>
						{t("wallet_deposit_transfer_success_close_button_text")}
					</Button>
				</Paper>
			}
		></Dialog>
	);

	return (
		<>
			{type ? (
				<Paper
					sx={{
						mt: 1,
						p: 2,
						borderRadius: "8px",
						backgroundColor: (theme) => theme.palette.info.main,
					}}
				>
					{type === "BOTH_ON_MAINTENANCE" ? (
						<></>
					) : type === "ONLY_DEPOSIT_MAINTENANCE" ? (
						<Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
							{t("wallet_info_card_deposit_title")}
						</Typography>
					) : (
						<Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
							{t("wallet_info_card_withdraw_title")}
						</Typography>
					)}
					<Grid
						container
						sx={{ display: "flex", alignItems: "center", mt: type === "BOTH_ON_MAINTENANCE" ? 0 : 1 }}
					>
						<Grid item xs={0.8}>
							{type === "BOTH_ON_MAINTENANCE" ? (
								<></>
							) : type === "ONLY_WITHDRAW_MAINTENANCE" || type === "ONLY_DEPOSIT_MAINTENANCE" ? (
								<InfoWarningGrey />
							) : (
								<InfoWarning />
							)}
						</Grid>
						<Grid item xs={type === "BOTH_ON_MAINTENANCE" ? 12 : 11.2}>
							<Typography
								sx={{
									mb: 0.3,
									fontSize: type === "BOTH_ON_MAINTENANCE" ? "16px" : "14px",
									alignItems: "center",
									fontWeight: type === "BOTH_ON_MAINTENANCE" ? 700 : 400,
									color: (theme) =>
										type === "PENDING_WITHDRAW"
											? theme.palette.secondary.main
											: type === "ONLY_WITHDRAW_MAINTENANCE" ||
											  type === "ONLY_DEPOSIT_MAINTENANCE"
											? theme.palette.info.dark
											: theme.palette.primary.main,
								}}
							>
								{typeTitles(t)[type]}
							</Typography>
						</Grid>
					</Grid>
					{type === "KYC_REQUIRED" && (
						<Typography sx={{ fontSize: "14px", mt: 0.5 }}>
							{inMobileKycMessage
								? t("wallet_no_kyc_warning_text_mobile")
								: t("wallet_no_kyc_warning_text_1")}
							<br />
							<Typography
								component="span"
								onClick={() => openKycNeededDialog()}
								onClose={() => closeKycNeededDialog()}
								sx={{
									cursor: "pointer",
									fontSize: "14px",
									color: (theme) => theme.palette.primary.main,
									textDecoration: "underline",
									fontWeight: 700,
								}}
							>
								{t("wallet_no_kyc_warning_text_2")}
							</Typography>
							<KycNeededDialog open={kycNeededDialog} onClose={closeKycNeededDialog} />
						</Typography>
					)}

					<Typography sx={{ fontSize: "14px" }}>{typeTexts(t)[type]}</Typography>
				</Paper>
			) : (
				<> </>
			)}
		</>
	);
}
