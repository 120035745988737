import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, DialogContent, Grid, Icon, List, ListItem, Paper, Typography, styled } from "@mui/material";

import { PageCenteredProgress } from "components";

import { useFetchAuthorized } from "services";
import Config from "services/config";

import { FollowerThin, RedMinus, UriDefault } from "images";
import Security from "images/Security";

import { StrategyContext } from "./MyStrategiesDialog";
import { SUCCESS } from "./utils/constant";

const DeleteStrategy = ({ contentProps, strategy }) => {
	const { setSelection, setSuccessMessage } = useContext(StrategyContext);

	const { t } = useTranslation("workshop");

	const [processing, setProcessing] = useState(false);

	const fetchAuthorized = useFetchAuthorized();

	const deleteStrategy = () => {
		setProcessing(true);

		fetchAuthorized(`${Config.apiRoot()}/strategy/strategy/${strategy.id}/delete`, {
			headers: {
				"Content-type": "application/json",
			},
			method: "PATCH",
		})
			.then(() => {
				setProcessing(false);
			})
			.then((_) => {
				setSuccessMessage({
					title: t("workshop_actions_delete_flow_success_text"),
				});

				setSelection(SUCCESS);
			});
	};

	const logos = [
		{ logo: UriDefault, backgroundColor: "white" },
		{ logo: RedMinus, height: "100px" },
		{ logo: FollowerThin, backgroundColor: "white" },
	];

	const RedButton = styled(Button)(({ theme }) => ({
		height: 50,
		backgroundColor: theme.palette.danger.main,
		"&:hover": {
			backgroundColor: theme.palette.danger.hover,
		},
	}));

	return (
		<>
			{processing && <PageCenteredProgress />}

			<DialogContent sx={{ paddingTop: "10vh !important" }} {...contentProps}>
				<Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
					<Grid item xs={12}>
						<Typography fontWeight={"bold"} sx={{ fontSize: "24px", textAlign: "center" }}>
							{t("workshop_actions_delete_flow_main_title")}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography sx={{ fontSize: "14px", textAlign: "center" }}>
							{t("workshop_actions_delete_flow_main_text")}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Paper
							sx={{
								mt: 2,
								backgroundColor: (theme) => theme.palette.info.light,
								padding: 2,
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
									mb: 2,
								}}
							>
								{logos.map((item, index) => (
									<Icon
										key={index}
										color="primary"
										sx={{
											width: 50,
											height: 50,
											backgroundColor: item.backgroundColor,
											borderRadius: "0.4rem",
											justifyContent: "center",
										}}
									>
										<Box sx={{ mt: "25%" }}>
											<item.logo />
										</Box>
									</Icon>
								))}
							</Box>

							<List
								sx={{
									pl: 1,
									"& .MuiListItem-root": {
										display: "list-item",
									},
									listStyleType: "disc",

									fontSize: "14px",
								}}
							>
								{Array.from({ length: 4 }, (_, i) => i + 1).map((number) => {
									const translationKey = `account_center_settings_delete_flow_reason_${number}`;
									return (
										<ListItem key={translationKey} value={translationKey}>
											{t(`workshop_actions_delete_flow_warning_statements_line_${number}`)}
										</ListItem>
									);
								})}
							</List>
						</Paper>
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{ paddingTop: "3vh" }}>
					<Grid item xs={12}>
						<RedButton
							fullWidth
							variant="contained"
							startIcon={<Security height={16} width={14.27} />}
							onClick={deleteStrategy}
						>
							{t("workshop_actions_delete_flow_confirm_button")}
						</RedButton>
					</Grid>
				</Grid>
			</DialogContent>
		</>
	);
};

export default DeleteStrategy;
