import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
	Button,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";

import { Dialog } from "components";

import { TierReward as TierRewardIcon } from "images";

import Disclaimer from "./Disclaimer";

const RewardDialog = ({ campaign, open, onClose }) => {
	const { t } = useTranslation("questBox");

	const { leaderboardInfo } = campaign ?? {};

	return (
		<Dialog
			dialogProps={{ open, maxWidth: "md", onClose }}
			title={
				<Grid container spacing={2}>
					<Grid item>
						<TierRewardIcon />
					</Grid>
					<Grid item>
						<Typography color="primary" fontWeight="bold" fontSize="24px">
							{t("quest_box_leaderboard_tier_reward_dialog_title")}
						</Typography>
					</Grid>
				</Grid>
			}
			content={
				<Grid container>
					<Grid item xs={12}>
						<Typography fontSize="14px" textAlign="center">
							{t("quest_box_leaderboard_tier_reward_dialog_info")}
						</Typography>
					</Grid>
					<Grid item xs={12} mt={2}>
						<TableContainer>
							<Table
								sx={{ minWidth: 750, borderCollapse: "separate", borderSpacing: "0 0", border: "none" }}
							>
								<TableHead>
									<TableRow>
										{["tier", "rank", "reward"].map((item, ix) => (
											<TableCell sx={{ border: "none" }} key={ix}>
												<Typography textAlign="center" color="primary" fontWeight="bold">
													{t(`quest_box_leaderboard_tier_reward_dialog_header_${item}`)}
												</Typography>
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody sx={{ background: (theme) => theme.palette.background.default }}>
									{leaderboardInfo.tierList.map((tierInfo, ix) => (
										<TableRow>
											<TableCell sx={{ border: "none" }}>
												<Typography sx={{ textAlign: "center" }} fontWeight="bold">
													{t(`quest_box_leaderboard_tier_reward_dialog_body_tier`, {
														tier: ix + 1,
													})}
												</Typography>
											</TableCell>
											<TableCell sx={{ border: "none" }}>
												<Typography sx={{ textAlign: "center" }} fontWeight="bold">
													{t(`quest_box_leaderboard_tier_reward_dialog_body_rank`, {
														rankTo: tierInfo.rankTo,
														rankFrom: tierInfo.rankFrom,
													})}
												</Typography>
											</TableCell>
											<TableCell sx={{ border: "none" }}>
												<Typography
													sx={{
														textAlign: "center",
														color: (theme) => theme.palette.success.main,
													}}
													fontWeight="bold"
													fontSize="16px"
												>
													{tierInfo.reward}
												</Typography>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
					<Grid item xs={12} mt={2}>
						<Disclaimer campaign={campaign} />
					</Grid>
					<Grid item xs={12} mt={3} justifyContent="center">
						<Grid container justifyContent="center">
							<Button variant="outlined" onClick={onClose} sx={{ width: "240px", height: "60px" }}>
								{t("quest_box_leaderboard_tier_reward_dialog_close_btn_text")}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			}
		/>
	);
};

export default function TierReward({ campaign }) {
	const [open, setOpen] = useState(false);

	const { t } = useTranslation("questBox");

	return (
		<Grid container justifyContent="center">
			<Grid item>
				<Button variant="text" startIcon={<TierRewardIcon />} onClick={() => setOpen(true)}>
					{t("quest_box_leaderboard_tier_reward_btn_text")}
				</Button>
			</Grid>
			<RewardDialog campaign={campaign} open={open} onClose={() => setOpen(false)} />
		</Grid>
	);
}
