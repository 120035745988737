import { createContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";

import { Box, useMediaQuery } from "@mui/material";

import { LeftMenu, PageCenteredProgress, ReferralCodeDialog, TopMenu } from "components";

import { setProfile } from "../actions/UserActions";
import { useUserApi } from "../api/user";
import { Config } from "../services";

export const ReferralContext = createContext();

export default function Main() {
	const { profile } = useSelector((state) => state.user);

	const matches = useMediaQuery("(max-width:900px)", { noSsr: true });
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { getProfile, putProfile } = useUserApi();
	const { i18n } = useTranslation();

	const [open, setOpen] = useState(false);
	const [processing, setProcessing] = useState(false);

	const logoutEventHandler = (event) => {
		if (event.key === "logout-event") {
			window.location.replace("/");
		}
	};

	useEffect(() => {
		setProcessing(true);

		getProfile()
			.then((data) => {
				const profileData = data?.data?.profile;
				if (Config.environment() === "production") {
					ReactGA.initialize("G-GC4LFFJ5L8", {
						gaOptions: {
							userId: profileData?.id,
						},
					});
				}

				dispatch(setProfile(profileData));
				setProcessing(false);

				if (!profileData?.language) {
					putProfile({
						language: navigator.language || navigator.userLanguage,
					});

					return { ...profileData, language: navigator.language || navigator.userLanguage };
				}

				return profileData;
			})
			.then((res) => {
				i18n.changeLanguage(res?.language ?? "en-US");

				matches && !location.pathname.startsWith("/payment") && navigate("/mobile-redirect");
			});

		window.addEventListener("storage", logoutEventHandler);

		return () => {
			window.removeEventListener("storage", logoutEventHandler);
		};
	}, []);

	return (
		<>
			{processing ? (
				<PageCenteredProgress />
			) : (
				<IntercomProvider
					appId={Config.intercomAppId()}
					autoBoot
					autoBootProps={{
						alignment: "right",
						horizontalPadding: 40,
						verticalPadding: 40,
						email: profile?.email,
						phone: profile?.phoneNumber,
						customAttributes: {
							...profile?.userBrief,
							realBalance: profile?.balance?.realBalance,
							bonusBalance: profile?.balance?.bonusBalance,
						},
					}}
				>
					<ReferralContext.Provider value={{ open, setOpen }}>
						<Box sx={{ display: "flex" }}>
							<LeftMenu></LeftMenu>
							<TopMenu></TopMenu>
							{open && <ReferralCodeDialog />}
							<Outlet />
						</Box>
					</ReferralContext.Provider>
				</IntercomProvider>
			)}
		</>
	);
}
