import { createContext, useState } from "react";
import { useTranslation } from "react-i18next";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Dialog, DialogTitle, Grid, IconButton, Typography } from "@mui/material";

import { Close as CloseSvg } from "images";

import AddConnectionSettings from "./AddConnectionSettings";
import CommercialSettings from "./CommercialSettings";
import DeleteStrategy from "./DeleteStrategy";
import DuplicateStrategy from "./DuplicateStrategy";
import EditConnectionSettings from "./EditConnectionSettings";
import Selection from "./Selection";
import ShowConnections from "./ShowConnections";
import Success from "./Success";
import {
	ADD_CONNECTION_SETTINGS,
	COMMERCIAL_SETTINGS,
	DELETE,
	DUPLICATE,
	EDIT_CONNECTION_SETTINGS,
	SELECTION,
	SHOW_CONNECTIONS,
	SUCCESS,
} from "./utils/constant";

export const StrategyContext = createContext();

export default function MyStrategiesDialog({ open, onClose, strategy }) {
	const { t } = useTranslation("workshop");

	const [selection, setSelection] = useState(SELECTION);
	const [successMessage, setSuccessMessage] = useState({ title: "", subtitle: "" });
	const [backButton, setBackButton] = useState({
		to: SELECTION,
		label: t("workshop_actions_main_title"),
	});
	const [selectedNetwork, setSelectedNetwork] = useState("discord");
	const [selectedConnectionSetting, setSelectedConnectionSetting] = useState({});

	const SelectionSettings = {
		[SELECTION]: {
			title: t("Bot Durumu"),
			subtitle:
				"Add your exchange's API information so that Traderlands can communicate with your exchange accounts.",
			component: Selection,
		},
		[DUPLICATE]: {
			title: t("workshop_actions_duplicate_main_title"),
			subtitle: t("workshop_actions_duplicate_main_text"),
			component: DuplicateStrategy,
		},
		[DELETE]: {
			title: t("workshop_actions_delete_main_title"),
			subtitle: t("workshop_actions_delete_main_text"),
			component: DeleteStrategy,
			color: strategy.public === 1 ? "#e0e0e0" : "",
		},
		[SUCCESS]: {
			component: Success,
		},
		[COMMERCIAL_SETTINGS]: {
			title: t("workshop_commercial_settings_header"),
			subtitle: t("workshop_commercial_settings_header_subtext"),
			component: CommercialSettings,
		},
		[ADD_CONNECTION_SETTINGS]: {
			component: AddConnectionSettings,
		},
		[SHOW_CONNECTIONS]: {
			component: ShowConnections,
		},
		[EDIT_CONNECTION_SETTINGS]: {
			component: EditConnectionSettings,
		},
	};

	const CurrentComponent = SelectionSettings[selection].component;

	const handleOutsideClick = () => {
		if (selection === SUCCESS) {
			onClose();
			window.location.reload();
		}
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			onClick={handleOutsideClick}
			sx={{
				"& .MuiPaper-root": {
					margin: "0 0 0 auto",
					height: "100%",
					maxHeight: "none",
					maxWidth: "568px",
				},
			}}
		>
			<DialogTitle sx={{ fontSize: 24, padding: "0 24px" }}>
				{selection !== SELECTION ? (
					<Grid container xs={12} direction="row">
						<IconButton
							color="primary"
							onClick={() => {
								(backButton.to === DUPLICATE || backButton.to === DELETE) &&
									setBackButton({
										to: SELECTION,
										label: t("constrol_panel_robot_settings_status_back_button"),
									});

								setSelection(backButton.to);
							}}
						>
							<KeyboardBackspaceIcon />
						</IconButton>

						<Typography
							style={{ color: (theme) => theme.palette.text.dark, marginLeft: "5px", marginTop: "10px" }}
						>
							{backButton.label}
						</Typography>
					</Grid>
				) : (
					<></>
				)}

				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseSvg />
				</IconButton>
			</DialogTitle>
			<StrategyContext.Provider
				value={{
					backButton,
					setBackButton,
					onClose,
					selection,
					setSelection,
					successMessage,
					setSuccessMessage,
					SelectionSettings,
					strategy,
					selectedNetwork,
					setSelectedNetwork,
					selectedConnectionSetting,
					setSelectedConnectionSetting,
				}}
			>
				<CurrentComponent
					contentProps={{ sx: { pt: "2vh !important" }, mt: "50%" }}
					strategy={strategy}
				></CurrentComponent>
			</StrategyContext.Provider>
		</Dialog>
	);
}
