import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Button, Grid, Link, Typography } from "@mui/material";

import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";

import { Breadcrumbs, PageCenteredProgress, RouteLayout } from "components";

import { useDashboardApi } from "api/dashboard";
import { useMarketplaceApi } from "api/marketplace";
import { usePlatformApi } from "api/platform";
import { useStrategyApi } from "api/strategy";
import useCatchError from "api/useCatchError";
import { useUserApi } from "api/user";

import { setParities } from "actions/ParityActions";
import { setProfile } from "actions/UserActions";

import Layout from "../Layout";
import Header from "./Header";
import MarketPlaceInfoBox from "./MarketPlaceInfoBox";
import RealTimePerformance from "./performance/RealTimePerformance";
import StrategyAnalytics from "./performance/StrategyAnalytics";

dayjs.extend(minMax);

export const MarketStrategyContext = createContext();

export default function Strategy() {
	const { jwt: selectedJWT } = useSelector((state) => state.jwt);
	const { profile } = useSelector((state) => state.user);
	const { parities } = useSelector((state) => state.parity);

	const [marketStrategy, setMarketStrategy] = useState();
	const [generalMarketMetrics, setGeneralMarketMetrics] = useState();
	const [followedStrategy, setFollowedStrategy] = useState();
	const [portfolio, setPortfolio] = useState();
	const [filter, setFilter] = useState();
	const [processing, setProcessing] = useState(true);

	const { getMarketStrategy } = useMarketplaceApi();
	const { getFollowedStrategies } = useStrategyApi();
	const { getParities } = usePlatformApi();
	const { getProfile } = useUserApi();
	const { getPortfolio } = useDashboardApi();

	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const catchError = useCatchError();
	const { t } = useTranslation("marketplace");

	useEffect(() => {
		Promise.all([
			getParities().then((data) => dispatch(setParities(data))),
			getMarketStrategy(id).then((data) => {
				setMarketStrategy(data);

				setFilter({
					quote: data.strategy.parities.quotes[0],
					timeRange: "3M",
					marketStrategyId: data.id,
				});

				return data;
			}),
			...(selectedJWT
				? [
						getProfile().then((data) => {
							dispatch(setProfile(data?.data?.profile));
							return data?.data?.profile;
						}),
				  ]
				: []),
			...(selectedJWT
				? [
						getPortfolio().then((data) => {
							setPortfolio(data);
							return data;
						}),
				  ]
				: []),
		])
			.then((values) => {
				console.log(values);
			})
			.catch(catchError)
			.finally(() => {
				setProcessing(false);
			});
	}, []);

	useEffect(() => {
		if (selectedJWT) {
			navigate(`/marketplace/strategy/${id}`);
			return;
		}
	}, []);

	useEffect(() => {
		if (
			profile?.strategyFollowers?.find((item) => item.strategy?.id == marketStrategy?.strategyId) &&
			selectedJWT
		) {
			getFollowedStrategies(marketStrategy?.strategyId).then((data) => {
				setFollowedStrategy(data[0]);
			});
		}
	}, [marketStrategy, profile]);

	return (
		<Layout>
			<RouteLayout
				headerComp={
					<Breadcrumbs
						paths={[
							{
								text: t("marketplace_main_title"),
								onClick: () => navigate("/marketplace"),
							},
							{
								text: t("marketplace_strategy_page_title"),
							},
						]}
					/>
				}
			>
				<MarketStrategyContext.Provider
					value={{
						marketStrategy,
						setMarketStrategy,
						generalMarketMetrics,
						setGeneralMarketMetrics,
						filter,
						setFilter,
						followedStrategy,
						setFollowedStrategy,
						portfolio,
					}}
				>
					{processing && !marketStrategy && !parities ? (
						<PageCenteredProgress />
					) : (
						<Grid container spacing={2} sx={{ pt: 2, maxWidth: "100%" }}>
							<Grid item md={4} xs={12} style={{ display: "flex" }}>
								<Grid container>
									<Grid item xs={12} style={{ display: "flex" }}>
										{parities && marketStrategy && <Header />}
									</Grid>
									<Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
										<MarketPlaceInfoBox />
									</Grid>
								</Grid>
							</Grid>
							<Grid item md={8} xs={12}>
								<RealTimePerformance />
							</Grid>

							{!selectedJWT && (
								<Grid item md={12} xs={12} sx={{ marginBottom: 4 }}>
									<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
										<Typography
											sx={{
												textAlign: "center",
												fontSize: "18px",
												marginY: 2,
											}}
										>
											{t("marketplace_strategy_full_access")}
										</Typography>
										<Box sx={{ display: "flex", justifyContent: "center" }}>
											<Link href="/login" underline="none" sx={{ cursor: "pointer" }}>
												<Button variant="contained" color="primary" sx={{ marginRight: 2 }}>
													<span>{t("marketplace_strategy_login")}</span>
												</Button>
											</Link>
											<Link href="/register" underline="none" sx={{ cursor: "pointer" }}>
												<Button variant="contained" color="primary">
													<span>{t("marketplace_strategy_register")}</span>
												</Button>
											</Link>
										</Box>
									</Box>
								</Grid>
							)}
							{selectedJWT && marketStrategy && (
								<Grid item md={12} xs={12}>
									<StrategyAnalytics />
								</Grid>
							)}
						</Grid>
					)}
				</MarketStrategyContext.Provider>
			</RouteLayout>
		</Layout>
	);
}
