import React from "react";

export default function SpotDown({ width, height, color }) {
	return (
		<svg
			width={width ?? "12"}
			height={height ?? "10"}
			viewBox="0 0 12 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M9.54732 0.806862C9.77457 0.814239 9.95282 1.00444 9.94544 1.23169C9.78985 6.02479 5.77897 9.78045 0.98623 9.62487C0.758978 9.61749 0.580735 9.42729 0.588112 9.20004C0.595489 8.97279 0.785693 8.79454 1.01294 8.80192C5.35154 8.94276 8.98166 5.54322 9.12249 1.20498C9.12986 0.977728 9.32007 0.799485 9.54732 0.806862Z"
				fill={color ?? "#36B37E"}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M9.27544 0.679833C9.45204 0.53662 9.7113 0.563686 9.85452 0.740287L11.3201 2.5476C11.4634 2.7242 11.4363 2.98346 11.2597 3.12667C11.0831 3.26988 10.8238 3.24282 10.6806 3.06622L9.21499 1.25891C9.07178 1.08231 9.09884 0.823046 9.27544 0.679833Z"
				fill={color ?? "#36B37E"}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M9.85477 0.740915C9.99763 0.917797 9.97006 1.177 9.79318 1.31987L8.08055 2.70315C7.90366 2.84602 7.64446 2.81844 7.50159 2.64156C7.35872 2.46468 7.3863 2.20547 7.56318 2.06261L9.27581 0.679325C9.45269 0.536459 9.7119 0.564034 9.85477 0.740915Z"
				fill={color ?? "#36B37E"}
			/>
		</svg>
	);
}
