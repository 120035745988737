import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CircularProgress, Grid, Typography } from "@mui/material";

import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";

import { useMarketplaceApi } from "api/marketplace";

import { MarketStrategyContext } from "../Strategy";
import Filter from "./Filter";
import Metrics from "./Metrics";
import Results from "./Results";

dayjs.extend(minMax);

const LiveTest = () => {
	const { t } = useTranslation("marketplace");
	const { filter, setGeneralMarketMetrics } = useContext(MarketStrategyContext);

	const [marketStrategyMetrics, setMarketStrategyMetrics] = useState();
	const { getMarketStrategyMetrics, getGeneralMarketMetrics } = useMarketplaceApi();
	const [processing, setProcessing] = useState(false);

	useEffect(() => {
		if (filter) {
			setProcessing(true);
			getMarketStrategyMetrics(filter)
				.then((data) => {
					const { marketStrategyMetric } = data?.data;
					const { backtestResults, livetestResults } = data?.data?.marketStrategyMetric ?? {};

					if (!livetestResults) return { marketStrategyMetric };

					const startDate = dayjs
						.min([
							...(backtestResults?.results?.length ? [dayjs(backtestResults?.results?.[0].date)] : []),
							...(livetestResults?.results?.length ? [dayjs(livetestResults?.results?.[0].date)] : []),
						])
						.format("YYYY-MM-DD");

					const endDate = dayjs
						.max([
							...(backtestResults?.results?.length
								? [dayjs(backtestResults?.results?.[backtestResults?.results?.length - 1].date)]
								: []),
							...(livetestResults?.results?.length
								? [dayjs(livetestResults?.results?.[livetestResults?.results?.length - 1].date)]
								: []),
						])
						.format("YYYY-MM-DD");
					return { startDate, endDate, marketStrategyMetric };
				})
				.then(({ startDate, endDate, marketStrategyMetric }) => {
					if (!marketStrategyMetric?.livetestResults) {
						setProcessing(false);
						return;
					}

					Promise.all([
						getGeneralMarketMetrics({ title: "TOT", startDate, endDate }),
						getGeneralMarketMetrics({ title: "BTC", startDate, endDate }),
					])
						.then((values) => {
							const tot = values[0].data.series;
							const btc = values[1].data.series;

							setGeneralMarketMetrics({ tot, btc });

							setMarketStrategyMetrics({
								...marketStrategyMetric,
								backtestResults: {
									...marketStrategyMetric?.backtestResults,
									results: marketStrategyMetric?.backtestResults?.results?.map(({ date, value }) => ({
										date,
										value,
										tot: tot[date],
										btc: btc[date],
									})),
								},
								livetestResults: {
									...marketStrategyMetric?.livetestResults,
									results: marketStrategyMetric?.livetestResults?.results?.map(({ date, value }) => ({
										date,
										value,
										tot: tot[date],
										btc: btc[date],
									})),
								},
							});
						})
						.finally(() => {
							setProcessing(false);
						});
				});
		}
	}, [filter]);

	return (
		<>
			{processing ? (
				<CircularProgress />
			) : (
				<Grid container>
					<Grid item xs={12} sx={{ mt: 2 }}>
						<Filter />
					</Grid>

					{!marketStrategyMetrics?.livetestResults?.results && (
						<Typography sx={{ mt: 2 }}>{t("marketplace_strategy_page_charts_no_data_text")}</Typography>
					)}

					{marketStrategyMetrics?.livetestResults?.results && (
						<Grid item xs={12}>
							{/* https://github.com/recharts/recharts/issues/854 */}
							<Results results={marketStrategyMetrics?.livetestResults?.results} />
						</Grid>
					)}

					{marketStrategyMetrics?.livetestResults?.metrics && (
						<Grid item xs={12}>
							<Metrics metrics={marketStrategyMetrics?.livetestResults?.metrics} />
						</Grid>
					)}
				</Grid>
			)}
		</>
	);
};

export default LiveTest;
