import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Grid, Link, Paper, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";

import { STRATEGY_RISK_LABELS } from "constants";

import { Chip } from "components";

import { useMarketplaceApi } from "api/marketplace";

import shortenNumber from "utils/shortenNumber";

import { InfoGray, InfoGreen, InfoOrange, InfoRed, LinkIcon } from "images";

import useStatisticsMap from "./Constants";
import { MarketStrategyContext } from "./Strategy";

export default function MarketPlaceInfoBox() {
	const { marketStrategy, filter } = useContext(MarketStrategyContext);
	const { strategy, explanations } = marketStrategy ?? {};
	const { labels, strategyStatistics, strategyFollowers } = strategy ?? {};

	const [metrics, setMetrics] = useState(null);

	const { t, i18n } = useTranslation("marketplace");
	const { getMarketStrategyMetrics } = useMarketplaceApi();

	useEffect(() => {
		getMarketStrategyMetrics(filter).then((data) => {
			setMetrics(data?.data?.marketStrategyMetric);
		});
	}, []);

	const [tab, setTab] = useState(0);

	return (
		<>
			<Box sx={{ borderRadius: "8px", backgroundColor: "white" }}>
				<Tabs
					variant="fullWidth"
					centered
					value={tab}
					onChange={(_, newValue) => {
						setTab(newValue);
					}}
				>
					<Tab
						fullWidth
						label={t("marketplace_strategy_page_explanation_tab_title").toLocaleUpperCase("tr-TR")}
						{...a11yProps(0)}
					></Tab>
				</Tabs>
				{tab === 0 && (
					<Explanation
						labels={labels}
						text={explanations?.[i18n.resolvedLanguage]?.text}
						strategyFollowers={strategyFollowers}
						totalVolume={strategyStatistics?.totalVolume}
					/>
				)}
			</Box>

			<Paper sx={{ mt: "8px", borderRadius: "8px", flex: 1 }}>
				<RiskWarning risk={metrics?.livetestResults?.metrics?.riskLabel} />
			</Paper>
		</>
	);
}

const Brief = ({ statistics }) => {
	const { t } = useTranslation("marketplace");
	const { statisticsMap } = useStatisticsMap();

	return (
		<Grid container sx={{ p: 2 }}>
			{Object.keys(statisticsMap).map((key, ix) => (
				<Grid key={ix} container columnSpacing={"-35px"}>
					<Grid item xs={10}>
						<Typography sx={{ fontSize: "14px", fontWeight: 700 }}>{statisticsMap[key]?.label}</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography sx={{ color: (theme) => theme.palette.primary.main }}>
							{statistics?.[key]
								? `${statisticsMap?.[key]?.price ? "$" : ""}${shortenNumber(
										statistics?.[key] * (statisticsMap?.[key]?.multiplier ?? 1)
								  )}${statisticsMap?.[key]?.percent ? "%" : ""}`
								: "0"}
						</Typography>
					</Grid>
				</Grid>
			))}
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography sx={{ color: "#6A1FC2", marginTop: "1rem" }}>
						{t("marketplace_strategy_note")}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const RiskWarning = ({ risk }) => {
	const theme = useTheme();
	const { t, i18n } = useTranslation("marketplace");

	const renderWarningIcon = () => {
		switch (risk) {
			case STRATEGY_RISK_LABELS.high:
				return <InfoRed style={{ marginTop: 5 }} />;
			case STRATEGY_RISK_LABELS.mid:
				return <InfoOrange style={{ marginTop: 5 }} />;
			case STRATEGY_RISK_LABELS.low:
				return <InfoGreen style={{ marginTop: 5 }} />;
			default:
				return <InfoGray style={{ marginTop: 5 }} />;
		}
	};

	return (
		<Grid container sx={{ p: 2 }}>
			<Grid item xs={0.8}>
				{renderWarningIcon()}
			</Grid>
			<Grid item xs={10}>
				<Typography
					sx={{
						fontSize: "12px",
						fontWeight: "bold",
						pb: "4px",
						[theme.breakpoints.up("xs")]: {
							ml: 3,
						},
						[theme.breakpoints.up("md")]: {
							ml: 3,
						},
						[theme.breakpoints.up("lg")]: {
							ml: 2,
						},
						[theme.breakpoints.up("xl")]: {
							ml: 1,
						},
					}}
				>
					{risk ? t(`marketplace_strategy_risk_text_${risk}`) : t("marketplace_strategy_risk_text_na")}
				</Typography>
				<Typography
					sx={{
						fontSize: "12px",
						[theme.breakpoints.up("xs")]: {
							ml: 3,
						},
						[theme.breakpoints.up("md")]: {
							ml: 3,
						},
						[theme.breakpoints.up("lg")]: {
							ml: 2,
						},
						[theme.breakpoints.up("xl")]: {
							ml: 1,
						},
						height: "h-full",
					}}
				>
					{risk
						? t(`marketplace_strategy_risk_disclaimer_${risk}`)
						: t("marketplace_strategy_risk_disclaimer_na")}
				</Typography>
				<Link
					underline="none"
					target="_blank"
					href={`https://help.traderlands.com/${i18n.resolvedLanguage}/articles/8238733`}
					variant="body2"
					sx={{
						display: "flex",
						color: (theme) => theme.palette.secondary.main,
						fontWeight: "bold",
						mt: 0.5,
						[theme.breakpoints.up("xs")]: {
							ml: 3,
						},
						[theme.breakpoints.up("md")]: {
							ml: 3,
						},
						[theme.breakpoints.up("lg")]: {
							ml: 2,
						},
						[theme.breakpoints.up("xl")]: {
							ml: 1,
						},
					}}
				>
					<Typography sx={{ fontWeight: "bold", fontSize: "12px", marginRight: "4px" }}>
						{t("marketplace_strategy_risk_blog_link_text")}
					</Typography>
					<LinkIcon />
				</Link>
			</Grid>
		</Grid>
	);
};

const Explanation = ({ text, labels, strategyFollowers, totalVolume }) => {
	const { t } = useTranslation("marketplace");

	const formattedTotalVolume = `$${totalVolume == undefined ? "0" : shortenNumber(totalVolume)}`;

	return (
		<Grid sx={{ p: 2 }}>
			<Typography sx={{ fontWeight: 700 }}>
				{t("marketplace_strategy_page_explanation_tab_features_title")}
			</Typography>
			{labels?.map((label, ix) => (
				<Chip
					key={ix}
					variant="contained"
					backgroundColor={"#0F20E8"}
					label={label}
					sx={{ fontSize: "12px", ml: 1, mt: 1 }}
				/>
			))}
			{strategyFollowers?.[0].positionSettings.leverage && (
				<Chip
					variant="contained"
					backgroundColor={"#F4F5FC"}
					label={strategyFollowers?.[0].positionSettings.leverage + "X"}
					sx={{ fontSize: "12px", ml: 1, mt: 1, color: "#0F20E8" }}
				/>
			)}
			<Chip
				variant="contained"
				backgroundColor={"#F4F5FC"}
				label={"Volume: " + formattedTotalVolume}
				sx={{ fontSize: "12px", ml: 1, mt: 1, color: "#0F20E8" }}
			></Chip>
			<Typography sx={{ fontWeight: 700, mt: 2 }}>
				{t("marketplace_strategy_page_explanation_tab_explanation_title")}
			</Typography>
			<Typography sx={{ minHeight: 100 }}>
				{text?.replace(/\s/g, "") === ""
					? t("marketplace_strategy_page_explanation_tab_no_explanation_text")
					: text}
			</Typography>
		</Grid>
	);
};
