import React from "react";

export default function ({ width, height }) {
	return (
		<svg
			width={width ? width : "18"}
			height={height ? height : "35"}
			viewBox="0 0 18 35"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.44218 17.0156H17L1 33.0157"
				stroke="#0F20E8"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M17 1L1 17.0162" stroke="#0F20E8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
}
